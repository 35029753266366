import axios from "src/utils/axios";
import { AxiosResponse } from "axios";
import {
	IPrepaidWashPromotion,
	IPrepaidWashPromotionConfig,
	XDynamicSqlName,
	ICreatePromotion,
	IUpdatePromotion,
	IStoredWashBalance,
	IStoredWashHistory,
	EEndpointType,
	XPrepaidPlanPromotionConfigUpdateRequest,
	XPrepaidPlanPromotionConfigCreateRequest,
	DynamicSql,
	IOrderBy,
	XPrepaidPlanPromotionUpdateRequest,
	XPrepaidPlanPromotionCreateRequest,
	XPrepaidPlanPromotionResponse,
	XPrepaidPromotionWithDetail,
} from "amp";
import { ENavCategory } from "src/enums/nav-category.enum";
import { generateManagmentRoutes } from "src/utils/generate-routes";
import { XDynamicTablePageProps } from "src/components/dynamic-table/DynamicTablePage";

const DYNAMIC_ENDPOINT_TABLE_NAME: Extract<
	XDynamicSqlName,
	"prepaidPromotionDetail"
> = "prepaidPromotionDetail";
const API_PREPAID_WASH_BASE_URL = `${EEndpointType.User}/prepaid-wash-promotions`;
const API_PREPAID_PLAN_BASE_URL = `${EEndpointType.Admin}/prepaid-plan-promotions`;
const API_STORED_WASH_BASE_URL = `${EEndpointType.User}/stored-wash`;
const API_BASE_PREPAID_WASH_CONFIG_URL = `${EEndpointType.User}/prepaid-wash-config`;
const API_BASE_PREPAID_PLAN_CONFIG_URL = `${EEndpointType.Admin}/prepaid-plan-promotion-config`;
const routes = generateManagmentRoutes(
	ENavCategory.marketing,
	"prepaid-promotions",
);
const historyRoute = `${routes.BASE_ROUTE}/prepaid-wash-purchase-history`;

type IWebConfigEditForm = Omit<
	IPrepaidWashPromotionConfig,
	| "created"
	| "created_by_id"
	| "last_modified"
	| "last_modified_by_id"
	| "tenant__r__external_id__c"
	| "id"
> & {
	id?: number;
};

export interface IPrepaidWashPromotionTableRow {
	id: number;
	prepaidPlanPromotionId?: number;
	prepaidWashPromotionId?: number;
	name: string;
	sub_title: string;
	tile_image_url: string;
	effective_to: Date;
	effective_from?: Date;
	ordinal: number;
}

const BASE_QUERY = new DynamicSql(DYNAMIC_ENDPOINT_TABLE_NAME, (ppd) => ppd
	.setColumns([
		"id",
		"name",
		"sub_title",
		"tile_image_url",
		"effective_to",
		"effective_from",
		"ordinal",
	])
	.addJoin("prepaidPlanPromotion", (ppPromo) => ppPromo
		.setAlias("ppPromo")
		.setType("LEFT")
		.setColumns(["id"])
	)
	.addJoin("prepaidWashPromotion", (pwPromo) => pwPromo
		.setAlias("pwPromo")
		.setType("LEFT")
		.setColumns(["id"])
	)
	.addCondition({
		left: { alias: DYNAMIC_ENDPOINT_TABLE_NAME, column: "is_deleted" },
		operator: "EQ",
		right: false
	})
	.setOrderBys([{ alias: DYNAMIC_ENDPOINT_TABLE_NAME, column: "ordinal", direction: "ASC" }])
).buildQuery();

const ORDER_BYS: IOrderBy[] = [
	{
		alias: DYNAMIC_ENDPOINT_TABLE_NAME,
		column: "ordinal",
	},
	{
		alias: DYNAMIC_ENDPOINT_TABLE_NAME,
		column: "name",
		transforms: ["LOWERCASE"],
	},
];

const transformDataGroup: XDynamicTablePageProps<
	typeof DYNAMIC_ENDPOINT_TABLE_NAME,
	IPrepaidWashPromotionTableRow
>["transformDataGroup"] = async (prepaidPromotionDetails) => prepaidPromotionDetails
	.map((prepaidPromotionDetail): IPrepaidWashPromotionTableRow => ({
		id: prepaidPromotionDetail.id,
		prepaidPlanPromotionId: prepaidPromotionDetail.prepaidPlanPromotion?.id,
		prepaidWashPromotionId: prepaidPromotionDetail.prepaidWashPromotion?.id,
		name: prepaidPromotionDetail.name,
		sub_title: prepaidPromotionDetail.sub_title,
		tile_image_url: prepaidPromotionDetail.tile_image_url,
		effective_from: prepaidPromotionDetail.effective_from,
		effective_to: prepaidPromotionDetail.effective_to,
		ordinal: prepaidPromotionDetail.ordinal,
	}));

const createPrepaidPlanPromotion = async (
	createBody: XPrepaidPlanPromotionCreateRequest,
): Promise<XPrepaidPromotionWithDetail<XPrepaidPlanPromotionResponse>> => {
	const response = await axios.post<XPrepaidPromotionWithDetail<XPrepaidPlanPromotionResponse>>(
		`${API_PREPAID_PLAN_BASE_URL}/create`,
		createBody,
	);
	return response.data;
};

const updatePrepaidPlanPromotion = async (
	updateBody: XPrepaidPlanPromotionUpdateRequest,
): Promise<XPrepaidPromotionWithDetail<XPrepaidPlanPromotionResponse>> => {
	const response = await axios.post<XPrepaidPromotionWithDetail<XPrepaidPlanPromotionResponse>>(
		`${API_PREPAID_PLAN_BASE_URL}/update`,
		updateBody,
	);
	return response.data;
};

const createPrepaidWashPromotion = async (
	createBody: ICreatePromotion,
): Promise<IPrepaidWashPromotion> => {
	const response = await axios.post<IPrepaidWashPromotion>(
		`${API_PREPAID_WASH_BASE_URL}/create`,
		createBody,
	);
	return response.data;
};

const updatePrepaidWashPromotion = async (
	updateBody: IUpdatePromotion,
): Promise<IPrepaidWashPromotion> => {
	const response = await axios.post<IPrepaidWashPromotion>(
		`${API_PREPAID_WASH_BASE_URL}/update`,
		updateBody,
	);
	return response.data;
};

const createPrepaidWashPromotionConfig = async (
	createBody: IWebConfigEditForm,
): Promise<IPrepaidWashPromotionConfig> => {
	const response = await axios.post<IPrepaidWashPromotionConfig>(
		`${API_BASE_PREPAID_WASH_CONFIG_URL}/create`,
		createBody,
	);
	return response.data;
};

const updatePrepaidWashPromotionConfig = (
	updateBody: IWebConfigEditForm,
): Promise<AxiosResponse<IPrepaidWashPromotionConfig>> => {
	return axios.post<IPrepaidWashPromotionConfig>(
		`${API_BASE_PREPAID_WASH_CONFIG_URL}/update`,
		updateBody,
	);
};

const createPrepaidPlanPromotionConfig = async (
	createBody: XPrepaidPlanPromotionConfigCreateRequest,
): Promise<IPrepaidWashPromotionConfig> => {
	const response = await axios.post<IPrepaidWashPromotionConfig>(
		`${API_BASE_PREPAID_PLAN_CONFIG_URL}/create`,
		createBody,
	);
	return response.data;
};

const updatePrepaidPlanPromotionConfig = (
	updateBody: XPrepaidPlanPromotionConfigUpdateRequest,
): Promise<AxiosResponse<IPrepaidWashPromotionConfig>> => {
	return axios.post<IPrepaidWashPromotionConfig>(
		`${API_BASE_PREPAID_PLAN_CONFIG_URL}/update`,
		updateBody,
	);
};

const getWashBalances = async (
	userId: string,
): Promise<IStoredWashBalance[]> => {
	const response = await axios.post<IStoredWashBalance[]>(
		`${API_STORED_WASH_BASE_URL}/get-wash-balances`,
		{ userId },
	);
	return response.data;
};

const getHistory = async (userId: string): Promise<IStoredWashHistory[]> => {
	const response = await axios.post<IStoredWashHistory[]>(
		`${API_STORED_WASH_BASE_URL}/get-history`,
		{ userId },
	);
	return response.data;
};

export const PrepaidPromotionService = {
	DYNAMIC_ENDPOINT_TABLE_NAME,
	API_PREPAID_WASH_BASE_URL,
	API_PREPAID_PLAN_BASE_URL,
	BASE_QUERY,
	ORDER_BYS,
	transformDataGroup,
	createPrepaidPlanPromotion,
	updatePrepaidPlanPromotion,
	createPrepaidWashPromotion,
	updatePrepaidWashPromotion,
	getWashBalances,
	getHistory,
	createPrepaidWashPromotionConfig,
	updatePrepaidWashPromotionConfig,
	createPrepaidPlanPromotionConfig,
	updatePrepaidPlanPromotionConfig,
	historyRoute,
	...routes,
};
