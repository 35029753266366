import { MouseEvent, useCallback, useContext, useState } from "react";
import { Menu, MenuItem, MenuProps } from "@material-ui/core";

import { MenuTrigger } from "src/components/MenuTrigger";
import { FleetAccountsContext, IFleetAccount } from "src/contexts/FleetAccountsContext";
import { useIsFleetAdmin } from "src/hooks/useIsFleetAdmin";

export const FleetAccountDropdown = () => {
	const {
		fleetAccounts,
		fleetAccountId,
		setFleetAccountId,
	} = useContext(FleetAccountsContext);

	const isFleetAdmin = useIsFleetAdmin();

	const [fleetName, setFleetName] = useState<string | undefined>(
		fleetAccounts?.find((fleet) => fleet.external_id__c === fleetAccountId)?.name
	);
	const [menuProps, setMenuProps] = useState<MenuProps>({ open: false });

	const selectFleet = useCallback((event: MouseEvent, fleet: IFleetAccount) => {
		setFleetName(fleet.name);
		setFleetAccountId(fleet.external_id__c);
	}, []);

	if (!isFleetAdmin && (!fleetAccounts?.length || fleetAccounts.length < 2)) {
		return null;
	}

	return (
		<>
			<MenuTrigger
				color="inverted"
				style={{ textTransform: "none" }}
				size="large"
				disableElevation
				onToggle={setMenuProps}
			>
				{fleetName}
			</MenuTrigger>

			<Menu {...menuProps}>
				{fleetAccounts.map((fleet) => (
					<MenuItem
						key={fleet.external_id__c}
						onClick={(event) => selectFleet(event, fleet)}
					>
						{fleet.name}
					</MenuItem>
				))}
			</Menu>
		</>
		);
};
