import { EFormType } from "src/enums/form-type.enum";
import { EPageType } from "src/enums/page-type.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.hardware}/lanes`;
const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;

const BASE_FORM_ROUTE = `${BASE_ROUTE}/${EPageType.form}`;
const FORM_ROUTE = `${BASE_FORM_ROUTE}/:formType/:id?`;
const CREATE_ROUTE = `${BASE_FORM_ROUTE}/${EFormType.create}`;
const EDIT_ROUTE = `${BASE_FORM_ROUTE}/${EFormType.edit}/:id`;
const COPY_ROUTE = `${BASE_FORM_ROUTE}/${EFormType.copy}/:id`;

export const lanesRoutes = {
	LIST_ROUTE,
	BASE_ROUTE,
	FORM_ROUTE,
	CREATE_ROUTE,
	EDIT_ROUTE,
	COPY_ROUTE,
};