import { EPageType } from "src/enums/page-type.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { EIntegrationKeys } from "amp";
import { EFormType } from "src/enums/form-type.enum";

const BASE_ROUTE = `/${ENavBaseRoute.management}/integrations`;
const BASE_ROUTE_BY_KEY = `/${ENavBaseRoute.management}/integrations/:key`;
const LIST_ROUTE = `${BASE_ROUTE}/:key/${EPageType.list}`;
const FORM_ROUTE_BY_KEY = `${BASE_ROUTE}/:key/${EPageType.form}`;
const FORM_ROUTE_BY_KEY_AND_ID = `${BASE_ROUTE}/:key/${EPageType.form}/:formType/:id?`;
const EDIT_INTEGRATION_ROUTE = `${BASE_ROUTE}/:key/${EPageType.form}/${EFormType.edit}/:id`;
const CREATE_INTEGRATION_ROUTE = `${BASE_ROUTE}/:key/${EPageType.form}/${EFormType.create}`;

function getBaseRouteByIntegrationKey(key: EIntegrationKeys) {
	return BASE_ROUTE_BY_KEY.replace(":key", key);
}

function getFormRouteByIntegrationKey(key: EIntegrationKeys) {
	return FORM_ROUTE_BY_KEY.replace(":key", key);
}

function getEditIntegrationRoute(key: EIntegrationKeys, id: number | string) {
	return EDIT_INTEGRATION_ROUTE.replace(":key", key).replace(":id", id.toString());
}

function getCreateIntegrationRoute(key: EIntegrationKeys) {
	return CREATE_INTEGRATION_ROUTE.replace(":key", key);
}

function getListRouteByIntegrationKey(key: EIntegrationKeys) {
	return LIST_ROUTE.replace(":key", key);
}

export const IntegrationsRoutes = {
	FORM_ROUTE_BY_KEY,
	FORM_ROUTE_BY_KEY_AND_ID,
	LIST_ROUTE,
	EDIT_INTEGRATION_ROUTE,
	CREATE_INTEGRATION_ROUTE,
	getBaseRouteByIntegrationKey,
	getFormRouteByIntegrationKey,
	getEditIntegrationRoute,
	getCreateIntegrationRoute,
	getListRouteByIntegrationKey,
};
