import { ReactNode, useEffect } from "react";
import { Box, Card, CardContent, IconButton, makeStyles, SvgIcon, Typography, useTheme } from "@material-ui/core";
import { Maximize2 as MaximizeIcon } from "react-feather";
import clsx from "clsx";
import { Theme } from "src/theme";

interface IChartTileProps {
	/** The label printed at the top-left of the chart */
	title: string;
	children: ReactNode | ReactNode[];
	/** Optional slot for additional content to render in the header */
	headerSlot?: ReactNode;
	/** If true, displays chart in full screen mode */
	fullScreen: boolean;
	/** Hides full screen button if omitted. Lifts updated `fullScreen` value up to parent */
	onFullScreenChange?: (value: boolean) => void;
}

export function ChartTile({
	children,
	headerSlot,
	fullScreen,
	onFullScreenChange,
	title,
}: IChartTileProps) {
	const classes = useStyles();
	const { typography } = useTheme();

	useEffect(() => {
		const exitFullScreen = ({ key }: KeyboardEvent) => {
			if (key === "Escape") {
				onFullScreenChange?.(false);
			}
		};
		window.addEventListener("keydown", exitFullScreen);
		return () => window.removeEventListener("keydown", exitFullScreen);
	}, [onFullScreenChange]);

	return (
		<Card className={clsx({ [classes.fullScreen]: fullScreen })}>
			<Box className={classes.header}>
				<Typography className={classes.title} variant="h5">
					{title}
				</Typography>
				{headerSlot}
				{onFullScreenChange && (
     				<IconButton
						style={{ marginLeft: "auto" }}
						color="inherit"
						onClick={() => onFullScreenChange(!fullScreen)}
					>
						<SvgIcon fontSize="small">
							<MaximizeIcon />
						</SvgIcon>
					</IconButton>
   				)}
			</Box>
			<CardContent style={{ fontFamily: typography.fontFamily }}>
				{children}
			</CardContent>
		</Card>
	);
}

const useStyles = makeStyles<Theme>(({ spacing, typography }) => ({
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "start",
		gap: spacing(1.5),
		padding: spacing(1, 1, 0, 2),
	},
	title: {
		...typography.truncate,
		display: "inline-flex",
		alignItems: "center",
		height: 44,
	},
	fullScreen: {
		position: "fixed !important" as "fixed",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		zIndex: 9999,
	},
}));
