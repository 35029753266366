import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import { LegendProps } from "recharts";
import { Theme } from "src/theme";

export enum ELegendShape {
	Bar = "bar",
	Line = "line",
}

interface ILegendProps extends LegendProps {
	shape: ELegendShape;
}

export function ChartLegend(props: ILegendProps) {
	const { payload } = props;
	const classes = useStyles(props);

	return (
		<ul className={classes.legend}>
			{payload?.map(({ value, color }) => (
				<li
					key={value}
					className={classes.legendItem}
				>
					<div
						className={classes.legendColor}
						style={{ background: color }}
					 />
					<span>{value}</span>
				</li>
			))}
		</ul>
	);
}

const CHART_SHAPES: Record<ELegendShape, CSSProperties> = {
	[ELegendShape.Bar]: {
		width: 16,
		height: 16,
	},
	[ELegendShape.Line]: {
		width: 20,
		height: 3,
	}
};

const useStyles = makeStyles<Theme, ILegendProps>(({ typography, spacing }) => ({
	legend: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		listStyleType: "none",
		gap: spacing(2),
		margin: spacing(6, 0, 0),
	},
	legendItem: {
		display: "flex",
		alignItems: "center",
		gap: spacing(1),
		overflow: "hidden",
		fontFamily: typography.fontFamily,
		fontSize: 14,

		"& span": {
			...typography.truncate,
			flex: "1 0 0",
		}
	},
	legendColor: ({ shape }) => ({
		flex: "0 0 auto",
		...CHART_SHAPES[shape],
	})
}));
