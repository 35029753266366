import { AxiosResponse } from "axios";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { Breadcrumb } from "src/components/Header";
import { EEndpointType } from "amp";
import axios from "src/utils/axios";
import {
	XGeneralMultiTenantWritable,
	XGeneralMultiTenantWritableWithId,
} from "amp";
import { fetchDataDynamic } from "src/hooks/useFetchDataDynamic";
import { IPriceGroup } from "amp";
import { IPriceGroupValidationError } from "amp";

const PAGE_NAME = "Price Groups";
const PORTAL_URL = "/app/management/price-groups";
const PORTAL_URL_CREATE = PORTAL_URL + "/create";
const PORTAL_URL_EDIT = PORTAL_URL + "/:id/edit";

const API_PRICE_GROUP_URL = `${EEndpointType.Csr}/price-groups`;

const ITEM_NAME_PLURAL = "Price Groups";
const ITEM_NAME = "Price Group";

const breadcrumbs: Breadcrumb[] = [{
	label: "Price Groups",
	url: PORTAL_URL,
}];

const getPortalUrlEdit = (id?: number | string) => id
	? PORTAL_URL_EDIT.replace(":id", id.toString())
	: PORTAL_URL_EDIT;

const useGoTo = () => {
	const navigate = useNavigate();
	const goToCreate = useCallback(() => navigate(PORTAL_URL_CREATE), []);
	const goToList = useCallback(() => navigate(PORTAL_URL), []);
	const goToEdit = useCallback((id: string) => navigate(`${PORTAL_URL}/${id}/edit`), []);
	const getEditUrl = useCallback((id: string) => `${PORTAL_URL}/${id}/edit`, []);
	return { goToCreate, goToList, goToEdit, getEditUrl };
};

const getPriceGroups = async (): Promise<IPriceGroup[]> => {
	return fetchDataDynamic("priceGroups", {
		orderBys: [{ column: "name" }],
	});
};

const getPriceGroupValidationErrors = async (): Promise<IPriceGroupValidationError[]> => {
	const result = await axios.post<
		XGeneralMultiTenantWritable<IPriceGroup>,
		AxiosResponse<IPriceGroupValidationError[]>
	>(`${API_PRICE_GROUP_URL}/get-validation-errors`);
	return result.data;
};

const getPriceGroupById = async (id: number): Promise<IPriceGroup | undefined> => {
	const priceGroups = await fetchDataDynamic("priceGroups", {
		condition: {
			left: { column: "id" },
			operator: "EQ",
			right: id,
		},
	});
	return priceGroups.length ? priceGroups[0] : undefined;
};

const createPriceGroup = async (priceGroup: XGeneralMultiTenantWritable<IPriceGroup>): Promise<IPriceGroup> => {
	const result = await axios.post<
		XGeneralMultiTenantWritable<IPriceGroup>,
		AxiosResponse<IPriceGroup>
	>(`${API_PRICE_GROUP_URL}/create`, priceGroup);
	return result?.data;
};

const updatePriceGroup = async (priceGroup: Partial<XGeneralMultiTenantWritableWithId<IPriceGroup>>): Promise<IPriceGroup | undefined> => {
	const result = await axios.post<
		Partial<XGeneralMultiTenantWritableWithId<IPriceGroup>>,
		AxiosResponse<IPriceGroup | undefined>
	>(`${API_PRICE_GROUP_URL}/update`, priceGroup);
	return result?.data;
};

export const PriceGroupService = {
	PAGE_NAME,
	PORTAL_URL,
	PORTAL_URL_CREATE,
	PORTAL_URL_EDIT,
	ITEM_NAME,
	ITEM_NAME_PLURAL,
	breadcrumbs,
	useGoTo,
	getPriceGroups,
	getPriceGroupById,
	getPriceGroupValidationErrors,
	createPriceGroup,
	updatePriceGroup,
	getPortalUrlEdit,
};