import {
	KeyboardEvent,
	MouseEvent,
	ReactNode,
	useEffect,
	useState,
} from "react";
import {
	Button,
	ButtonProps,
	makeStyles,
	MenuProps,
	PropTypes,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { flow, omit, omitBy } from "lodash/fp";

import { Theme } from "src/theme";
import { transparentize } from "amp";
import { from } from "src/utils/fp";

export interface IMenuTriggerProps extends Omit<ButtonProps, "color"> {
	color: PropTypes.Color | "inverted";
	children: ReactNode | ReactNode[];
	onToggle(menuProps: MenuProps): void;
}

/**
 * Convenience component that accessibly manages the link between an MUI Button
 * and an MUI Menu.
 *
 * @example
 * ```
 *  * import { Menu, MenuItem, MenuProps } from "@material-ui/core";
 * import { MenuTrigger } from "src/components/MenuTrigger";
 *
 * export function MyWrapperComponent() {
 *    const [menuProps, setMenuProps] = useState<MenuProps>({ open: false });
 *
 *    return <>
 *       <MenuTrigger onToggle={setMenuProps}>
 *          Click Me!
 *       </MenuTrigger>
 *
 *       <Menu {...menuProps}>
 *          <MenuItem>Foo</MenuItem>
 *          <MenuItem>Bar</MenuItem>
 *          <MenuItem>Baz</MenuItem>
 *       </Menu>
 *    </>
 * }
 * ```
 */
export function MenuTrigger(props: IMenuTriggerProps) {
	const { children, onToggle } = props;
	const buttonProps: ButtonProps = flow(
		from(props),
		omit(["children", "onToggle"]),
		omitBy((v, k) => k === "color" && v === "inverted"),
	)();

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [forwardEvent, setForwardEvent] = useState<Event | null>(null);

	const classes = useStyles(props);

	useEffect(() => {
		onToggle({
			open: isOpen,
			anchorEl,
			onClose,
			TransitionProps: {
				onEntering,
			},
		});
	}, [anchorEl, isOpen]);

	const onClose = () => {
		setIsOpen(false);
		setAnchorEl(null);
		setForwardEvent(null);
	};

	const onEntering = () => {
		if (forwardEvent !== null) {
			const { activeElement } = document;
			activeElement?.dispatchEvent(forwardEvent);
		}
	};

	const onClick = (event: MouseEvent<HTMLElement>) => {
		setIsOpen(value => !value);
		setAnchorEl(event.currentTarget);
	};

	const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
		if (isOpen) return;

		if (/^Arrow(Up|Down)$/.test(event.key)) {
			setIsOpen(true);
			setAnchorEl(event.currentTarget);

			// An ARIA menu should focus the last element when it's opened by pressing
			// ArrowUp on the trigger button. MUI's Menu component doesn't implement
			// this, but it does correctly handle arrow Up/Down events once the menu
			// is actually opened. We can hack around the a11y issue by capturing
			// the opening ArrowUp event and re-dispatching it in the menu's
			// `onEntering` callback.
			if (event.key === "ArrowUp") {
				setForwardEvent(event.nativeEvent);
			}
		}
	};

	return (
		<Button className={classes.button}
			{...buttonProps}
			aria-haspopup="menu"
			aria-expanded={isOpen}
			onClick={onClick}
			onKeyDown={onKeyDown}
			endIcon={<ExpandMore fontSize="small" />}
		>
			{children}
		</Button>
	);
}

const useStyles = makeStyles<Theme, IMenuTriggerProps>(({ palette }) => ({
	button: ({ color }) => color === "inverted" ? {
		color: palette.primary.contrastText,
		borderColor: transparentize(palette.primary.contrastText, 0.23),
	} : {},
}));
