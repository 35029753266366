import { EFormType } from "src/enums/form-type.enum";
import { EPageType } from "src/enums/page-type.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.marketing}/contacts`;

const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;
const FORM_ROUTE = `${BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const CREATE_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
const EDIT_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const COPY_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.copy}/:id`;

export const ContactRoutes = {
	BASE_ROUTE,
	LIST_ROUTE,
	FORM_ROUTE,
	CREATE_ROUTE,
	EDIT_ROUTE,
	COPY_ROUTE,
};