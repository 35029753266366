import { useEffect, useState } from "react";
import axios from "src/utils/axios";

export const useAxiosRequestInterceptor = (
	getHeaders: (() => Record<string, string>) | (() => Promise<Record<string, string>>),
): void => {
	const [tokenInterceptorId, setTokenInterceptorId] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (tokenInterceptorId !== undefined) {
			axios.interceptors.request.eject(tokenInterceptorId);
		}
		let tokenInterceptorIdNew: number | undefined;
		tokenInterceptorIdNew = axios.interceptors.request.use(async (request) => {
			return {
				...request,
				headers: {
					...(request.headers || {}),
					...(await getHeaders()),
				},
			};
		});
		setTokenInterceptorId(tokenInterceptorIdNew);
	}, [getHeaders]);
};
