import _ from 'lodash';
import {
	colors,
	responsiveFontSizes,
} from '@material-ui/core';
import {
	Theme as MuiTheme,
	ThemeOptions as MuiThemeOptions,
	createTheme as createMuiTheme,
} from '@material-ui/core/styles';
import type {
	Palette as MuiPalette,
	TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import { Typography, TypographyOptions } from "@material-ui/core/styles/createTypography";
import { CSSProperties } from "@material-ui/styles";

interface TypeBackground extends MuiTypeBackground {
	dark: string;
}

interface Palette extends MuiPalette {
	background: TypeBackground;
}

export interface Theme extends MuiTheme {
	name: string;
	palette: Palette;
	typography: Typography & {
		truncate: CSSProperties;
	};
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
	direction?: Direction;
	responsiveFontSizes?: boolean;
	theme?: string;
}

interface ThemeOptions extends MuiThemeOptions {
	name?: string;
	direction?: Direction;
	palette?: Record<string, any>;
	typography?: TypographyOptions & {
		truncate: CSSProperties;
	};
}

const autowashColors = {
	darkOrange: "#9b431f",
	primaryOrange: "#c25427",
	lightOrange: "#ce7652",
	darkBlue: "#006999",
	primaryBlue: "#006999",
	mediumBlue: "#0083bf",
	lightBlue: "#e6f3f9",
}

const baseOptions: ThemeOptions = {
	direction: 'ltr',
	typography: {
		...typography,
		truncate: {
			display: "inline",
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 768,
			md: 960,
			lg: 1280,
			xl: 1600,
		},
	},
	overrides: {
		MuiLinearProgress: {
			root: {
				borderRadius: 3,
				overflow: 'hidden'
			}
		},
		MuiListItemIcon: {
			root: {
				minWidth: 32
			}
		},
		MuiChip: {
			root: {
				backgroundColor: 'rgba(0,0,0,0.075)'
			}
		},
	}
};

const themesOptions: ThemeOptions[] = [
	{
		name: THEMES.LIGHT,
		overrides: {
			MuiInputBase: {
				input: {
					'&::placeholder': {
						opacity: 1,
						color: colors.blueGrey[600]
					}
				}
			},
			MuiOutlinedInput: {
				input: {
					"&:-webkit-autofill": {
						"-webkit-text-fill-color": autowashColors.primaryBlue,
						"-webkit-box-shadow": "0 0 0 100px #f7faff inset",
					}
				}
			},
		},
		palette: {
			type: 'light',
			action: {
				active: colors.blueGrey[600]
			},
			background: {
				default: colors.common.white,
				dark: '#f4f6f8',
				paper: colors.common.white
			},
			primary: {
				main: autowashColors.primaryBlue,
			},
			secondary: {
				main: autowashColors.primaryOrange,
			},
			warning: {
				main: '#D31D11'
			},
			success: {
				main: '#00b200'
			},
			text: {
				primary: colors.blueGrey[900],
				secondary: colors.blueGrey[600]
			}
		},
		shadows: softShadows
	},
	{
		name: THEMES.DARK,
		overrides: {
			MuiOutlinedInput: {
				input: {
					"&:-webkit-autofill": {
						"-webkit-text-fill-color": autowashColors.lightBlue,
						"-webkit-box-shadow": "0 0 0 100px #30353e inset",
					}
				}
			},
		},
		palette: {
			type: 'dark',
			action: {
				active: 'rgba(255, 255, 255, 0.54)',
				hover: 'rgba(255, 255, 255, 0.04)',
				selected: 'rgba(255, 255, 255, 0.08)',
				disabled: 'rgba(255, 255, 255, 0.26)',
				disabledBackground: 'rgba(255, 255, 255, 0.12)',
				focus: 'rgba(255, 255, 255, 0.12)'
			},
			background: {
				default: '#282C34',
				dark: '#1c2025',
				paper: '#282C34'
			},
			primary: {
				main: autowashColors.primaryBlue,
			},
			secondary: {
				main: autowashColors.primaryOrange,
			},
			warning: {
				main: '#D31D11'
			},
			success: {
				main: '#00FF00'
			},
			text: {
				primary: '#e6e5e8',
				secondary: '#adb0bb',
			}
		},
		shadows: strongShadows
	},
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
	let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

	if (!themeOptions) {
		console.warn(new Error(`The theme ${config.theme} is not valid`));
		[themeOptions] = themesOptions;
	}

	let theme = createMuiTheme(
		_.merge(
			{},
			baseOptions,
			themeOptions,
			{ direction: config.direction }
		)
	);

	if (config.responsiveFontSizes) {
		theme = responsiveFontSizes(theme);
	}

	return theme as Theme;
}
