import { differenceInDays, differenceInMonths, format } from "date-fns";

export type DateRange =
	{ start: Date, end: Date } | 
	"ALL_TIME" |
	"NO_RANGE";

interface IConfig {
	showYear: boolean;
}

export function formatDateDisplay(
	date: Date,
	config: IConfig = { showYear: false },
): string {
	const { showYear } = config;
	const dateFormat = showYear ? "MMMM d, yyyy" : "MMMM d";
	return format(date, dateFormat);
}

export function formatDateRangeDisplay(
	range: DateRange,
	config: IConfig = { showYear: false }
): string {
	if (range === "NO_RANGE") {
		return "nothing";
	} if (range === "ALL_TIME") {
		return "All time";
	}
	const { start, end } = range;
	const formattedStart = formatDateDisplay(start, config);
	const formattedEnd = formatDateDisplay(end, config);
	const showEnd = differenceInDays(end, start) > 1;
	return showEnd ? `${formattedStart} - ${formattedEnd}` : formattedStart;
}

export function rangesSpanYears(later: DateRange, earlier: DateRange): boolean {
	if (later === "ALL_TIME" || earlier === "ALL_TIME" || later === "NO_RANGE") {
		return true;
	} if (later.start && later.end && earlier === "NO_RANGE") {
		return differenceInMonths(later.end, later.start) >= 11;
	} if (earlier !== "NO_RANGE") {
		return (
			differenceInMonths(later.end, later.start) >= 11 || 
			differenceInMonths(later.end, earlier.start) >= 11
		);
	}
	return true;
}
