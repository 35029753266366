import * as Sentry from "@sentry/react";
import type { FC } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import {
	jssPreset,
	StylesProvider,
	ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import { AuthProvider } from 'src/contexts/AuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { routes, renderRoutes } from 'src/routes';
import { AWSProvider } from './contexts/AWSContext';
import ConfirmationModal from './components/ConfirmationModal';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorContextProvider from './contexts/ErrorContext';
import environment from "./environment";
import { TenantProvider } from './contexts/TenantContext';
import { StripeProvider } from "./contexts/StripeContext";
import { UserAndAccountProvider } from './contexts/UserAndAccountContext';
import { ScrollProvider } from "./contexts/ScrollContext";
import { GeneralInformationProvider } from './contexts/GeneralInformationContext';
import { FleetAccountsProvider } from "./contexts/FleetAccountsContext";

const dsn = environment.sentry.dsn;
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App: FC = () => {
	const { settings } = useSettings();
	const theme = createTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme
	});

	const env = process.env.NODE_ENV;

	if (env !== "development") {
		Sentry.init({
			dsn,
			environment: env,
		});
	}

	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<ThemeProvider theme={theme}>
				<StylesProvider jss={jss}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<SnackbarProvider
							dense
							maxSnack={10}
						>
							<ErrorContextProvider>
								<ErrorBoundary>
									<TenantProvider>
										<AuthProvider>
											<UserAndAccountProvider>
												<FleetAccountsProvider>
													<GeneralInformationProvider>
														<StripeProvider>
															<AWSProvider>
																<ScrollProvider>
																	<GlobalStyles />
																	<ConfirmationModal />
																	<Routes>
																		{renderRoutes(routes)}
																	</Routes>
																</ScrollProvider>
															</AWSProvider>
														</StripeProvider>
													</GeneralInformationProvider>
												</FleetAccountsProvider>
											</UserAndAccountProvider>
										</AuthProvider>
									</TenantProvider>
								</ErrorBoundary>
							</ErrorContextProvider>
						</SnackbarProvider>
					</MuiPickersUtilsProvider>
				</StylesProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
};

export default App;
