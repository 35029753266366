import type { FC } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { Theme } from "src/theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    },
    html: {
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      height: "100%",
      width: "100%"
    },
    body: {
      height: "100%",
      width: "100%"
    },
    "#root": {
      height: "100%",
      width: "100%"
    },
    ".MuiDataGrid-columnsContainer": {
      position: "sticky !important",
      zIndex: 10,
      backgroundColor: theme.palette.background.dark,
    },
  }
}));

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
