/* eslint-disable max-len, no-redeclare */
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useMountedRef } from "./useMountedRef";

/**
 * Use this hook when you have asynchronous actions and you don't
 * want to worry about setting state on unmounted component.
 *
 * It won't set state if component unmounted
 */
// prettier-ignore
export function useStateWithMounted<T = undefined>(): [T | undefined, Dispatch<SetStateAction<T | undefined>>];
// prettier-ignore
export function useStateWithMounted<T>(initialData: T | (() => T)): [T, Dispatch<SetStateAction<T>>];

export function useStateWithMounted<T>(initialData?: T | (() => T)) {
	const [data, _setData] = useState(initialData);
	const mountedRef = useMountedRef();

	const setData = useCallback((dispatcher: SetStateAction<typeof data>) =>{
		if (mountedRef.current) {
			_setData(dispatcher);
		}
	}, []);

	return [data, setData];
}
