import { HTMLProps, useContext, useState, useEffect } from 'react';
import { CircularProgress, makeStyles } from "@material-ui/core";
import { BrokenImageOutlined } from "@material-ui/icons";

import { TenantContext } from "src/contexts/TenantContext";
import axios from "src/utils/axios";
import { EEndpointType } from "amp";
import { IBrandImage } from 'amp';

type ILogoProps = HTMLProps<HTMLImageElement>;

export default function Logo(props: ILogoProps) {
	const {
		ampTenantKey: tenantKey,
		ampTenantImpersonateKey: impersonateKey,
		ampTenants: tenants,
	} = useContext(TenantContext);

	const classes = useStyles();

	const [imgSrc, setImgSrc] = useState<string | null>(null);
	const [impersonateImgSrc, setImpersonateImgSrc] = useState<string | null>(null);

	useEffect(() => {
		if (tenants.length) {
			setImgSrc(
				tenants.find(t => t.tenant_key === tenantKey)?.brand_logo_url ?? null
			);
		} else if (tenantKey) {
			fetchLogoUrl(tenantKey)
				.then(setImgSrc)
				.catch(console.error);
		}

		if (impersonateKey && tenants.length) {
			setImpersonateImgSrc(
				tenants.find(t => t.tenant_key === impersonateKey)
					?.brand_logo_url
					?? null
			);
		} else {
			setImpersonateImgSrc(null);
		}
	}, [tenantKey, impersonateKey, tenants]);

	if (impersonateImgSrc) {
 		return (
			<img className={classes.image}
				alt="Logo"
				src={impersonateImgSrc}
				{...props}
			/>
		); 
	}

	if (imgSrc) {
		return (
			<img className={classes.image}
				alt="Logo"
				src={imgSrc}
				{...props}
			/>
		); 
	}

	if (!tenants.length) {
		return (
			<CircularProgress
				className={classes.icon}
				size={28}
			/>
		); 
	}

	return <BrokenImageOutlined className={classes.icon} />;
}

const useStyles = makeStyles(({ palette }) => ({
	image: {
		width: "auto",
		height: 52,
	},

	icon: {
		color: palette.text.disabled,
	}
}));

async function fetchLogoUrl(
	tenantKey: string
): Promise<string | null> {
	const endpoint = `/${EEndpointType.Anonymous}/branding/get-brand-image-by-key/BrandLogo`;
	const response = await axios.get<IBrandImage>(endpoint, {
		headers: { "Amp-Tenant": tenantKey },
	});

	return response.data?.image_url__c ?? null;
}
