import React, { useEffect, useState } from "react";
import axios from "src/utils/axios";

export const useAxiosResponseInterceptor = (
	{ onSuccess, onFailure }: {
		onSuccess?: (context: { status: number }) => Promise<void>;
		onFailure?: (context: { status?: number }) => Promise<void>;
	},
): void => {
	const [tokenInterceptorId, setTokenInterceptorId] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (tokenInterceptorId !== undefined) {
			axios.interceptors.response.eject(tokenInterceptorId);
		}
		let tokenInterceptorIdNew: number | undefined;
		tokenInterceptorIdNew = axios.interceptors.response.use(
			async (response) => {
				if (onSuccess) {
					const status = response.status;
					await onSuccess({ status });
				}
				return response;
			},
			async (error) => {
				if (onFailure) {
					const status = error?.response?.status;
					await onFailure({ status });
				}
				throw error;
			},
		);
		setTokenInterceptorId(tokenInterceptorIdNew);
	}, [onSuccess, onFailure]);
};
