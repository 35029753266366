import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	makeStyles,
	Modal,
	Theme,
	Typography,
} from "@material-ui/core";
import { Clear, Refresh } from "@material-ui/icons";
import { useState, useCallback, useMemo } from "react";
import { IDisplayError } from "src/interfaces/display-error";

const useStyles = makeStyles<Theme>(({ palette }) => {
	return {
		centerContainter: {
			height: "100vh",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		errorCard: {
			minheight: "50%",
			width: 600,
		},
		errorHeader: {
			paddingBottom: 0,
			height: 30,
		},
		errorBody: {
			backgroundColor: palette.background.default,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-evenly",
			padding: "20px",
		},
		errorName: {
			color: palette.error.dark,
			fontWeight: "bold",
			paddingBottom: 20,
		},
		errorActions: {
			display: "flex",
			justifyContent: "center",
		},
		errorToast: {
			backgroundColor: palette.error.dark,
		},
		errorSee: {
			color: palette.error.contrastText,
			"&:hover": {
				backgroundColor: palette.error.light,
			},
		},
		errorDismiss: {
			borderRadius: 4,
			color: palette.error.contrastText,
			"&:hover": {
				backgroundColor: palette.background.default,
			},
		},
	};
});
interface IErrorModalProps {
	error: IDisplayError;
	dismissError: (errorId: string) => void;
	autoshowError?: boolean;
}

const _ERROR_NAME = "Something went wrong!";
const _UNKNOWN_ERROR_ID = "UNKNOWN";
const _DEFAULT_ERROR_MESSAGE =
	"There was an error trying to process your request. If this issue persists, please contact support.";

const ErrorModal = ({
	error,
	dismissError,
	autoshowError = true,
}: IErrorModalProps) => {
	const [showError, setShowError] = useState(autoshowError);
	const classes = useStyles();
	const dismissHandler = useCallback(() => {
		setShowError(false);
		dismissError(error.id);
	}, [dismissError, error]);
	const reloadHandler = useCallback(() => {
		window.location.reload();
	}, []);

	const errorMessage = useMemo(() => {
		if (error.id === _UNKNOWN_ERROR_ID || !error.message) {
			return _DEFAULT_ERROR_MESSAGE;
		}
		return error.message;
	}, [error]);

	return showError ? (
		<Modal open onBackdropClick={() => dismissHandler()}>
			<Box className={classes.centerContainter}>
				<Box className={classes.errorCard}>
					<Card>
						<CardHeader
							className={classes.errorHeader}
							action={(
        						<IconButton onClick={() => setShowError(false)}>
									<Clear />
								</IconButton>
      						)}
						/>
						<CardContent className={classes.errorBody}>
							<Typography
								className={classes.errorName}
								variant="h3"
								align="center"
							>
								{error.name ?? _ERROR_NAME}
							</Typography>
							<Typography variant="body1" align="center">
								{errorMessage}
							</Typography>
						</CardContent>
						<CardActions className={classes.errorActions}>
							<Button startIcon={<Clear />} onClick={dismissHandler}>
								Dismiss
							</Button>
							<Button startIcon={<Refresh />} onClick={reloadHandler}>
								Reload
							</Button>
						</CardActions>
					</Card>
				</Box>
			</Box>
		</Modal>
	) : null;
};

export default ErrorModal;
