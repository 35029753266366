import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import NavBar, { Section } from './NavBar';
import TopBar from './TopBar';
import { TenantContext } from 'src/contexts/TenantContext';
import LoadingScreen from 'src/components/LoadingScreen';
import { ScrollContext } from "src/contexts/ScrollContext";
import { useLocation } from "react-router";

interface DashboardLayoutProps {
  navSections: Section[];
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ navSections, children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const { ampTenantImpersonateKey } = useContext(TenantContext);
  const { setIsBottomScroll } = useContext(ScrollContext);
  const [reload, setReload] = useState(false);
  const location = useLocation();

  const contentContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
	  if (ampTenantImpersonateKey) {
		setReload(true);
		// Reload page on impersonate key change
		setTimeout(() => {
			setReload(false);
		});
	  }
  }, [ampTenantImpersonateKey]);

  const onScroll = useCallback((): void => {
    if (!contentContainerRef.current || !contentRef.current) return;
    const OFFSET = 25;
    const _isBottomScroll = ((contentRef.current.scrollHeight - contentContainerRef.current.offsetHeight)
      - contentRef.current.scrollTop) < OFFSET;
    setIsBottomScroll(_isBottomScroll);
  }, []);

  useEffect(() => {
    onScroll();
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        sections={navSections}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div
          className={classes.contentContainer}
          ref={contentContainerRef}
        >
          <div
            className={classes.content}
            onScroll={onScroll}
            ref={contentRef}
          >
            {reload ? <LoadingScreen /> : children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
