import React, {
  ReactNode,
} from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core";
import { Theme } from "src/theme";

interface Props {
  children?: ReactNode;
  title: string;
  className?: string;
}

const Page = ({
  children,
  title = '',
  className,
}: Props) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${className}`}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

export default Page;

