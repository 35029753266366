import React from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { EAuthPermission } from "src/contexts/AuthContext";

interface GuestGuardProps {
	children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
	const { loggedIn, userPermission } = useAuth();

	if (loggedIn) {
		if (userPermission === EAuthPermission.Fleet) {
			return <Navigate to="/fleet" />;
		} else if (userPermission === EAuthPermission.SiteLead) {
			return <Navigate to="/site-lead" />;
		} else {
			return <Navigate to="/app" />;
		}
	}

	return <>{children}</>;
};

GuestGuard.propTypes = {
	children: PropTypes.node,
};

export default GuestGuard;
