import { Container, Grid, makeStyles, StylesProvider } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import {
	LineChartTile,
	BarChartTile,
	IBarChartProps,
	IKpiTileProps,
	KpiTile,
	ILineChartProps,
} from "src/components/charts";
import Header, { Breadcrumb } from "src/components/Header";
import { TimeComparisonFilter } from "src/components/TimeComparisonFilter";
import Page from "src/components/Page";
import { Theme } from 'src/theme';
import { useFetchData } from "src/hooks/useFetchData";
import { ReportDataService } from "./report-data.service";
import { ReportKpiTileName, ReportKpiTileService } from "./report-kpi-tile.service";
import { ReportChartName, ReportChartService } from "./report-chart.service";
import LoadingScreen from "src/components/LoadingScreen";
import { DateRange } from "src/utils/formatDateRange";
import { INoDataChartProps, NoDataChartTile } from "src/components/charts/NoDataChartTile";
import { IAggregateUserTotal, IReportRequestIncrement, IAllReportsResponse } from "amp";
import "./styles.css";
import { GeneralInformationContext } from "src/contexts/GeneralInformationContext";
import { useNavigate } from "react-router";
import { UserService } from "../user-management/user.service";

export const REPORT_DASHBOARD_PATH = "/app/management/dashboard";
const PAGE_NAME = "Dashboard";

const breadcrumbs: Breadcrumb[] = [{
	label: PAGE_NAME,
	url: REPORT_DASHBOARD_PATH,
}];

type XReportKpiTiles = Record<ReportKpiTileName, IKpiTileProps | null>;
type XReportCharts = Record<ReportChartName, IBarChartProps | ILineChartProps | INoDataChartProps>;

export default function ReportDashboardView() {
	const navigate = useNavigate();
	const classes = useStyles();
	const { generalInformation } = useContext(GeneralInformationContext);
	const [time, setTime] = useState<DateRange>("NO_RANGE");
	const [comparison, setComparison] = useState<DateRange>("NO_RANGE");
	const [kpiTiles, setKpiTiles] = useState<XReportKpiTiles>({
		"Total Users": null,
		"Active Subscriptions": null,
		"Active Locations": null,
	});
	const [charts, setCharts] = useState<XReportCharts>({
		"Top 10 Washes by Type": null,
		"Top 10 Wash Locations": null,
		"Distinct User Logins": null,
		"Total Washes": null,
		"New Users": null,
		"New Subscriptions": null,
	});
	const { data: apiData, retry: fetchData, loading } = useFetchData<[
		IAllReportsResponse,
		[IAggregateUserTotal[], IAggregateUserTotal[]],
		number,
		number,
	] | null>(() => {
		const userRequest = ReportDataService.buildRequest(time, comparison);
		const allTimeRequest: IReportRequestIncrement = {};
		if (!userRequest) {
			return null;
		}
		return Promise.all([
			ReportDataService.getAllReports(userRequest),
			ReportDataService.getUsersTotals(allTimeRequest),
			ReportDataService.getActiveSubscriptionTotal(),
			ReportDataService.getActiveLocationsTotal(),
		]);
	}, { handleInitialDataLoad: false });

	useEffect(() => {
		if (generalInformation?.hide_portal_dashboard) {
			navigate(UserService.USER_PORTAL_URL);
		}
	}, [generalInformation?.hide_portal_dashboard]);

	useEffect(() => {
		fetchData();
	}, [time, comparison]);

	useEffect(() => {
		if (!apiData) {
			return;
		}
		const [
			{
				washesByType, washesByLocation, washesTotals,
				usersTotals, newSubscriptionTotals, distinctLoginTotals,
			},
			allTimeUsersTotals,
			activeSubscriptionTotal,
			activeLocationsTotal,
		] = apiData;
		const totalUsersKpiTileData = ReportKpiTileService.mapToTotalUsersKpiTile(allTimeUsersTotals);
		const activeLocationKpiTileData = ReportKpiTileService.mapToActiveLocationsKpiTile(activeLocationsTotal);
		const activeSubscriptionTile = ReportKpiTileService.mapToActiveSubscriptionKpiTile(activeSubscriptionTotal);
	
		const washesByTypeChartData = ReportChartService.mapToWashesByTypeChart(washesByType, time, comparison);
		const washesByLocationChartData = ReportChartService.mapToWashesByLocationChart(washesByLocation, time, comparison);
		const totalWashesChartData = ReportChartService.mapToTotalWashesChart(washesTotals, time, comparison);
		const newUsersChartData = ReportChartService.mapToNewUsersChart(usersTotals, time, comparison);
		const newSubscriptionsChartData = ReportChartService.mapToNewMembersChart(newSubscriptionTotals, time, comparison);
		const distinctLoginChartData = ReportChartService.mapToDistinctLogins(distinctLoginTotals, time, comparison);

		setKpiTiles({
			"Total Users": totalUsersKpiTileData,
			"Active Subscriptions": activeSubscriptionTile,
			"Active Locations": activeLocationKpiTileData,
		});
		setCharts({
			"New Users": newUsersChartData,
			"New Subscriptions": newSubscriptionsChartData,
			"Distinct User Logins": distinctLoginChartData,
			"Top 10 Washes by Type": washesByTypeChartData,
			"Top 10 Wash Locations": washesByLocationChartData,
			"Total Washes": totalWashesChartData,
		});
	}, [apiData]);

	return (
		<Page
			className={classes.root}
			title={PAGE_NAME}
		>
			<Container maxWidth={false}>
				<Header
					breadcrumbs={breadcrumbs}
					pageName={PAGE_NAME}
					itemName="Report"
					itemNamePlural="Reports"
				/>
				<Grid
					container
					spacing={3}
					style={{
						marginBottom: 0,
					}}
				>
					{renderKpiTiles(kpiTiles)}
				</Grid>
				<TimeComparisonFilter
					onTimeChange={setTime}
					onComparisonChange={setComparison}
				/>
				{loading
				? <LoadingScreen />
					: <Grid
						container
						spacing={3}
						style={{
							marginTop: 0,
						}}
					>
						{renderCharts(charts)}
					</Grid>
				}
			</Container>
		</Page>
	);
}

function renderKpiTiles(kpiTiles: XReportKpiTiles) {
	return Object
		.values(kpiTiles)
		.map((kpiTile) => (kpiTile &&
			<Grid item
				key={kpiTile.label}
				lg
			>
				<KpiTile {...kpiTile} />
			</Grid>
		));
}

export function renderCharts(charts: XReportCharts) {
	return Object
		.values(charts)
		.map((chart) => chart &&
			<StylesProvider
				injectFirst
				key={chart.name}
			>
				<Grid item
					xs={12}
					sm={6}
					xl={4}
				>
					{renderChart(chart)}
				</Grid>
			</StylesProvider>
		);
}

function renderChart(props: ILineChartProps | IBarChartProps | INoDataChartProps) {
	if ("data" in props && "bars" in props) {
		return <BarChartTile {...props} />;
	} else if ("dataSets" in props) {
		return <LineChartTile {...props as ILineChartProps} />;
	}
	return <NoDataChartTile {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	},
}));
