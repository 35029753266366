import {
	IFormatCurrencyOptions,
	formatCurrency as _formatCurrency,
} from "../../utils/formatCurrency";

/** Returns a configured currency formatter */
export function formatCurrency(options: IFormatCurrencyOptions) {
	return (value: number) => _formatCurrency(value, options);
}

/** Just converts the value to string */
export function formatNoop(value: number) {
	return value.toString(10);
}

/** Adds commas every 3 digits left of the decimal (if any) */
export function formatDefault(value: number) {
	return value.toLocaleString(undefined, {
		useGrouping: true,
		maximumFractionDigits: 3,
	});
}

/** Returns a customized formatter */
export function formatCustom(options: Intl.NumberFormatOptions) {
	return (value: number) => value.toLocaleString(undefined, options);
}
