import axios from "src/utils/axios";
import { EEndpointType } from "amp";
import {
	IReportRequest,
	IReportRequestIncrement,
	IAggregateWashLocation,
	IAggregateWashTotal,
	IAggregateWashType,
	IAggregateUserTotal,
	IAllReportsResponse,
} from "amp";
import { DateRange } from "src/utils/formatDateRange";
import { endOfDay } from "date-fns";

export enum EAggregateIncrement {
	Year = "YEAR",
	Month = "MONTH",
	Day = "DOY",
	Hour = "HOUR",
}

export const ReportDataService = {
	getAllReports,
	getWashesByType,
	getWashesByLocation,
	getTotalWashes,
	getUsersTotals,
	getActiveSubscriptionTotal,
	getActiveLocationsTotal,
	buildRequest,
};

const API_URL = `${EEndpointType.Csr}/reports/`;
const get = <TResponse>(endpoint: string): Promise<TResponse> => axios.get<TResponse>(API_URL + endpoint).then(x => x.data);
const post = <TRequest extends {}, TResponse>(
	endpoint: string,
	body: TRequest
): Promise<TResponse> => axios.post<TResponse>(API_URL + endpoint, body).then(x => x.data);

function getAllReports(request: IReportRequestIncrement): Promise<IAllReportsResponse> {
	return post<IReportRequestIncrement, IAllReportsResponse>(
		"get-all-reports",
		request,
	);
}

function getWashesByType(request: IReportRequest): Promise<IAggregateWashType[]> {
	return post<IReportRequest, IAggregateWashType[]>(
		"get-washes-by-type",
		request,
	);
}

function getWashesByLocation(request: IReportRequest): Promise<IAggregateWashLocation[]> {
	return post<IReportRequest, IAggregateWashLocation[]>(
		"get-washes-by-location",
		request,
	);
}

function getTotalWashes(request: IReportRequestIncrement): Promise<[IAggregateWashTotal[], IAggregateWashTotal[]]> {
	return post<IReportRequestIncrement, [IAggregateWashTotal[], IAggregateWashTotal[]]>(
		"get-washes-totals",
		request,
	);
}

function getUsersTotals(request: IReportRequestIncrement): Promise<[IAggregateUserTotal[], IAggregateUserTotal[]]> {
	return post<IReportRequestIncrement, [IAggregateUserTotal[], IAggregateUserTotal[]]>(
		"get-users-totals",
		request,
	);
}

function getActiveLocationsTotal(): Promise<number> {
	return get<number>(
		"get-active-locations-total",
	);
}

function getActiveSubscriptionTotal(): Promise<number> {
	return get<number>(
		"get-active-subscriptions-total",
	);
}

function buildRequest(time: DateRange, comparison: DateRange): IReportRequestIncrement | null {
	const request: IReportRequestIncrement = {
		increment: { chunk: EAggregateIncrement.Day, offset: -new Date().getTimezoneOffset(), }
	};
	if (time === "NO_RANGE" || comparison === "ALL_TIME") {
		return null;
	} if (time === "ALL_TIME") {
		return request;
	} if (comparison === "NO_RANGE") {
		request.period = {
			start: time.start.toISOString(),
			end: endOfDay(time.end).toISOString(),
		};
	} else {
		request.period = {
			start: time.start.toISOString(),
			end: endOfDay(time.end).toISOString(),
		};
		request.compare = {
			start: comparison.start.toISOString(),
			end: endOfDay(comparison.end).toISOString(),
		};
	}
	return request;
}
