export interface IFormatCurrencyOptions extends Intl.NumberFormatOptions {
	trimCents?: boolean;
};

export const formatCurrency = (
	value: number,
	options: IFormatCurrencyOptions = {},
): string => {
	options = { ...options };
	const trimCents = options.trimCents ?? false;
	delete options.trimCents;

	const maximumFractionDigits = trimCents ? 0 : 2;
	const minimumFractionDigits = maximumFractionDigits;

	return value.toLocaleString(undefined, {
		style: "currency",
		currency: "USD",
		maximumFractionDigits,
		minimumFractionDigits,
		...options,
	});
}
