import React, { FC, ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AUTOWASH_TENANT_KEY, TenantContext } from "src/contexts/TenantContext";
import SuperAdminGuard from "./SuperAdminGuard";

interface CreateTenantGuardProps {
	redirect?: boolean;
  children?: ReactNode;
}

const CreateTenantGuard: FC<CreateTenantGuardProps> = ({ redirect = true, children }) => {
  const { ampTenantImpersonateKey } = useContext(TenantContext);
  if (ampTenantImpersonateKey && ampTenantImpersonateKey !== AUTOWASH_TENANT_KEY) {
    if (redirect) {
			return <Navigate to="/app" />;
		} else {
			return null;
		}
  }
  return (
    <SuperAdminGuard redirect={redirect}>
      {children}
    </SuperAdminGuard>
  );
}

export default CreateTenantGuard;
