import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme'; 

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

interface LoadingScreenProps {
  loading?: boolean;
  cache?: boolean;
  minHeight?: number;
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ 
  children, 
  loading = true, 
  cache = false,
  minHeight,
}) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <>
      <div className={classes.root} style={{ display: loading ? "flex" : "none" }}>
        <Box style={{ minHeight, justifyContent: "center", display: "flex", flexDirection: "column" }} width={400}>
          <LinearProgress />
        </Box>
      </div>
      {/* hide children if loading and using cache */}
      {cache && (
        <div style={{ display: loading ? "none" : "block" }}>
          {children}
        </div>
      )}
      {/* unmount children if loading and not using cache */}
      {!cache && !loading && children}
    </>
  );
};

export default LoadingScreen;
