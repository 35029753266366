import { Button, useTheme } from "@material-ui/core";
import LocalCarWashRounded from "@material-ui/icons/LocalCarWashRounded";
import { NavLink } from "react-router-dom";

import CreateTenantGuard from "src/components/CreateTenantGuard";

export const CreateTenantButton = () => {
	const { palette } = useTheme();

	return (
		<CreateTenantGuard redirect={false}>
			<Button
				style={{ color: palette.primary.contrastText }}
				component={NavLink}
				to="/app/management/tenant/create"
				startIcon={<LocalCarWashRounded />}
			>
				Create Tenant
			</Button>
		</CreateTenantGuard>
	);
}
