import React, { useRef, useEffect } from "react";

/**
 * use this hook to avoid setting state on unmounted component
 */
export const useMountedRef = () => {
	const mountedRef = useRef(true);
	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);
	return mountedRef;
};