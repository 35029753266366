import React, { useEffect } from 'react';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { EAuthError } from 'src/contexts/AuthContext';

export enum EAppGuardType {
    Management,
    Fleet,
    SiteLead,
}

interface AppGuardProps {
    type: EAppGuardType;
    children?: ReactNode;
}

const AppGuard: FC<AppGuardProps> = ({ type, children }) => {
    const {
        isAuthorizedAppRoutes,
        isAuthorizedFleetRoutes,
        isAuthorizedSiteLeadRoutes,
        userLoggedIn,
        logOut
    } = useAuth();

    const isAuthorized =
        (
            type === EAppGuardType.Management &&
            isAuthorizedAppRoutes
        ) || (
            type === EAppGuardType.Fleet &&
            isAuthorizedFleetRoutes
        ) || (
            type === EAppGuardType.SiteLead &&
            isAuthorizedSiteLeadRoutes
        );

    useEffect(
        () => {
            if (userLoggedIn && !isAuthorized) {
                logOut(EAuthError.Unauthorized);
            }
        },
        [logOut, userLoggedIn, isAuthorized],
    );

    if (!isAuthorized) {
        return <>403 - not authorized</>
    }

	return <>{children}</>;
};

AppGuard.propTypes = {
    children: PropTypes.node
};

const ManagementGuard: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <AppGuard type={EAppGuardType.Management}>
            {children}
        </AppGuard>
    );
};

const FleetGuard: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <AppGuard type={EAppGuardType.Fleet}>
            {children}
        </AppGuard>
    );
};

const SiteLeadGuard: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <AppGuard type={EAppGuardType.SiteLead}>
            {children}
        </AppGuard>
    );
};

export {
    ManagementGuard,
    FleetGuard,
    SiteLeadGuard,
};
