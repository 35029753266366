import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import * as serviceWorker from "./serviceWorker";

// Force redirect to https
if (!window.location.href.includes("localhost") && window.location.protocol != "https:") {
  window.location.protocol = "https";
}
// Force '/portal' path base
if (!window.location.pathname.startsWith(process.env.PUBLIC_URL)) {
  window.history.replaceState(
    window.history.state,
    "",
    window.location.origin + process.env.PUBLIC_URL,
  );
}

ReactDOM.render(
  <SettingsProvider>
    <App />
  </SettingsProvider>,
  document.getElementById('root')
);

// Remove any lingering service workers
serviceWorker.unregister();
