import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState
} from 'react';

interface IScrollContext {
  setIsBottomScroll: Dispatch<SetStateAction<boolean>>;
  isBottomScroll: boolean;
}

const defaultScrollContext: IScrollContext = {
	setIsBottomScroll: () => false,
	isBottomScroll: false,
};

export const ScrollContext = createContext(defaultScrollContext);

export const ScrollProvider = ({ children }: { children: ReactNode }) => {
	const [isBottomScroll, setIsBottomScroll] = useState<boolean>(false);
	
  return (
	<ScrollContext.Provider value={{
		  isBottomScroll,
		  setIsBottomScroll
	}}>
      {children}
    </ScrollContext.Provider>
  );
};
