import { EFormType } from "src/enums/form-type.enum";
import { EPageType } from "src/enums/page-type.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.marketing}/gift-cards`;

const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;
const PROMOTION_FORM_ROUTE = `${BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const CREATE_PROMOTION_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
const EDIT_PROMOTION_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const COPY_PROMOTION_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.copy}/:id`;
const BULK_CREATE_FORM_ROUTE = `${BASE_ROUTE}/bulk-create`;
const TRANSACTION_DETAILS_ROUTE = `${BASE_ROUTE}/transaction-details/:id`;

export const GiftCardRoutes = {
	BASE_ROUTE,
	LIST_ROUTE,
	PROMOTION_FORM_ROUTE,
	CREATE_PROMOTION_ROUTE,
	EDIT_PROMOTION_ROUTE,
	COPY_PROMOTION_ROUTE,
	BULK_CREATE_FORM_ROUTE,
	TRANSACTION_DETAILS_ROUTE,
};
