/**
 * Simple utility which allows you to seed an FP pipe (like `lodash/fp/flow`)
 * with an initial value as the first pipe argument instead of passing it to
 * the function _returned_ by the pipe invocation. This makes for better
 * readability and much better type inference from TypeScript.
 *
 * @example
 * ```
 * // Without `from`:
 * let nums = [1, 2, 3, 4, 5, 6];
 * let sumOfSquaredEvens = flow(
 *    filter((n: number) => n % 2 === 0),
 *    map((n: number) => n * n),
 *    reduce((accum: number, current: number) => accum + current),
 * )(nums);
 *
 * // With `from`:
 * let nums = [1, 2, 3, 4, 5, 6];
 * let sumOfSquaredEvens = flow(
 *    from(nums),
 *    filter(n => n % 2 === 0), // <-- TS knows that `n` is a number here
 *    map(n => n * n),
 *    reduce((accum, current) => accum + current),
 * )();
 * ```
 */
export function from<T>(seedValue: T): () => T {
	return () => seedValue;
}
