import React from "react";
import { Typography } from "@material-ui/core";
import { ChartTile } from "./ChartTile";

export interface INoDataChartProps {
	/** The label printed at the top-left of the chart */
	name: string;
}

export function NoDataChartTile({ name }: INoDataChartProps) {
	return (
		<ChartTile
			title={name}
			fullScreen={false}
		>
			<div style={{
				position: "absolute",
				width: "100%",
				height: "100%",
				top: 0,
				left: 0,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontStyle: "italic",
			}}>
				<Typography
					color="textSecondary"
				>
					No data available
				</Typography>
			</div>
		</ChartTile>
	);
}
