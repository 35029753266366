import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EAccountApproach, EAccountType, IAccountContext, IAccountInvoice, IAutowashLocation, IBillingUser, IFleetAccountSubscriptionStagingConfiguration, IFleetAcountWithSubscriptionStagingConfig, IProductWithJson, XMaybe } from "amp";
import { useLocation } from "react-router";
import { useFetchData } from "src/hooks/useFetchData";
import { AccountService } from "src/views/user-management/account-service";
import { FleetAccountsService } from "../fleet-accounts.service";
import { EPriceJoinType, useFetchProducts } from "src/hooks/useFetchProducts";
import { UserService } from "src/views/user-management/user.service";
import useErrorBoundary from "src/hooks/useErrorBoundary";
import { useSnackbar } from "notistack";
import { useFetchDataDynamic } from "src/hooks/useFetchDataDynamic";

export interface IFleetContext {
	loading: boolean;
	loadingAccountData: boolean;
	accountContext: IAccountContext | undefined;
	subscriptionStagingConfig: XMaybe<IFleetAccountSubscriptionStagingConfiguration>;
	userBillingData: IBillingUser | undefined;
	upcomingInvoice: IAccountInvoice | undefined;
	refreshBillingData: () => Promise<void>;
	products: IProductWithJson[] | undefined;
	refreshAll: () => Promise<void>;
	updateAccountData: (accountData: IFleetAcountWithSubscriptionStagingConfig) => void;
	tryAccountAction: (
		action: () => Promise<IFleetAcountWithSubscriptionStagingConfig | false>,
		refreshBilling: boolean,
		successMessage?: string
	) => Promise<IFleetAcountWithSubscriptionStagingConfig | false>
	isCreate: boolean,
	priceId: number | undefined,
	locations: IAutowashLocation[] | undefined;
}

export const FleetContext = createContext<IFleetContext>({
	loading: false,
	loadingAccountData: false,
	isCreate: false,
	accountContext: undefined,
	subscriptionStagingConfig: undefined,
	userBillingData: undefined,
	upcomingInvoice: undefined,
	products: undefined,
	priceId: undefined,
	locations: undefined,
	refreshAll: async () => {},
	tryAccountAction: async () => false,
	updateAccountData: () => undefined,
	refreshBillingData: async () => {},
});

interface IFleetContextProps {
	fleetAccountId: string | undefined;
}
export const FleetProvider = ({ children, fleetAccountId }: PropsWithChildren<IFleetContextProps>) => {
	const location = useLocation();
	const _tryAction = useErrorBoundary();
	const { enqueueSnackbar } = useSnackbar();
	const isCreate = location.pathname.includes(FleetAccountsService.PORTAL_URL_CREATE);
	const fleetAccountIdRef = useRef<string>();
	
	const [updating, setUpdating] = useState(false);

	const _getAccountData = useCallback(() => {
		if (fleetAccountId) {
			return FleetAccountsService.getFleetAccountData(fleetAccountId);
		}
		return null;
	}, [fleetAccountId]);

	const {
		data: accountData,
		loading: loadingAccountData,
		setData: updateAccountData,
		retry: refreshAccountData,
	} = useFetchData(_getAccountData);

	const accountContext = accountData?.accountContext;
	const subscriptionStagingConfig = accountData?.subscriptionStagingConfig;
	
	const _getBillingData = useCallback(() => {
		if (accountContext) {
			const primaryUserId = accountContext.userPrimary.external_id__c;
			return UserService.getBillingUser(primaryUserId);
		}
		return null;
	}, [accountContext]);

	const _getUpcomingInvoice = useCallback(async () => {
		if (fleetAccountId) {
			const invoices = await AccountService.getUpComingInvoices([fleetAccountId]);
			return invoices[0];
		}
	}, [fleetAccountId]);
	
	const {
		data: userBillingData,
		retry: updateUserBillingData,
		loading: loadingBillingUser,
	} = useFetchData(_getBillingData);

	const {
		data: upcomingInvoice,
		retry: updateUpcomingInvoice
	} = useFetchData(_getUpcomingInvoice);

	const {
		products,
		loadingProducts,
	} = useFetchProducts({ 
		accountType: EAccountType.Fleet, 
		handleInitialLoad: true, 
		priceJoinType: EPriceJoinType.All 
	});

	const { data: locations, loading: loadingLocations, } = useFetchDataDynamic("locations", {
		condition: {
			left: { column: "is_active" },
			operator: "EQ",
			right: true,
		},
		orderBys: [
			{ column: "internal_name__c", direction: "ASC" },
			{ column: "name", direction: "ASC" }
		],
	});

	const refreshAll = useCallback(async () => {
		await Promise.all([refreshAccountData(), updateUserBillingData(), updateUpcomingInvoice()]);
	}, [updateAccountData, updateUserBillingData, updateUpcomingInvoice]);
	
	const refreshBillingData = useCallback(async () => {
		await Promise.all([updateUserBillingData(), updateUpcomingInvoice()]);
	}, [updateUserBillingData, updateUpcomingInvoice]);

	const tryAccountAction = useCallback(async (
		action: () => Promise<IFleetAcountWithSubscriptionStagingConfig | false>, 
		refreshBilling: boolean,
		successMessage?: string,
	): Promise<IFleetAcountWithSubscriptionStagingConfig | false> => {
		// Start loading
		setUpdating(true);
		// Take account action
		const updatedFleetAccountData = await _tryAction(action, () => {
			setUpdating(false);
			enqueueSnackbar("Fleet Account update failed", {
				variant: 'error'
			});
		}, () => {
			enqueueSnackbar(successMessage ?? "Account successfully updated", { variant: "success" });
		});
		// Check if updated account data exists
		if (updatedFleetAccountData) {
			// Update account data
			updateAccountData(updatedFleetAccountData);
		}
		// Stop loading
		setUpdating(false);
		// Check if should refresh billing data
		if (refreshBilling) {
			// Refresh billing data
			await refreshBillingData();
		}
		// Return result
		return updatedFleetAccountData;
	}, [_tryAction, refreshBillingData]);

	const accountPriceId = useMemo(() => {
		const accountApproach = accountContext?.account.account_approach__c;
		return (accountApproach === EAccountApproach.ChargePerVehicleSingle 
			|| accountApproach === EAccountApproach.ChargePerVehicleMulti)  
			? accountContext?.accountVehicles.find(
				({ vehicle }
			) => !!vehicle.price_id)?.vehicle.price_id 
			: accountContext?.accountUsers.find(
					({ mobileUserAccount }
				) => !!mobileUserAccount.price_id)?.mobileUserAccount.price_id;
	}, [accountContext]);

	useEffect(() => {
		if (accountContext) {
			updateUserBillingData();
		}
	}, [!!accountContext]);

	useEffect(() => {
		if (fleetAccountIdRef.current && fleetAccountIdRef.current !== fleetAccountId) {
			refreshAll();
		}
		fleetAccountIdRef.current = fleetAccountId;
	}, [fleetAccountId]);

	const loadingContextData =
		loadingAccountData
		|| loadingBillingUser
		|| loadingProducts
		|| loadingLocations
		|| updating;

	const value = useMemo(() => ({
		loading: loadingContextData,
		loadingAccountData,
		accountContext,
		subscriptionStagingConfig,
		userBillingData,
		upcomingInvoice,
		updateAccountData,
		refreshBillingData,
		refreshAll,
		tryAccountAction,
		isCreate,
		products,
		priceId: subscriptionStagingConfig?.requested_price_id || accountPriceId,
		locations,
	}), [
		loadingAccountData,
		accountContext,
		subscriptionStagingConfig,
		userBillingData,
		upcomingInvoice,
		updateAccountData,
		refreshBillingData,
		refreshAll,
		tryAccountAction,
		isCreate,
		products,
		accountPriceId,
		locations,
		loadingContextData,
	]);

	return (
		<FleetContext.Provider value={value}>
			{children}
		</FleetContext.Provider>
	);
};
