import axios from 'axios';
import environment from 'src/environment';

const axiosInstance = axios.create({
  baseURL: environment.api.url,
});

axiosInstance.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export default axiosInstance;
