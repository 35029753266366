const environment = {
	api: {
		url: "https://api-devops.ampmemberships.com",
	},
	localhost: {
		tenantKey: "",
	},
	aws: {
		url: "https://devops-ampmemberships-com.s3.us-west-1.amazonaws.com/public",
	},
	sentry: {
		dsn: "https://2d20c4baab7544c3b5dc49347770b852@o4505036277481472.ingest.sentry.io/4505036297732096",
	},
	google: {
		maps_api: "AIzaSyBWo_SGwBfdpeD6l8AsYz_iQPyB3qmu7t8",
	}
};

export default environment;