import React, { Component, CSSProperties, ReactNode } from "react";
import { Subject, fromEvent } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

interface Props {
	ratio: number;
	children: ReactNode | ReactNode[];
	onResize?(width: number, height: number): void;
}

export class Aspect extends Component<Props> {
	private _containerRef?: HTMLElement | null;
	private _willUnmount$ = new Subject<void>();

	get height(): string {
		return ((1 / this.props.ratio) * 100) + "%";
	}

	constructor(props: Props) {
		super(props);
	}

	componentDidMount() {
		fromEvent(window, "resize")
			.pipe(
				debounceTime(200),
				takeUntil(this._willUnmount$),
			)
			.subscribe(() => {
				if (!this._containerRef) return;
				const { clientWidth, clientHeight } = this._containerRef;
				this.props.onResize?.(clientWidth, clientHeight);
			});
	}

	componentWillUnmount() {
		this._willUnmount$.next();
		this._willUnmount$.complete();
	}

	updateContainerRef = (node: HTMLElement | null) => {
		this._containerRef = node;
		if (!node) return;
		const { clientWidth, clientHeight } = node;
		this.props.onResize?.(node.clientWidth, node.clientHeight);
	}

	render = () => (
		<div
			ref={this.updateContainerRef}
			style={styles.container}
		>
			<div style={styles.content}>
				{this.props.children}
			</div>
			<div style={{ paddingTop: this.height }}></div>
		</div>
	);
}

const styles: Record<string, CSSProperties> = {
	container: {
		width: `100%`,
		position: `relative`,
	},
	content: {
		position: `absolute`,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
}
