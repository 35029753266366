import { useMemo, useState } from "react";
import { useTheme } from "@material-ui/core";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChartTile } from "./ChartTile";
import { ChartTooltip } from "./ChartTooltip";
import { ChartLegend, ELegendShape } from "./ChartLegend";
import { transparentize } from "amp";
import { truncate } from "lodash";

export interface IBarChartDatum {
	/** The label printed below a datum */
	name: string;
	/** The bar names and values to be plotted for a datum */
	bars: Record<string, number>;
}

export interface IBarChartBar {
	/** The name of the bar. Must be consistent in all data */
	name: string;
	/** The color of the bar. If omitted, the `colorKey` value will be referenced */
	color?: string;
	/** A key that points to a specific, preselected color value */
	colorKey?: ColorKey;
}

export interface IBarChartProps {
	/** The label printed at the top-left of the chart */
	name: string;
	/** The data to be plotted on the chart */
	data: IBarChartDatum[];
	/**
	 * Information about the bars used to label and style
	 * the bars in the data and the legend
	 * */
	bars: IBarChartBar[];
	hideLegend?: boolean;
	display?: "vertical" | "horizontal";
}

type ColorKey = "primary" | "secondary";

const axisFontSize = 14;

export function BarChartTile({ data, bars, name, hideLegend, display }: IBarChartProps) {
	const { palette } = useTheme();
	const barColors: Record<ColorKey, string> = {
		primary: palette.type === "light" ? palette.primary.main : palette.primary.light,
		secondary: transparentize(palette.text.disabled, 0.75),
	};
	const [fullScreen, setFullScreen] = useState(false);
	const [animations, setAnimations] = useState(true);

	/**
	 * An array of objects: one contains a "name" property with a string value to
	 * represent the datum name; the rest contain a string property with numeric
	 * values to represent the datum bars
	 */
	const formattedData = useMemo<Record<string, string | number>[]>(() => {
		return data.map(({ name, bars }) => ({
			name,
			...bars,
		}));
	}, [data]);

	const formattedBars = useMemo<{ name: string, color: string }[]>(() => {
		return bars?.map(({ name, color, colorKey }) => ({
			name,
			color: color || barColors[colorKey ?? "primary"],
		}));
	}, [bars]);

	function handleFullScreenChange(value: boolean) {
		if (animations) {
			setAnimations(false);
		}
		setFullScreen(value);
	}

	return (
		<ChartTile
			title={name}
			fullScreen={fullScreen}
			onFullScreenChange={handleFullScreenChange}
		>
			<ResponsiveContainer
				aspect={fullScreen || display === "vertical" ? undefined : 2}
				width={fullScreen ? window.innerWidth - 32 : '100%'}
				height={fullScreen ? window.innerHeight - 128 : display === "vertical" ? data.length * 40 + 60 : '100%'}
				// something about BarChart requires this debounce to avoid ResizeObserver loop limit exceeded error
				// https://github.com/recharts/recharts/issues/1770#issuecomment-530329412
				debounce={50}
			>
				<BarChart
					data={formattedData}
					margin={{
						top: 0,
						right: 16,
						left: 0,
						bottom: 16,
					}}
					layout={display === "vertical" ? "vertical" : undefined}
					barSize={!fullScreen && display === "vertical" ? 30 : undefined}
					barGap={!fullScreen && display === "vertical" ? 10 : undefined}
				>
					<CartesianGrid
						style={{ stroke: palette.divider }}
						horizontal={display !== "vertical"}
						vertical={false}
					/>
					<XAxis
						dataKey={display === "vertical" ? undefined : "name"}
						type={display === "vertical" ? "number" : "category"}
						style={{ fill: palette.text.secondary }}
						axisLine={{ stroke: palette.divider }}
						tick={display === "vertical" ? undefined : <CustomizedAxisTick color={palette.text.secondary} />}
						tickLine={false}
						interval={0}
						allowDecimals={false}
					/>
					<YAxis
						dataKey={display === "vertical" ? "name" : undefined}
						type={display === "vertical" ? "category" : "number"}
						style={{
							fill: palette.text.disabled,
						}}
						width={display === "vertical" ? 150 : undefined}
						axisLine={{ stroke: palette.divider }}
						tickLine={false}
						fontSize={axisFontSize}
						tickFormatter={(value) => {
							if (value.length > 17) {
								return value.substring(0, 17) + "...";
							}
							return value;
						}} 
					/>
					<Tooltip
						isAnimationActive={false}
						content={<ChartTooltip opacity={0.8} />}
						cursor={{ fill: (palette.background as any).dark }}
					/>
					{!hideLegend && (
						<Legend
							content={<ChartLegend shape={ELegendShape.Bar} />}
						/>
					)}
					{formattedBars.map(({ name, color }) => (
						<Bar
							key={name}
							dataKey={name}
							fill={color}
							isAnimationActive={animations}
						/>
					))}
				</BarChart>
			</ResponsiveContainer>
		</ChartTile>
	);
}

function CustomizedAxisTick({ x, y, payload, color }: any) {
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={16}
				textAnchor="end"
				fontSize={axisFontSize}
				fill={color}
				transform="rotate(-35)"
			>
				{truncate(payload?.value, { length: 12 })}
			</text>
		</g>
	);
}