import { EEndpointType } from "amp";
import { IGeneralInformation } from "amp";
import axios from "src/utils/axios";
import { XSalesforceMultiTenantWritable } from "amp";

const BRANDING_ENDPOINT_ADMIN = `${EEndpointType.Admin}/branding`;
const BRANDING_ENDPOINT_ANONYMOUS = `${EEndpointType.Anonymous}/branding`;
const ITEM_NAME = "General Information";
const PAGE_NAME = "Update General Information";
const PORTAL_URL = "management/branding/general";

const getGeneralInformationForAdmin = async (): Promise<IGeneralInformation> => {
	const result = await axios.get<IGeneralInformation[]>(`${BRANDING_ENDPOINT_ADMIN}/get-general-information`);
	return result.data.length ? result.data[0] : null;
};

const getGeneralInformationForAnonymous = async (): Promise<IGeneralInformation> => {
	const result = await axios.get<IGeneralInformation[]>(`${BRANDING_ENDPOINT_ANONYMOUS}/get-general-information`);
	return result.data.length ? result.data[0] : null;
};

const handleUpdateGeneralInformation = async (
	generalInformation: XSalesforceMultiTenantWritable<IGeneralInformation>
): Promise<IGeneralInformation> => {
	const result = await axios.post(`${BRANDING_ENDPOINT_ADMIN}/update-general-information`, { generalInformation });
	return result.data;
};

const GeneralService = {
	ITEM_NAME,
	PORTAL_URL,
	PAGE_NAME,
    handleUpdateGeneralInformation,
	getGeneralInformationForAdmin,
	getGeneralInformationForAnonymous,
};

export default GeneralService;