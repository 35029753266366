import { keyBy, orderBy } from "lodash";
import { addDays, getDayOfYear, differenceInDays, startOfTomorrow, setDayOfYear } from "date-fns";

import {
	IAggregateUserTotal,
	IAggregateWashLocation,
	IAggregateWashTotal,
	IAggregateWashType,
	IAggregateNewSubscriptionsTotal,
	IAggregateDistinctLoginTotal,
	IAggregateResultDataIncrements,
} from "amp";
import { DateRange, formatDateDisplay, formatDateRangeDisplay, rangesSpanYears } from "src/utils/formatDateRange";
import { IBarChartBar, IBarChartProps } from "src/components/charts/BarChartTile";
import { EHeaderType, ILineChartProps } from "src/components/charts/LineChartTile";
import { formatCurrency } from "src/utils/formatCurrency";
import { INoDataChartProps } from "src/components/charts/NoDataChartTile";

export const ReportChartService = {
	mapToWashesByTypeChart,
	mapToWashesByLocationChart,
	mapToTotalWashesChart,
	mapToNewUsersChart,
	mapToNewMembersChart: mapToNewSubscriptionsChart,
	mapToDistinctLogins,
};

export type ReportChartName =
	"Top 10 Washes by Type" |
	"Top 10 Wash Locations" |
	"Total Washes" |
	"New Users" |
	"New Subscriptions" |
	"Distinct User Logins";

function mapToWashesByTypeChart(
	washesByType: IAggregateWashType[],
	time: DateRange,
	comparison: DateRange,
): IBarChartProps | INoDataChartProps {
	const topTenResults = getTopNResults(10, washesByType, ({ total_count }) => total_count);
	return _mapToBarChart<IAggregateWashType>({
		name: "Top 10 Washes by Type",
		data: topTenResults,
		getInfo: ({ wash_type_name, total_count, total_count_compare }) => ({
			name: wash_type_name,
			totalCount: total_count,
			totalCountCompare: total_count_compare,
		}),
		time,
		comparison,
	});
}

function mapToWashesByLocationChart(
	washesByLocation: IAggregateWashLocation[],
	time: DateRange,
	comparison: DateRange,
): IBarChartProps | INoDataChartProps {
	const topTenResults = getTopNResults(10, washesByLocation, ({ total_count }) => total_count);
	return _mapToBarChart<IAggregateWashLocation>({
		name: "Top 10 Wash Locations",
		data: topTenResults,
		getInfo: ({ location_name, total_count, total_count_compare }) => ({
			name: location_name,
			totalCount: total_count,
			totalCountCompare: total_count_compare,
		}),
		time,
		comparison,
	});
}

function mapToTotalWashesChart(
	totalWashes: [IAggregateWashTotal[], IAggregateWashTotal[]] | null,
	time: DateRange,
	comparison: DateRange,
): ILineChartProps | INoDataChartProps {
	return _mapToLineChart<IAggregateWashTotal>({
		name: "Total Washes",
		data: totalWashes,
		getValue: (datum) => datum?.total_count ?? 0,
		time,
		comparison,
	});
}

function mapToDistinctLogins(
	distinctLogins: [IAggregateDistinctLoginTotal[], IAggregateDistinctLoginTotal[]] | null,
	time: DateRange,
	comparison: DateRange,
): ILineChartProps | INoDataChartProps {
	return _mapToLineChart<IAggregateDistinctLoginTotal>({
		name: "Distinct User Logins",
		data: distinctLogins,
		getValue: (datum) => datum?.total_logins ?? 0,
		time,
		comparison,
	});
}

function mapToNewUsersChart(
	usersData: [IAggregateUserTotal[], IAggregateUserTotal[]],
	time: DateRange,
	comparison: DateRange,
): ILineChartProps | INoDataChartProps {
	return _mapToLineChart<IAggregateUserTotal>({
		name: "New Users",
		data: usersData,
		getValue: (datum) => datum?.users_added ?? 0,
		time,
		comparison,
	});
}

function mapToNewSubscriptionsChart(
	membersData: [IAggregateNewSubscriptionsTotal[], IAggregateNewSubscriptionsTotal[]],
	time: DateRange,
	comparison: DateRange,
): ILineChartProps | INoDataChartProps {
	return _mapToLineChart<IAggregateNewSubscriptionsTotal>({
		name: "New Subscriptions",
		data: membersData,
		getValue: (datum) => datum?.subscriptions_total ?? 0,
		time,
		comparison,
	});
}

function _mapToBarChart<T>(
	{ data, getInfo, name, time, comparison }: {
		name: ReportChartName;
		data: T[];
		time: DateRange;
		comparison: DateRange;
		getInfo: (datum: T) => {
			name: string;
			totalCount: number;
			totalCountCompare?: number;
		};
	}
): IBarChartProps | INoDataChartProps {
	if (!data?.length) {
		return { name };
	}
	const showYear = rangesSpanYears(time, comparison);
	const timeDisplay = formatDateRangeDisplay(time, { showYear });
	const comparisonDisplay = formatDateRangeDisplay(comparison, { showYear });
	const comparisonExists = comparison !== "NO_RANGE";
	const legendBars: IBarChartBar[] = [{ name: timeDisplay, colorKey: "primary" }];
	if (comparisonExists) {
		legendBars.unshift({ name: comparisonDisplay, colorKey: "secondary" });
	}
	return {
		name,
		data: data.map((datum) => {
			const { name: datumName, totalCount, totalCountCompare } = getInfo(datum);
			const datumBars = { [timeDisplay]: totalCount };
			if (comparisonExists) {
				datumBars[comparisonDisplay] = totalCountCompare ?? 0;
			}
			return {
				name: datumName,
				bars: datumBars,
			};
		}),
		bars: legendBars,
	};
}

function _mapToLineChart<T extends IAggregateResultDataIncrements>(
	{ data, name, time, comparison, getValue, isMonetary, headerType }: {
		name: ReportChartName,
		data: [T[], T[]] | null,
		time: DateRange,
		comparison: DateRange,
		getValue: (datum?: T, previous?: number) => number,
		isMonetary?: boolean,
		headerType?: EHeaderType,
	}
): ILineChartProps | INoDataChartProps {
	const [timeData, comparisonData] = data;
	if (!timeData.length && !comparisonData.length) {
		return { name };
	}
	const timeDataKeyedByDay = keyBy<T>(timeData, _getDay);
	const comparisonDataKeyedByDay = keyBy<T>(comparisonData, _getDay);
	const showYear = rangesSpanYears(time, comparison);
	const timeDisplay = formatDateRangeDisplay(time, { showYear });
	const comparisonDisplay = formatDateRangeDisplay(comparison, { showYear });
	const allTimeStart = _getFirstDate<T>(timeData, _getDate);
	const primaryValues = _generateLineChartPoints({
		..._getStartEnd(time, allTimeStart),
		getLabel: (date) => formatDateDisplay(date, { showYear }),
		getValue: (date, previous) => {
			const dayOfYear = getDayOfYear(date);
			const datum = timeDataKeyedByDay[dayOfYear];
			return getValue(datum, previous);
		},
	});
	const comparisonValues = _generateLineChartPoints({
		..._getStartEnd(comparison),
		getLabel: (date) => formatDateDisplay(date, { showYear }),
		getValue: (date, previous) => {
			const dayOfYear = getDayOfYear(date);
			const datum = comparisonDataKeyedByDay[dayOfYear];
			return getValue(datum, previous);
		},
	});
	return {
		name,
		dataSets: {
			primary: {
				name: timeDisplay,
				values: primaryValues,
			},
			...(comparisonValues && {
				compare: {
					name: comparisonDisplay,
					values: comparisonValues,
				},
			}),
		},
		...(isMonetary && { format: formatCurrency }),
		headerType: headerType ?? EHeaderType.Sum,
	};
}

function _generateLineChartPoints(
	{ start, end, getLabel, getValue }: {
		start: Date | null,
		end: Date | null,
		getLabel: (date: Date) => string,
		getValue: (date: Date, previous?: number) => number,
	},
): { label: string, value: number }[] | null {
	if (!start && !end) {
		return null;
	}
	const points: { label: string, value: number }[] = [];
	let difference = Math.round(differenceInDays(end, start));
	let date = start;
	let previous: number | undefined;
	while (difference-- >= 0) {
		const label = getLabel(date);
		const value = getValue(date, previous);
		points.push({ label, value });
		date = addDays(date, 1);
		previous = value;
	}
	return points;
}

function _getStartEnd(
	range: DateRange,
	allTimeStart: Date = new Date(),
): { start: Date | null, end: Date | null } {
	if (range === "NO_RANGE") {
		return { start: null, end: null };
	} if (range === "ALL_TIME") {
		return { start: allTimeStart, end: startOfTomorrow() };
	} 
		return range;
}

function _getFirstDate<T>(
	items: T[],
	getTime: (datum: T) => {
		day: number;
		year: number;
	},
): Date | null {
	const itemsSortedByTimeAscending = items
		.slice()
		.sort((a, b) => {
			const aTime = getTime(a);
			const bTime = getTime(b);
			const diff = aTime.year - bTime.year;
			return diff == 0 ? aTime.day - bTime.day : diff;
		});
	const first = getTime(itemsSortedByTimeAscending?.[0]);
	if (first.year && first.day) {
		return setDayOfYear(new Date(first.year, 1), first.day);
	}
	return null;
}

function _getDate(datum?: IAggregateResultDataIncrements): { year: number; day: number } {
	const day = _getDay(datum);
	const year = _getYear(datum);
	return { day, year };
}

function _getDay(datum?: IAggregateResultDataIncrements): number {
	return datum?.day ?? 0;
}

function _getYear(datum?: IAggregateResultDataIncrements): number {
	return datum?.year ?? 0;
}

function getTopNResults<T, TValue>(n: number, data: T[], getSortValue: (datum: T) => TValue) {
	return orderBy(data, (datum: T) => getSortValue(datum), "desc").slice(0, n);
}
