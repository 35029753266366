import { ENavCategory } from "src/enums/nav-category.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { EPageType } from "src/enums/page-type.enum";
import { EFormType } from "src/enums/form-type.enum";
import { IRouter } from "src/types/router";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.hardware}/scan-results`;
const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;
const FORM_ROUTE = `${BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const EDIT_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const COPY_ROUTE = "";

export const ScanResultsRoutes: IRouter<false> = {
	BASE_ROUTE,
	CREATE_ROUTE: undefined,
	LIST_ROUTE,
	FORM_ROUTE,
	EDIT_ROUTE,
	COPY_ROUTE,
};
