import { useEffect, useMemo, useState } from "react";
import type { FC, ReactNode } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
	Button,
	Collapse,
	ListItem,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import type { Theme } from "src/theme";
import { PORTAL_TABLE_STATE } from "src/constants";
import { useHasPermission } from "src/hooks/useHasPermission";
import { EAuthPermission } from "src/contexts/AuthContext";
import MdiIcon from "@mdi/react";
import { useShowBeta } from "src/hooks/useShowBeta";
import { CSSProperties } from "@material-ui/styles";

interface NavItemProps {
	children?: ReactNode;
	className?: string;
	depth: number;
	href?: string;
	icon?: any;
	info?: any;
	open?: boolean;
	permissionLevel?: EAuthPermission;
	beta?: boolean;
	title: string;
	active?: boolean;
	pathName?: string;
}

interface StyleProps {
	active?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	item: {
		display: "block",
		paddingTop: 0,
		paddingBottom: 0,
	},
	itemLeaf: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0,
	},
	button: {
		color: theme.palette.text.secondary,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
	},
	buttonLeaf: {
		color: theme.palette.text.secondary,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		// unknown typescript issue with fontweight requires typecast
		fontWeight: theme.typography
			.fontWeightRegular as CSSProperties["fontWeight"],
		"&.depth-0": {
			"& $title": {
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
	},
	icon: (props) => ({
		display: "flex",
		alignItems: "center",
		marginRight: theme.spacing(1),
		color: props.active ? theme.palette.secondary.main : "inherit",
	}),
	title: (props) => ({
		marginRight: "auto",
		color: props.active ? theme.palette.secondary.main : "inherit",
	}),
	active: {
		color: theme.palette.secondary.main,
		"& $title": {
			fontWeight: theme.typography.fontWeightMedium,
		},
		"& $icon": {
			color: theme.palette.secondary.main,
		},
	},
	beta: {
		color: theme.palette.info.main,
	},
}));

const NavItem: FC<NavItemProps> = ({
	children,
	className,
	depth,
	href,
	icon,
	info: Info,
	open: openProp,
	title,
	permissionLevel,
	beta,
	active,
	pathName,
	...rest
}) => {
	const classes = useStyles({ active: active ?? false });
	const { palette } = useTheme();

	const NavItemIcon = useMemo(() => {
		if (typeof icon === "object" && "displayName" in icon) {
			return () => <FeatherIcon icon={icon} active={active} />;
		}

		if (typeof icon === "string") {
			return () => (
				<MdiIcon
					path={icon}
					style={{
						width: 24,
						height: 24,
						padding: 2,
						marginRight: 8,
						color: active ? palette.secondary.main : "inherit",
					}}
				/>
			);
		}
		return icon;
	}, [icon, active]);
	const [open, setOpen] = useState<boolean>(!!openProp);

	useEffect(() => {
		// If open prop changes, or we go to a  different page, reset the open state
		setOpen(!!openProp);
	}, [openProp, pathName]);

	const handleToggle = (): void => {
		setOpen((prevOpen) => !prevOpen);
	};

	let paddingLeft = 8;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const style = { paddingLeft };

	const hasPermission = useHasPermission(permissionLevel);
	const showBeta = useShowBeta(beta);

	if (!hasPermission || !showBeta) {
		return <></>;
	}

	if (children) {
		return (
			<ListItem
				className={clsx(classes.item, className)}
				disableGutters
				key={title}
				{...rest}
			>
				<Button className={classes.button} onClick={handleToggle} style={style}>
					{NavItemIcon && (
						<NavItemIcon className={classes.icon} width={24} height={24} />
					)}
					<span className={classes.title}>{title}</span>
					{!!beta && <span className={classes.beta}>BETA</span>}
					{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				</Button>
				<Collapse in={open}>{children}</Collapse>
			</ListItem>
		);
	}

	return (
		<ListItem
			className={clsx(classes.itemLeaf, className)}
			disableGutters
			selected={active}

			key={title}
			{...rest}
		>
			<Button
				className={clsx(classes.buttonLeaf, `depth-${depth}`)}
				component={RouterLink}
				style={style}
				to={href ?? "#"}
				onClick={() =>
					window.sessionStorage.removeItem(`${PORTAL_TABLE_STATE}--${href}`)
				}
			>
				{NavItemIcon && (
					<NavItemIcon
						className={classes.icon}
						width={24}
						height={24}
						color={palette.secondary.main}
					/>
				)}
				<span className={classes.title}>{title}</span>
				{!!beta && <span className={classes.beta}>BETA</span>}
				{Info && <Info />}
			</Button>
		</ListItem>
	);
};

/**
 * Simple utility to make React Feather icons appear the same size as the Material UI icons
 */
function FeatherIcon({ icon: Icon, active }) {
	const { palette } = useTheme();
	return (
		<div
			style={{
				width: 24,
				height: 24,
				padding: 2,
				marginRight: 8,
				color: active ? palette.secondary.main : "inherit",
			}}
		>
			<Icon size={20} />
		</div>
	);
}

NavItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	depth: PropTypes.number.isRequired,
	href: PropTypes.string,
	icon: PropTypes.elementType,
	info: PropTypes.elementType,
	open: PropTypes.bool,
	title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
	open: false,
};

export default NavItem;
