import { EErrorId, IError, XMaybe, getError, mapPromoCodeStatusToErrorMessage } from "amp";
import { IDisplayError } from "src/interfaces/display-error";

export const handleInvalidPromoCodeError = (error: IError<unknown>): XMaybe<IDisplayError> => {
	// Get promo code error
	const promoCodeError = getError(error, EErrorId.PromoCodeInvalid);
	if (promoCodeError) {
		const { id, data } = promoCodeError;
		const message = data?.reason ? mapPromoCodeStatusToErrorMessage(data?.reason) : "Unknown error";
		return {
			message,
			id,
			name: "Invalid Promotion Code"
		};
	} 
	return undefined;
};