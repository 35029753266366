import { EEndpointType } from "amp";
import axios from "src/utils/axios";
import Stripe from "stripe";
import {
	PriceUpdate,
	IProductUpdate,
	ICreateStripeProduct,
	IWashType,
	IProductWithJson,
} from "amp";

export type { IProductUpdate, ICreateStripeProduct, PriceUpdate };

const PORTAL_PATH = "/app/management/plans";
const PORTAL_EDIT_PATH = PORTAL_PATH + "/edit";

const _ENDPOINT_ROOT = `${EEndpointType.User}/products`;

export default {
	PORTAL_PATH,
	PORTAL_EDIT_PATH,

	async getAll() {
		const response = await postRequest<IProductWithJson[]>("get-all-products", {
			joins: [{
				relation: "productWashTypes",
				joins: [{
					relation: "washType",
					selectColumns: ["name"],
				}],
			}],
		});
		return response.data;
	},

	async get(id: string | number) {
		const response = await getRequest<IProductWithJson>(`get-product/${id}`);
		return response.data;
	},

	async getAllWashTypes(): Promise<IWashType[]> {
		const response = await axios.post(`${EEndpointType.User}/wash-types/get-wash-types`,
			{
				select: ["name", "external_id__c", "service__c"],
				relations: ["washTypeCategory"]
			});
		return response.data.washTypes;
	},

	async update(id: string | number, updates: Partial<IProductUpdate>) {
		const response = await postRequest<IProductWithJson[]>("update-products", {
			updates: [{
				id,
				...updates,
			}],
		});
		const [updated] = response.data;

		return updated;
	},

	async create(createRequest: Partial<ICreateStripeProduct>): Promise<Stripe.Product> {
		const response = await postRequest<Stripe.Product>("create-product", createRequest);
		return response.data;
	},

	async batchUpdate(updates: Partial<IProductUpdate>[]) {
		const response = await postRequest<IProductWithJson[]>("update-products", { updates });
		return response.data;
	},
};

function getRequest<T>(endpoint: string) {
	return axios.get<T>(`${_ENDPOINT_ROOT}/${endpoint}`);
}

function postRequest<T>(endpoint: string, body: any) {
	return axios.post<T>(`${_ENDPOINT_ROOT}/${endpoint}`, body);
}
