import { ChangeEvent, useEffect, useState } from "react";
import { Box, Card, CardContent, makeStyles, TextField, Typography } from "@material-ui/core";
import {
	startOfMonth, 
	startOfQuarter,
	startOfToday,
	startOfYear,
	subDays,
	subMonths,
	subQuarters,
	subWeeks,
	subYears,
} from "date-fns";
import { DateRange, formatDateRangeDisplay } from "src/utils/formatDateRange";

type Time =
	"Today" |
	"Yesterday" |
	"Last 7 days" |
	"Last 4 weeks" |
	"Last 3 months" |
	"Last 12 months" |
	"Month to date" |
	"Quarter to date" |
	"Year to date" |
	"All time";

type Comparison =
	"Previous period" |
	"Previous month" |
	"Previous quarter" |
	"Previous year" |
	"No comparison";

interface IProps {
	onTimeChange: (dates: DateRange) => void;
	onComparisonChange: (dates: DateRange) => void;
}

interface Filter<T> {
	name: T;
	calculation: (today: Date) => DateRange;
}

type FilterWithComparisons = Filter<Time> & { comparisons: Filter<Comparison>[] };

const comparisonPrevious = (
	{ name, subPeriod, subRange }: { name: Comparison; subPeriod: (date: Date) => Date; subRange: (date: Date) => Date; },
): Filter<Comparison> => ({
	name,
	calculation: (today) => {
		const periodPrevious = subPeriod(today);
		const rangePrevious = subRange(today);
		return periodPrevious < rangePrevious
			? { start: subRange(periodPrevious), end: subDays(periodPrevious, 1) }
			: { start: subPeriod(rangePrevious), end: subDays(rangePrevious, 1) };
	},
});

const filterLastPeriod = (
	{ name, subPeriod }: { name: Time; subPeriod: (date: Date) => Date; }
): FilterWithComparisons => ({
	name,
	calculation: (today) => ({ start: subPeriod(today), end: subDays(today, 1) }),
	comparisons: [
		comparisonPrevious({
			name: "Previous period",
			subPeriod,
			subRange: subPeriod,
		}),
		comparisonPrevious({
			name: "Previous month",
			subPeriod,
			subRange: (date) => subMonths(date, 1),
		}),
		comparisonPrevious({
			name: "Previous quarter",
			subPeriod,
			subRange: (date) => subQuarters(date, 1),
		}),
		comparisonPrevious({
			name: "Previous year",
			subPeriod,
			subRange: (date) => subYears(date, 1),
		}),
		{
			name: "No comparison",
			calculation: () => "NO_RANGE",
		},
	],
});

const comparisonsProgress = (
	comparisons: {
		name: Comparison;
		startOfPeriod: (date: Date) => Date;
		subPeriod: (date: Date) => Date;
		subRange: (date: Date) => Date;
	}[],
): Filter<Comparison>[] => comparisons.reduce(
	(all, { name, startOfPeriod, subPeriod, subRange }) => {
		const now = new Date();
		if (subPeriod(now).valueOf() >= subRange(now).valueOf()) {
			all.push({
				name,
				calculation: (today) => {
					const rangePrevious = subRange(today);
					return { start: startOfPeriod(rangePrevious), end: subDays(rangePrevious, 1) };
				},
			});
		}
		return all;
	},
	[] as Filter<Comparison>[], 
);

const filterToDate = (
	{ name, startOfPeriod, subPeriod }: {
		name: Time;
		startOfPeriod: (date: Date) => Date;
		subPeriod: (date: Date) => Date;
	},
): FilterWithComparisons => ({
	name,
	calculation: (today) => ({ start: startOfPeriod(today), end: subDays(today, 1) }),
	comparisons: [
		...comparisonsProgress([
			{
				name: "Previous month",
				startOfPeriod,
				subPeriod,
				subRange: (date) => subMonths(date, 1),
			},
			{
				name: "Previous quarter",
				startOfPeriod,
				subPeriod,
				subRange: (date) => subQuarters(date, 1),
			},
			{
				name: "Previous year",
				startOfPeriod,
				subPeriod,
				subRange: (date) => subYears(date, 1),
			},
		]),
		{
			name: "No comparison",
			calculation: () => "NO_RANGE",
		},
	],
});

const filters: FilterWithComparisons[] = [
	{
		name: "Today",
		calculation: (today) => ({ start: today, end: today }),
		comparisons: [
			{ 
				name: "Previous period",
				calculation: (today) => {
					const yesterday = subDays(today, 1);
					return { start: yesterday, end: yesterday };
				},
			},
			{
				name: "Previous month",
				calculation: (today) => {
					const lastMonth = subMonths(today, 1);
					return { start: lastMonth, end: lastMonth };
				},
			},
			{
				name: "Previous quarter",
				calculation: (today) => {
					const lastQuarter = subQuarters(today, 1);
					return { start: lastQuarter, end: lastQuarter };
				},
			},
			{
				name: "Previous year",
				calculation: (today) => {
					const lastYear = subYears(today, 1);
					return { start: lastYear, end: lastYear };
				},
			},
			{
				name: "No comparison",
				calculation: () => "NO_RANGE",
			},
		],
	},
	filterLastPeriod({
		name: "Yesterday",
		subPeriod: (date) => subDays(date, 1),
	}),
	filterLastPeriod({
		name: "Last 7 days",
		subPeriod: (date) => subWeeks(date, 1),
	}),
	filterLastPeriod({
		name: "Last 4 weeks",
		subPeriod: (date) => subMonths(date, 1),
	}),
	filterLastPeriod({
		name: "Last 3 months",
		subPeriod: (date) => subQuarters(date, 1),
	}),
	filterLastPeriod({
		name: "Last 12 months",
		subPeriod: (date) => subYears(date, 1),
	}),
	filterToDate({
		name: "Month to date",
		startOfPeriod: startOfMonth,
		subPeriod: (date) => subMonths(date, 1),
	}),
	filterToDate({
		name: "Quarter to date",
		startOfPeriod: startOfQuarter,
		subPeriod: (date) => subQuarters(date, 1),
	}),
	filterToDate({
		name: "Year to date",
		startOfPeriod: startOfYear,
		subPeriod: (date) => subYears(date, 1),
	}),
	{
		name: "All time",
		calculation: () => "ALL_TIME",
		comparisons: [],
	},
];

function getDisplay(
	selectedTime: Time,
	selectedComparison: Comparison,
	range: DateRange
): string {
	const showYear =
		selectedTime === "Last 12 months" ||
		selectedTime === "Year to date" ||
		selectedComparison === "Previous year";
return formatDateRangeDisplay(range, { showYear });
}

export function TimeComparisonFilter({ onTimeChange, onComparisonChange }: IProps) {
	const classes = useStyles();
	const [selectedTime, setSelectedTime] = useState<Time>(filters[2].name);
	const [selectedTimeRange, setSelectedTimeRange] = useState<DateRange>("ALL_TIME");
	const [selectedComparison, setSelectedComparison] = useState<Comparison>(filters[0].comparisons[0].name);
	const [selectedComparisonRange, setSelectedComparisonRange] = useState<DateRange>("NO_RANGE");

	function handleChange<T extends string>(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		setState: (value: T) => void,
	) {
		event.persist();
		const selectedValue = event.target.value;
		setState(selectedValue as T);
	}

	function updateTime(time: Time): void {
		const today = startOfToday();
		const range = filters.find(({ name }) => name === time)?.calculation(today);
		if (range) {
			setSelectedTimeRange(range);
			onTimeChange(range);
		}
	}

	function updateComparison(comparison: Comparison): void {
		const today = startOfToday();
		const availableComparisons = filters
			.find(({ name: timeName }) => timeName === selectedTime)
			?.comparisons;
		const fallbackRange = availableComparisons?.[0]?.calculation(today) ?? "NO_RANGE";
		const range = availableComparisons
			?.find(({ name: periodName }) => periodName === comparison)
			?.calculation(today) ?? fallbackRange;
		setSelectedComparisonRange(range);
		onComparisonChange(range);
	}

	useEffect(() => {
		updateTime(selectedTime);
	}, [selectedTime]);

	useEffect(() => {
		updateComparison(selectedComparison);
	}, [selectedTimeRange, selectedComparison]);

	return (
		<Card>
			<CardContent>
				<Box className={classes.container}>
					<TextField
						className={classes.dropdown}
						label="Time"
						onChange={(event) => handleChange<Time>(event, setSelectedTime)}
						select
						SelectProps={{ native: true }}
						value={selectedTime}
						variant="outlined"
					>
						{filters.map(({ name }) => (
							<option
								key={name}
								value={name}
							>
								{name}
							</option>
						))}
					</TextField>
					{selectedTime === "All time"
						? null
						: (
<>
							<TextField
								className={classes.dropdown}
								label="Comparison"
								onChange={(event) => handleChange<Comparison>(event, setSelectedComparison)}
								select
								SelectProps={{ native: true }}
								value={selectedComparison}
								variant="outlined"
							>
								{filters.find((time) => time.name === selectedTime)?.comparisons.map(({ name }) => (
									<option
										key={name}
										value={name}
									>
										{name}
									</option>
								))}
							</TextField>
							<Typography>
								<span className={classes.bold}>
									{getDisplay(selectedTime, selectedComparison, selectedTimeRange)}
								</span>
								&nbsp;compared to&nbsp;
								<span className={classes.bold}>
									{getDisplay(selectedTime, selectedComparison, selectedComparisonRange)}
								</span>
							</Typography>
						</>
)
					}
				</Box>
			</CardContent>
		</Card>
	);
}

const useStyles = makeStyles(() => ({
	container: {
		display: "flex",
		alignItems: "center",
	},
	dropdown: {
		marginRight: 16,
	},
	bold: {
		fontWeight: 700,
	}
}));
