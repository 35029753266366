import { useEffect } from "react";
import { EEndpointType } from "amp";
import axios from "src/utils/axios";
import { useStateWithMounted } from "src/utils/useStateWithMounted";
import { EAccountType, IProduct, IProductWithJson } from "amp";

export { EAccountType };

export enum EFleetStrategy {
	Membership = "Membership",
	PayPerWash = "PayPerWash",
}

export enum EPriceJoinType {
	None = "None",
	ParentOnly = "ParentOnly",
	All = "All",
}

export interface IUseFetchProductsProps {
	accountType?: EAccountType;
	activeOnly?: boolean;
	handleInitialLoad?: boolean;
	selectedProducts?: IProduct[];
	priceJoinType: EPriceJoinType;
}

export const useFetchProducts = ({
	accountType,
	activeOnly,
	handleInitialLoad = false,
	selectedProducts,
	priceJoinType,
}: IUseFetchProductsProps) => {
	const [products, setProducts] = useStateWithMounted<IProductWithJson[] | null>(null);
	const [activeProducts, setActiveProducts] = useStateWithMounted<IProductWithJson[] | null>(null);
	const [loadingProducts, setLoadingProducts] = useStateWithMounted(handleInitialLoad);
	async function fetchProducts(): Promise<void> {
		if (!loadingProducts) {
			setLoadingProducts(true);
		}
		let whereWithOperators = {};
		if (accountType) {
			whereWithOperators = {
				metadata: {
					Filter: {
						Type: accountType
					},
					Alias: "prices",
				},
			};
		}
		if (activeOnly) {
			whereWithOperators = {
				...whereWithOperators,
				active: true,
			};
		}
		const { data: _products } = await axios.post<IProductWithJson[]>(
			`${EEndpointType.User}/products/get-all-products`,
			{ 
				..._getPriceJoinAndOrderBy(priceJoinType),
				whereWithOperators,
			}
		);
		setProducts(_products);
		setLoadingProducts(false);
	}

	const _getPriceJoinAndOrderBy = (joinType: EPriceJoinType) => {
		const priceOrderBy = {
			"product.ordinal": "ASC",
			"prices.ordinal": "ASC",
		};
		const noPriceOrderBy = { "product.ordinal": "ASC" };
		switch (joinType) {
			case EPriceJoinType.All: 
				return { joins: [{ relation: "prices" }], orderBy: priceOrderBy };
			case EPriceJoinType.ParentOnly:
					return { 
						joins: [{ relation: "prices", condition: "parent_product_price_id is null", }],
						orderBy: priceOrderBy 
					};
			case EPriceJoinType.None:
			default:
				return { orderBy: noPriceOrderBy };
		}
	};
	useEffect(() => {
		if (products || handleInitialLoad) {
			fetchProducts();
		}
	}, [accountType]);

	useEffect(() => {
		if (!products) {
			return;
		}
		const selectedProductIds = selectedProducts?.map((item) => item.id) || [];
		// Filter products for active or product that matches filter products
		const _products = products?.filter((option) => {
			return option.active || selectedProductIds.includes(option.id);
		});
		setActiveProducts(_products);
	}, [products, selectedProducts]);

	return {
		fetchProducts,
		products,
		activeProducts,
		loadingProducts,
	};
};
