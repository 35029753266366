import React, { FC } from "react";

/**
 * Lets you nest components in a clean way
 * and allows for children pass through
 *
 * @example
 *
 * // This -->
 * const AppProvider = nestComponents(One, Two, Three)
 *
 * // Instead of this -->
 * const AppProvider = ({children}) => (
 * 	<One>
 * 		<Two>
 * 			<Three>{children}</Three>
 * 		</Two>
 * 	</One>
 * )
 */
export const combineComponents = (...components: FC[]): FC =>
	components.reduce(
		(Accumulated, Component): FC => ({ children }) => (
			<Accumulated>
				<Component>{children}</Component>
			</Accumulated>
		),
		({ children }) => <>{children}</>,
	);
