import { MouseEvent, useContext, useEffect, useState } from "react";
import { Menu, MenuItem, MenuProps } from "@material-ui/core";

import { MenuTrigger } from "src/components/MenuTrigger";
import { TenantContext } from "src/contexts/TenantContext";
import useAuth from "src/hooks/useAuth";
import { XTenantKeyAndLogo } from "amp";

export const TenantDropdown = () => {
	const {
		handleImpersonateTenantKeyChange,
		ampTenants,
		ampTenantImpersonateKey,
		ampTenantKey,
		fetchAmpTenants,
	} = useContext(TenantContext);

	const { isAuthorizedAppRoutes } = useAuth();
	const [tenantName, setTenantName] = useState<string | undefined>(undefined);
	const [menuProps, setMenuProps] = useState<MenuProps>({ open: false });

	useEffect(() => {
		if (ampTenants?.length) {
			const initialTenantName = ampTenants.find((tenant) => 
				tenant.tenant_key === (ampTenantImpersonateKey ?? ampTenantKey))?.car_wash_name ?? "Autowash";
			setTenantName(initialTenantName);
		}
	}, [ampTenants]);

	useEffect(() => {
		if (isAuthorizedAppRoutes) {
			fetchAmpTenants();
		}
	}, [isAuthorizedAppRoutes]);

	const selectTenant = (event: MouseEvent, tenant: XTenantKeyAndLogo) => {
		setTenantName(tenant.car_wash_name);
		handleImpersonateTenantKeyChange(tenant.tenant_key);
		menuProps.onClose?.(event, "backdropClick");
	};

	if (!ampTenants?.length || ampTenants.length < 2) {
		return null;
	}

	return (
		<>
			<MenuTrigger
				color="inverted"
				style={{ textTransform: "none" }}
				size="large"
				disableElevation
				onToggle={setMenuProps}
			>
				{tenantName}
			</MenuTrigger>

			<Menu {...menuProps}>
				{ampTenants.map((tenant, index) => (
					<MenuItem
						key={`${tenant.tenant_key}${String(index)}`}
						onClick={(event) => selectTenant(event, tenant)}
					>
						{tenant.car_wash_name}
					</MenuItem>
				))}
			</Menu>
		</>
		);
};
