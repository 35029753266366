import { IKpiTileProps } from "src/components/charts";
import { IAggregateUserTotal } from "amp";

export type ReportKpiTileName =
	"Total Users" |
	"Active Locations" |
	"Active Subscriptions";

export const ReportKpiTileService = {
	mapToTotalUsersKpiTile,
	mapToActiveLocationsKpiTile,
	mapToActiveSubscriptionKpiTile,
};

function mapToTotalUsersKpiTile(
	usersData: [IAggregateUserTotal[], IAggregateUserTotal[]],
): IKpiTileProps | null {
	return _mapToKpiTile<IAggregateUserTotal>({
		apiData: usersData,
		getDatumTotalCount: (datum) => datum.users_total,
		tileLabel: "Total Users",
	});
}

function mapToActiveLocationsKpiTile(
	data: number,
): IKpiTileProps | null {
	return _mapToKpiTile<number>({
		apiData: data,
		getDatumTotalCount: (datum) => datum,
		tileLabel: "Active Locations",
	});
}

function mapToActiveSubscriptionKpiTile(data: number ): IKpiTileProps | null {
	return _mapToKpiTile<number>({
		apiData: data,
		getDatumTotalCount: (datum) => datum,
		tileLabel: "Active Subscriptions",
	});
}

function _mapToKpiTile<T>(details: {
	apiData: [T[], T[]] | number | null,
	tileLabel: ReportKpiTileName,
	getDatumTotalCount?: (datum: T) => number,
	isMonetary?: boolean,
}): IKpiTileProps | null {
	const { apiData, tileLabel, getDatumTotalCount, isMonetary } = details;
	if (apiData === null || apiData === undefined) {
		return null;
	}
	let sum: number;
	if (Array.isArray(apiData)) {
		if (!getDatumTotalCount) {
			throw new Error("Insufficient argument `getDatumTotalCount`");
		}
		sum = apiData[0].reduce((accum, current) => accum + getDatumTotalCount(current), 0);
	} else {
		sum = apiData;
	}
	return {
		value: sum,
		label: tileLabel,
		isMonetary,
	};
}