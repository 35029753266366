import { FleetProvider } from "../fleet-accounts/contexts/FleetContext";
import { useContext } from "react";
import { FleetAccountsContext } from "src/contexts/FleetAccountsContext";

export const FleetAdminPortal = ({ children }) => {
	const { 
		fleetAccountId: accountId, 
	} = useContext(FleetAccountsContext);

	return (
		<FleetProvider fleetAccountId={accountId}>
			{children}
		</FleetProvider>
	);
};