import { useCallback } from "react";
import { useNavigate } from "react-router";
import { AccountKeysArray, EEndpointType, IAccountContext, IAddJoin, IAutowashAccount, ICancelSubscription, ICreateFleetAccountRequest, ICreateFleetAccountResponse, IGetFleetAccountResponse, IMobileUserContact, IUpdateFleetAccountRequest, IUpdateFleetAccountResponse } from "amp";
import axios from "src/utils/axios";
import { AccountService } from "../user-management/account-service";

export interface IUpdateFleetAccountWashTypes {
	accountWashTypes: string[];
	accountId: string;
}

const PORTAL_URL = "/app/management/users-and-accounts/fleet-accounts";
const PORTAL_URL_CREATE = PORTAL_URL + "/create";
const PORTAL_URL_EDIT = PORTAL_URL + "/edit/:fleetAccountId";
const getPortalUrlEdit = (id?: number | string) => id
	? PORTAL_URL_EDIT.replace(":fleetAccountId", id.toString())
	: PORTAL_URL_EDIT;

const API_ACCOUNT_URL = `${EEndpointType.User}/account/`;
const API_UPDATE_USER = `${EEndpointType.Public}/user/update-user`;
const API_ACCOUNTS_URL = `${EEndpointType.User}/accounts`;
const API_FLEET_URL = `${EEndpointType.User}/fleet`;
const API_CREATE_ACCOUNT = `${API_FLEET_URL}/create-fleet-account`;
const API_UPDATE_ACCOUNT = `${API_FLEET_URL}/update-fleet-account`;
const API_ACTIVATE_SUBSCRIPTION = `${API_FLEET_URL}/activate-subscription`;
const API_CANCEL_SUBSCRIPTION = `${API_ACCOUNTS_URL}/cancel-subscriptions`;
const API_GET_BY_ID = `${API_FLEET_URL}/get-fleet-account`;
const API_UPDATE_ACCOUNT_WASH_TYPES = `${EEndpointType.User}/accounts/update-account-wash-types`;

const post = <T extends {}, Y>(endpoint: string, body: T): Promise<Y> =>
	axios.post<Y>(API_ACCOUNT_URL + endpoint, body).then(x => x.data);
	
const fleetAccountJoins: IAddJoin[] = [
	{
		relation: "primaryAccountHolder",
		type: "Inner",
		selectColumns: ["external_id__c"],
		joins: [{
			relation: "contact",
			type: "Inner",
			selectColumns: ["email", "name"],
		}]
	},
];
const getAll = () =>
	post<
	{
		select: AccountKeysArray,
		joins: IAddJoin[]
	}, IAutowashAccount[]>("get-fleet-accounts",
	{
		select: ["id", "external_id__c", "name", "stripe_subscription_status__c", "account_approach__c"],
		joins: fleetAccountJoins,
	}
);

const getFleetAccountData = async (accountId: string): Promise<IGetFleetAccountResponse> => {
	const { data } = await axios.post<IGetFleetAccountResponse>(API_GET_BY_ID, { accountId });
	return data;
};

async function create(request: ICreateFleetAccountRequest): Promise<string> {
	const { data } = await axios.post<ICreateFleetAccountResponse>(
		API_CREATE_ACCOUNT, request);
	return data.account.external_id__c;
}

async function update(request: IUpdateFleetAccountRequest): Promise<IUpdateFleetAccountResponse> {
	const { data } = await axios.post<IUpdateFleetAccountResponse>(API_UPDATE_ACCOUNT, request);
	return data;
}

async function activateSubscription(accountId: string): Promise<IUpdateFleetAccountResponse> {
	const { data } = await axios.post<IUpdateFleetAccountResponse>(API_ACTIVATE_SUBSCRIPTION, { accountId });
	return data;
}

async function reactivateVehicleSingleSubscription(accountId: string): Promise<IUpdateFleetAccountResponse> {
	const accountContext = await AccountService.reactivateVehicleSingleSubscription(accountId);
	return {
		accountContext,
		subscriptionStagingConfig: undefined
	};
}

async function reactivateUserSubscription(accountId: string): Promise<IUpdateFleetAccountResponse> {
	const { data } = await AccountService.reactivateUserSubscription(accountId);
	return {
		accountContext: data as IAccountContext,
		subscriptionStagingConfig: undefined
	};
}

async function cancelSubscription(
	accountId: string,
	cancelSubscription: ICancelSubscription
): Promise<IUpdateFleetAccountResponse> {
	const { data } = await axios.post<IAccountContext>(API_CANCEL_SUBSCRIPTION, {
		accountId,
		modification: cancelSubscription,
	});
	return {
		accountContext: data,
		subscriptionStagingConfig: undefined
	};
}

async function updateAccountWashTypes(
	update: IUpdateFleetAccountWashTypes
): Promise<void> {
	await axios.post<IAccountContext>(API_UPDATE_ACCOUNT_WASH_TYPES, update);
}

async function updateUser(user: Partial<IMobileUserContact>, userExtId: string): Promise<IMobileUserContact> {
	return (await axios.post<IMobileUserContact>(API_UPDATE_USER, {
		...user,
		external_id__c: userExtId,
	})).data;
}

const ITEM_NAME = "Fleet Account";
const ITEM_NAME_PLURAL = "Fleet Accounts";
const PAGE_NAME = "Fleet Accounts";

const useGoTo = () => {
	const navigate = useNavigate();
	const goToCreate = useCallback(() => navigate(FleetAccountsService.PORTAL_URL_CREATE), []);
	const goToList = useCallback(() => navigate(FleetAccountsService.PORTAL_URL), []);
	return { goToCreate, goToList };
};

export const FleetAccountsService = {
	ITEM_NAME,
	ITEM_NAME_PLURAL,
	PAGE_NAME,

	PORTAL_URL,
	PORTAL_URL_CREATE,
	getPortalUrlEdit,
	getAll,
	getFleetAccountData,
	create,
	update,
	activateSubscription,
	cancelSubscription,
	reactivateVehicleSingleSubscription,
	reactivateUserSubscription,
	updateUser,
	updateAccountWashTypes,
	useGoTo,
};