import { EFormType } from "src/enums/form-type.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { EPageType } from "src/enums/page-type.enum";

const GAMES_BASE_ROUTE = `/${ENavBaseRoute.management}/games`

const ARCADE_BASE_ROUTE = `${GAMES_BASE_ROUTE}/arcade`;
const ARCADE_LIST_ROUTE = ARCADE_BASE_ROUTE;
const ARCADE_CREATE_ROUTE = `${ARCADE_BASE_ROUTE}/${EFormType.create}`;
const ARCADE_EDIT_ROUTE = `${ARCADE_BASE_ROUTE}/${EFormType.edit}/:id`;

const SPINNER_BASE_ROUTE = `${GAMES_BASE_ROUTE}/spinner`;
const SPINNER_LIST_ROUTE = SPINNER_BASE_ROUTE;
const SPINNER_FORM_ROUTE = `${SPINNER_BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const SPINNER_CREATE_ROUTE = `${SPINNER_BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
const SPINNER_EDIT_ROUTE = `${SPINNER_BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const SPINNER_COPY_ROUTE = `${SPINNER_BASE_ROUTE}/${EPageType.form}/${EFormType.copy}/:id`;

export const GamesRoutes = {
	ARCADE_LIST_ROUTE,
	ARCADE_CREATE_ROUTE,
	ARCADE_EDIT_ROUTE,

	SPINNER_FORM_ROUTE,
	SPINNER_LIST_ROUTE,
	SPINNER_CREATE_ROUTE,
	SPINNER_EDIT_ROUTE,
	SPINNER_COPY_ROUTE,
};
