import axios from "src/utils/axios";
import { AxiosResponse } from "axios";
import { IDynamicQueryWithRefresh } from "src/components/dynamic-table/DynamicTablePage";
import { EPageType } from "src/enums/page-type.enum";
import { EFormType } from "src/enums/form-type.enum";
import { XDynamicSqlName } from "amp";
import { EEndpointType } from "amp";
import { BrandImageRoutes } from "./brand-images.routes";
import { IBrandImageCategory } from "amp";
import { EditFormCreate, EditFormUpdate } from "src/interfaces/props/edit-form.props";

const DYNAMIC_ENDPOINT_TABLE_NAME: XDynamicSqlName =
	"brandImageCategory";
const API_BASE_URL = `${EEndpointType.Admin}/branding`;

const { BRAND_IMAGES_BASE_ROUTE } = BrandImageRoutes;

const BRAND_IMAGE_CATEGORIES_LIST_ROUTE = `${BRAND_IMAGES_BASE_ROUTE}/${EPageType.list}?tab=BRAND_IMAGE_CATEGORY`;
const BRAND_IMAGE_CATEGORIES_CREATE_ROUTE = `${BRAND_IMAGES_BASE_ROUTE}/categories/${EFormType.create}`;
const BRAND_IMAGE_CATEGORIES_EDIT_ROUTE = `${BRAND_IMAGES_BASE_ROUTE}/categories/${EFormType.edit}/:id`;

const ITEM_NAME = "Category";
const ITEM_NAME_PLURAL = "Categories";

const BASE_QUERY: IDynamicQueryWithRefresh = {
	refresh: Number.MIN_SAFE_INTEGER,
	columns: [
		"id",
		"name",
		"description",
	],
	orderBys: [{ column: "name", direction: "ASC" }],
};

const create = async (
	brandImageCategory: EditFormCreate<IBrandImageCategory>,
): Promise<IBrandImageCategory> => {
	const response = await axios.post<IBrandImageCategory>(
		`${API_BASE_URL}/create-brand-image-category`,
		{ brandImageCategory },
	);
	return response.data;
};

const update = (
	brandImageCategory: EditFormUpdate<IBrandImageCategory>,
): Promise<AxiosResponse<IBrandImageCategory[]>> => {
	return axios.post<IBrandImageCategory[]>(
		`${API_BASE_URL}/update-brand-image-categories`,
		{ brandImageCategories: [brandImageCategory] },
	);
};

export const BrandImageCategoriesService = {
	DYNAMIC_ENDPOINT_TABLE_NAME,
	API_BASE_URL,
	BASE_QUERY,
	create,
	update,
	BRAND_IMAGE_CATEGORIES_LIST_ROUTE,
	BRAND_IMAGE_CATEGORIES_CREATE_ROUTE,
	BRAND_IMAGE_CATEGORIES_EDIT_ROUTE,
	ITEM_NAME,
	ITEM_NAME_PLURAL,
};
