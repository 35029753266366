import { makeStyles, Theme, Typography } from "@material-ui/core";
import { useCallback } from "react";
import { TooltipProps } from "recharts";

import { transparentize } from "amp";
import { formatDefault } from "./formatters";
import { Formatter } from "recharts/types/component/DefaultTooltipContent";

export type ChartTooltipProps = TooltipProps<string | number, string | number> & {
	opacity: number;
};

export type XChartTooltipFormatter = Formatter<string | number, string | number>;

export function ChartTooltip(props: ChartTooltipProps) {
	const {
		formatter,
		payload,
		label,
	} = props;
	const format: XChartTooltipFormatter = useCallback(
		formatter ?? ((value: number) => formatDefault(value)),
		[formatter],
	);

	const classes = useStyles(props);

	const bullet = (color?: string) => ({
		width: 6,
		height: 6,
		borderRadius: 3,
		marginRight: 8,
		background: color,
	});

	return (
		<div className={classes.container}>
			<Typography className={classes.heading}
				variant="h6"
			>
				{label}
			</Typography>
			<ul className={classes.list}>
				{payload?.map(({ color, dataKey, payload: data }, index) => (
					<li className={classes.item} key={dataKey}>
						<span className={classes.itemLabel}>
							{dataKey}
						</span>
						<span className={classes.itemValue}>
							<div style={bullet(color)} />
							{format(data[dataKey], dataKey, data, index, payload)}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
}

const useStyles = makeStyles<Theme, ChartTooltipProps>(({
	palette,
	shadows,
	shape,
	spacing,
	typography,
}) => ({
	container: ({ opacity }) => ({
		background: transparentize(palette.background.default, opacity),
		backdropFilter: `blur(8px)`,
		border: `1px solid ${palette.divider}`,
		boxShadow: shadows[8],
		borderRadius: shape.borderRadius,
		padding: spacing(1.25, 2),
		width: 500,
	}),
	heading: {
		marginBottom: spacing(1.25),
		wordWrap: `break-word`,
		overflowWrap: `break-word`,
		hyphens: `auto`,
	},
	list: {
		display: `flex`,
		flexDirection: `column`,
		gap: spacing(0.75),
	},
	item: {
		display: `block`,
		position: `relative`,
	},
	itemLabel: {
		...typography.overline,
		display: `block`,
		color: palette.text.secondary,
		fontSize: 11,
		lineHeight: 1,
	},
	itemValue: {
		...typography.h5,
		display: `flex`,
		alignItems: `center`,
	},
}));
