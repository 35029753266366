import { createContext, FC, ReactNode } from "react";
import { EEndpointType } from "amp";
import axios from 'src/utils/axios';
import {
  IImageFileNamesResponse,
  IImageFileCreateResponse,
} from "amp";
import environment from "../environment";

interface IAWSContext {
  listFileNames: () => Promise<string[] | undefined>;
  getFileUrl: (fileName: string) => string;
  createFile: (file: File) => Promise<string | undefined>;
  deleteFile: (fileName: string,) => Promise<void>;
}

interface IAWSProviderProps {
  children: ReactNode
}

export const AWSContext = createContext<IAWSContext | null>(null);

export const AWSProvider: FC<IAWSProviderProps> = ({ children }) => {
  return (
    <AWSContext.Provider value={{
      listFileNames: async () => {
        try {
          const imageFileNames = await axios.get<IImageFileNamesResponse>(`${EEndpointType.User}/aws-s3/get-image-file-names`);
          return imageFileNames.data.imageFileNames;
        } catch (err) {
          console.error(err);
        }
      },
      getFileUrl: (fileName: string) => {
        const awsUrl = environment.aws.url;
        const imageUrl = `${awsUrl}/${fileName}`;
        return imageUrl;
      },
      createFile: async (file: File) => {
        try {
          const bodyFormData = new FormData();
          bodyFormData.append("file", file, file.name);
          const response = await axios.post<IImageFileCreateResponse>(`${EEndpointType.User}/aws-s3/create-file`, bodyFormData);
          return response.data.fileName;
        } catch (err) {
          console.error(err);
        }
      },
      deleteFile: async (fileName: string) => {
        const confirmation = window.confirm(`Are you sure you want to delete ${fileName}?`);
        if (!confirmation) { return; }
        try {
          await axios.delete(`${EEndpointType.User}/aws-s3/delete-image-file/${fileName}`);
        } catch (err) {
          console.error(err);
        }
      }
    }}>
      {children}
    </AWSContext.Provider>
  );
};
