import React from "react";
import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
	const { loggedIn } = useAuth();
	const { search } = useLocation();

	if (!loggedIn) {
		return <Navigate to={{ pathname: "/login", search }} />;
	}

	return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
