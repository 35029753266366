import { createContext, useCallback, useContext, useEffect, useMemo, ReactNode } from "react";
import LoadingScreen from "src/components/LoadingScreen";
import useAuth from "src/hooks/useAuth";
import { useFetchData } from "src/hooks/useFetchData";
import GeneralService from "src/views/general/general.service";
import { EAuthPermission } from "./AuthContext";
import { TenantContext } from "./TenantContext";
import { IGeneralInformation, XSalesforceMultiTenantWritableWithId } from "amp";

export interface IGeneralInformationContext {
	generalInformation: IGeneralInformation | null;
	loading: boolean;
	refreshGeneralInformation: () => Promise<boolean>;
	updateGeneralInformation: (
		updatedGeneralInformation: XSalesforceMultiTenantWritableWithId<IGeneralInformation>
	) => Promise<void>;
}

export const GeneralInformationContext = createContext<IGeneralInformationContext>({
	generalInformation: null,
	loading: false,
	refreshGeneralInformation: () => Promise.resolve(false),
	updateGeneralInformation: () => Promise.resolve(undefined),
});

interface ITenantProviderProps {
	children: ReactNode;
}

export const GeneralInformationProvider = ({ children }: ITenantProviderProps) => {
	const { userPermission, loggedIn } = useAuth();
	const { ampTenantKey, ampTenantImpersonateKey } = useContext(TenantContext);
	const generalInformationCallback = useMemo(() => {
		return userPermission === EAuthPermission.Admin
			? GeneralService.getGeneralInformationForAdmin
			: GeneralService.getGeneralInformationForAnonymous;
	}, [userPermission]);
	const {
		retry: refreshGeneralInformation,
		loading,
		data: generalInformation,
		setData: setGeneralInformation,
	} = useFetchData(() => generalInformationCallback(), { handleInitialDataLoad: false });

	const updateGeneralInformation = useCallback(async (
		updatedGeneralInformation: XSalesforceMultiTenantWritableWithId<IGeneralInformation>
	) => {
		const updateResult = await GeneralService.handleUpdateGeneralInformation(updatedGeneralInformation);
		setGeneralInformation(updateResult);
	}, []);

	useEffect(() => {
		if ((ampTenantImpersonateKey || ampTenantKey) && loggedIn) {
			refreshGeneralInformation();
		}
	}, [ampTenantKey, ampTenantImpersonateKey, loggedIn, userPermission]);

	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<GeneralInformationContext.Provider
			value={{
				generalInformation,
				loading,
				refreshGeneralInformation,
				updateGeneralInformation,
			}}
		>
			{children}
		</GeneralInformationContext.Provider>
	);
}
