import { useCallback, useContext } from "react";
import { ErrorContext } from "src/contexts/ErrorContext";

const useErrorBoundary = () => {
	const { addError } = useContext(ErrorContext);
	const tryAction = useCallback(
		async <TValue>(
			action: () => Promise<TValue>,
			catchCallback: (error: Error) => void = () => null,
			successCallback?: () => void,
		): Promise<TValue | undefined> => {
			try {
				const result = await action();
				if (successCallback) {
					successCallback();
				}
				return result;
			} catch (err) {
				addError(err);
				catchCallback(err?.response?.data?.error);
			}
		},
		[addError],
	);
	return tryAction;
};

export default useErrorBoundary;
