import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
	Breadcrumbs,
	Button,
	Grid,
	Link,
	SvgIcon,
	Typography,
	makeStyles,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
	RefreshCcw as RefreshIcon,
	PlusCircle as PlusCircleIcon,
} from "react-feather";
import { Theme } from "src/theme";

export interface Breadcrumb {
	label: string;
	url: string;
}

export type PortalEntryPath = "app" | "fleet" | "site-lead";

export interface HeaderProps {
	className?: string;
	create?: () => void;
	refresh?: () => Promise<unknown> | void;
	refreshDisabled?: boolean;
	disableCreate?: boolean;
	pageName: string;
	itemName?: string;
	itemNamePlural?: string;
	breadcrumbs?: Breadcrumb[];
	portalEntryPath?: PortalEntryPath;
	customButtons?: JSX.Element[];
	createButtonLabel?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	action: {
		marginBottom: theme.spacing(1),
		"& + &": {
			marginLeft: theme.spacing(1),
		},
	},
}));

const Header: FC<HeaderProps> = ({
	className,
	create,
	refresh,
	refreshDisabled,
	disableCreate = false,
	pageName,
	itemName = "item",
	itemNamePlural: _itemNamePlural = "items",
	breadcrumbs,
	portalEntryPath = "app",
	customButtons,
	createButtonLabel,
	...rest
}) => {
	const classes = useStyles();

	return (
		<Grid
			className={clsx(classes.root, className)}
			container
			justifyContent="space-between"
			spacing={3}
			{...rest}
		>
			<Grid item md={6} xs={12}>
				{breadcrumbs && (
					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
					>
						<Link
							variant="body1"
							color="inherit"
							to={`/${portalEntryPath}`}
							component={RouterLink}
						>
							Portal
						</Link>
						{breadcrumbs.map((breadcrumb, index) =>
							index !== breadcrumbs.length - 1 ? (
								<Link
									key={index}
									variant="body1"
									color="inherit"
									to={breadcrumb.url}
									component={RouterLink}
								>
									{breadcrumb.label}
								</Link>
							) : (
								<Typography key={index} variant="body1" color="textPrimary">
									{breadcrumb.label}
								</Typography>
							),
						)}
					</Breadcrumbs>
				)}
				<Typography variant="h3" color="textPrimary">
					{pageName}
				</Typography>
			</Grid>
			<Grid container justifyContent="flex-end" spacing={2} item md={6} xs={12}>
				{refresh && (
					<Grid item>
						<Button
							color="primary"
							variant="contained"
							startIcon={
								<SvgIcon fontSize="small">
									<RefreshIcon />
								</SvgIcon>
							}
							onClick={() => refresh()}
							disabled={refreshDisabled}
						>
							Refresh
						</Button>
					</Grid>
				)}
				{create && (
					<Grid item>
						<Button
							disabled={disableCreate}
							color="secondary"
							variant="contained"
							startIcon={
								<SvgIcon fontSize="small">
									<PlusCircleIcon />
								</SvgIcon>
							}
							onClick={() => create()}
						>
							{createButtonLabel || `New ${itemName}`}
						</Button>
					</Grid>
				)}
				<Grid item>{customButtons}</Grid>
			</Grid>
		</Grid>
	);
};

Header.propTypes = {
	className: PropTypes.string,
	create: PropTypes.func,
};

export default Header;
