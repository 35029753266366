import { v4 as uuidv4 } from 'uuid';
import React, { Component, ReactNode } from 'react';
import { IDisplayError } from 'src/interfaces/display-error';
import ErrorModal from './ErrorModal';

class ErrorBoundary extends Component<
  { children?: ReactNode },
  { error: IDisplayError | null }
> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error: { ...error, id: uuidv4() } };
  }

  componentDidCatch(error: Error, errorInfo) {
    // We can also log the error to an error reporting service here
    console.error(error, errorInfo);
  }

  render() {
    // Uncaught error
    if (this.state.error) {
      return (
        <ErrorModal
          error={this.state.error}
          dismissError={() => this.setState({ error: null })}
        />
      );
    }
    return this.props.children ?? null;
  }
}

export default ErrorBoundary;
