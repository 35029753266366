import React from "react";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

import { Theme } from "src/theme";
import { formatCurrency } from "src/utils/formatCurrency";

export interface IKpiTileProps {
	value: number;
	label: string;
	isMonetary?: boolean;
}

export function KpiTile({ value, label, isMonetary }: IKpiTileProps) {
	const classes = useStyles();

	return (
		<Card>
			<CardContent
				className={classes.root}
				style={{paddingBottom: "16px"}}
			>
				<Typography variant="h2">
					{isMonetary
						? formatCurrency(value, { trimCents: true })
						: value.toLocaleString()
					}
				</Typography>
				<Typography variant="h6"
					className={classes.label}
				>
					{label}
				</Typography>
			</CardContent>
		</Card>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
	},
	label: {
		textAlign: "center",
	},
}));