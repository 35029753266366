import { useState, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import {
	LineChart,
	Line,
	Tooltip,
	XAxis,
	ResponsiveContainer,
	Legend,
} from "recharts";

import { Theme } from "src/theme";
import { lighten, transparentize } from "amp";
import { Aspect } from "../Aspect";
import { ChartTile } from "./ChartTile";
import { ChartTooltip, XChartTooltipFormatter } from "./ChartTooltip";
import { ChartLegend, ELegendShape } from "./ChartLegend";
import { formatDefault } from "./formatters";
import { CSSProperties } from "@material-ui/styles";

export enum EHeaderType {
	Sum,
	Last,
}

export interface ILineChartDataSet {
	/** The label for this dataset, for use in tooltips / legend */
	name: string;
	/** The values to be plotted on the graph */
	values: {
		label: string;
		value: number;
	}[];
}

export interface ILineChartProps {
	/** The label printed at the top-left of the chart */
	name: string;
	/** The data to display */
	dataSets: {
		primary: ILineChartDataSet;
		compare?: ILineChartDataSet;
	};
	/** The way to handle data in chart header */
	headerType: EHeaderType;
	/** (optional) A function to format each value */
	format?(value: number): string;
}

export function LineChartTile(props: ILineChartProps) {
	const { name } = props;
	const format = props.format ?? formatDefault;

	const [fullScreen, setFullScreen] = useState(false);

	return (
		<ChartTile
			title={name}
			headerSlot={<DiffPill {...props} />}
			fullScreen={fullScreen}
			onFullScreenChange={setFullScreen}
		>
			{fullScreen
				? <FullScreenChart {...props} format={format} />
				: <ResponsiveChart {...props} format={format} />
			}
		</ChartTile>
	);
}

function FullScreenChart(props: ILineChartProps) {
	return (
		<>
			<ChartHeading {...props} />
			<ResponsiveContainer
				width={window.innerWidth - 32}
				height={window.innerHeight - 128 - 36}
			>
				<InnerChart
					width={1}
					height={1}
					{...props}
				/>
			</ResponsiveContainer>
		</>
	);
}

function ResponsiveChart(props: ILineChartProps) {
	const [width, setWidth] = useState<number | undefined>();
	const [height, setHeight] = useState<number | undefined>();

	return (
		<Aspect
			ratio={2 / 1}
			onResize={(width, height) => {
				setWidth(width);
				setHeight(height);
			}}
		>
			<ChartHeading {...props} />
			<InnerChart
				width={width}
				height={height && height - 36}
				{...props}
			/>
		</Aspect>
	);
}

function ChartHeading({ dataSets, format, headerType }: ILineChartProps) {
	const classes = useChartHeadingStyles();

	const primaryValues = dataSets.primary.values;
	const compareValues = dataSets.compare?.values;

	const primarySum = format?.(getSummary(headerType, primaryValues));
	const compareSum = compareValues && format?.(getSummary(headerType, compareValues));

	return (
		<div className={classes.root}>
			{primarySum &&
				<h3>{primarySum}</h3>
			}
			{compareSum &&
				<h4>{compareSum}</h4>
			}
		</div>
	);
}

const useChartHeadingStyles = makeStyles<Theme>(({
	typography,
	spacing,
	palette,
}) => ({
	root: {
		display: `flex`,
		alignItems: `baseline`,
		justifyContent: `space-between`,
		height: 36,
		margin: spacing(-2, 0, 1),
		padding: spacing(0, 1, 0, 0),

		"& h3, & h4": {
			...typography.truncate,
			fontWeight: `${typography.fontWeightRegular} !important`,
			lineHeight: `1 !important`,
			margin: `0 !important`,
		},

		"& h3": {
			...typography.h3,
			color: palette.text.primary,
		},

		"& h4": {
			...typography.h4,
			color: palette.text.disabled,
		},
	},
}));

interface IInnerChartProps extends ILineChartProps {
	width?: number;
	height?: number;
}

function InnerChart({
	width,
	height,
	dataSets,
	format: _format,
}: IInnerChartProps) {
	const { palette } = useTheme();
	const classes = useInnerChartStyles();
	
	const format: XChartTooltipFormatter = useCallback((value: number) => _format?.(value), [_format]);

	const dataSetColors = {
		primary: palette.type === "light" ? palette.primary.main : palette.primary.light,
		compare: transparentize(palette.text.disabled, 0.75),
	};

	const { primary, compare } = dataSets;
	const data = [primary, compare].reduce((accum, dataSet) => {
		if (!dataSet) {
			return accum;
		}

		if (!accum.length) {
			return dataSet.values.map(({ label, value }) => ({
				label,
				[dataSet.name]: value,
			}));
		}

		return accum.map((prevItem, idx) => {
			const newValueExists = dataSet.values[idx]?.value != null;
			return {
				...prevItem,
				...(newValueExists && { [dataSet.name]: dataSet.values[idx].value }),
			};
		});
	}, [] as Record<string, string>[]);

	return (
		<LineChart className={classes.root}
			data={data}
			width={width}
			height={height}
			margin={{
				top: 8,
				right: 16,
				bottom: 0,
				left: 16,
			}}
		>
			<XAxis
				dataKey="label"
				interval={dataSets.primary?.values?.length - 2}
				tickLine={false}
				axisLine={{
					strokeWidth: 1,
					stroke: palette.divider,
				}}
			/>
			<Tooltip
				isAnimationActive={false}
				content={<ChartTooltip opacity={0.5} formatter={format} />}
				cursor={{ stroke: palette.divider }}
			/>
			<Legend
				content={<ChartLegend shape={ELegendShape.Line} />}
			/>
			{Object.entries(dataSets)
				.reverse()
				.map(([key, { name }]) => (
					<Line
						key={name}
						type="linear"
						dataKey={name}
						stroke={dataSetColors[key]}
						strokeWidth={2}
						dot={false}
						isAnimationActive={false}
					/>
			))}
		</LineChart>
	);
}

const useInnerChartStyles = makeStyles(({ palette, typography }) => ({
	root: {
		"& .recharts-cartesian-axis-tick": {
			"& text": {
				...typography.h6,
				fontSize: 16,
				fontWeight: typography.fontWeightRegular,
			},
		},

		"& .recharts-yAxis .recharts-cartesian-axis-tick text": {
			fill: palette.text.disabled,
		},

		"& .recharts-xAxis .recharts-cartesian-axis-tick text": {
			fill: palette.text.secondary,
		},

		"& .recharts-xAxis .recharts-cartesian-axis-tick": {
			"&:first-child text": {
				textAnchor: `start`,
			},
			"&:last-child text": {
				textAnchor: `end`,
			},
		},
	},
}));

function DiffPill({ dataSets, headerType }: ILineChartProps) {
	const classes = useDiffPillStyles();
	const { palette } = useTheme();

	const primaryValues = dataSets.primary.values;
	const compareValues = dataSets.compare?.values;

	if (!compareValues) {
		return null;
	}

	const a = getSummary(headerType, primaryValues);
	const b = getSummary(headerType, compareValues);

	const delta = (a - b) / b;
	if (!Number.isFinite(delta)) {
		return null;
	}
	const sign = delta > 0 ? "+" : "";
	const display = sign + (delta * 100).toFixed(1) + "%";

	const swatch = delta > 0
		? palette.success
		: palette.warning;
	const foreground = palette.type === "light"
		? swatch.dark
		: lighten(swatch.light, 0.5);
	const background = transparentize(swatch.main, 0.15);

	return (
		<span className={classes.root}
			style={{
				color: foreground,
				background,
			}}
		>
			{display}
		</span>
	);
}

const useDiffPillStyles = makeStyles(({ spacing, shape, typography }) => ({
	root: {
		padding: spacing(0.5, 1),
		borderRadius: shape.borderRadius,
		fontSize: 14,
		fontFamily: typography.fontFamily,
		// unknown typescript issue with fontweight requires typecast
		fontWeight: typography.fontWeightBold as CSSProperties["fontWeight"],
	},
}));

function getSummary(headerType: EHeaderType, values: { value: number }[]): number {
	switch (headerType) {
		case EHeaderType.Sum:
			return values.reduce((accum, { value }) => accum + value, 0);
		case EHeaderType.Last:
			return values[values.length - 1]?.value ?? 0;
		default:
			throw new Error(`Header type (${headerType}) not recognized.`);
	}
}
