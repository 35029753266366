import {
	createContext,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import type { FC, ReactNode } from 'react';
import { TenantContext } from "./TenantContext";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { EEndpointType, IGeneralInformation } from "amp";
import axios from "src/utils/axios";
import { useStateWithMounted } from 'src/utils/useStateWithMounted';

const _GET_BRANDING_GENERAL_INFORMATION = `${EEndpointType.Anonymous}/branding/get-general-information`;

type XStripeContext = Stripe | Promise<Stripe> | undefined;
export const StripeContext = createContext<XStripeContext>(undefined);

interface IStripeConfig {
	stripeAccountId: string;
	stripeKey: string;
}

interface Props {
	children: ReactNode;
}
export const StripeProvider: FC<Props> = ({ children }) => {
	const { ampTenantImpersonateKey } = useContext(TenantContext);
	const [stripeConfig, setStripeConfig] = useStateWithMounted<IStripeConfig>();
	const stripePromise = useMemo(
		() => stripeConfig ? loadStripe(stripeConfig.stripeKey, { stripeAccount: stripeConfig.stripeAccountId }) : null,
		[stripeConfig],
	);
	
	useEffect(() => {
		setTimeout(fetchAndSetPublicStripKey);
	}, [ampTenantImpersonateKey]);

	async function fetchAndSetPublicStripKey(): Promise<void> {
		const { data } = await axios.get<IGeneralInformation[]>(_GET_BRANDING_GENERAL_INFORMATION);
		const brandingData = data[0];
		setStripeConfig({ stripeAccountId: brandingData.stripe_account_id, stripeKey: brandingData.stripe_public_key__c });
	}
	return (
		<StripeContext.Provider value={stripePromise}>
			{children}
		</StripeContext.Provider>
	);
};