import { useCallback } from "react";
import axios from "src/utils/axios";
import {
	IUseConfirmActionTextConfig,
	useConfirmAction,
} from "./useConfirmAction";

interface IDeleteItemState {
	deleteUrl: string;
	postDeleteAction?: (() => Promise<unknown>) | (() => void);
	itemName: string;
	itemNamePlural: string;
}

const useConfirmDelete = ({
	deleteUrl,
	postDeleteAction = () => undefined,
	itemName,
	itemNamePlural,
}: IDeleteItemState): [boolean, (ids: number[]) => Promise<boolean>] => {
	// Create get text config
	const getUseConfirmActionTextConfig = useCallback(
		(ids: number[]): IUseConfirmActionTextConfig => {
			const deleteText =
				ids.length === 1 ? `this ${itemName}` : `these ${itemNamePlural}`;

			return {
				message: `Do you really want to delete ${deleteText}?`,
				header: "Are you sure?",
				confirmText: "Delete",
				successSnackbarMessage: `${
					ids.length === 1 ? itemName : itemNamePlural
				} deleted`,
			};
		},
		[itemName, itemNamePlural],
	);
	// Create delete items action
	const deleteItemsCallback = useCallback(
		async (ids: number[]): Promise<void> => {
			// Check length of id array
			if (!ids?.length) {
				// Throw error
				throw new Error("Id array cannot be empty");
			}
			// Delete items
			await axios.delete<{ ids: number[] }>(deleteUrl, {
				data: {
					ids,
				},
			});
			// Run post delete action
			await postDeleteAction();
		},
		[deleteUrl, postDeleteAction],
	);
	// Get deleting and deleteItems from useConfirmAction
	const [deleting, deleteItems] = useConfirmAction({
		action: deleteItemsCallback,
		getTextConfig: getUseConfirmActionTextConfig,
	});

	// Return deleting and deleteItems
	return [deleting, deleteItems];
};

export default useConfirmDelete;
