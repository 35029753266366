import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	AuthenticationDetails,
	CognitoUserPool,
	CognitoUser,
	CognitoUserSession,
	CognitoAccessToken,
	CognitoIdToken,
	CognitoRefreshToken,
	ISignUpResult,
} from "amazon-cognito-identity-js";
import SplashScreen from "src/components/SplashScreen";
import { TenantContext } from "./TenantContext";
import { useAxiosRequestInterceptor } from "src/hooks/useAxiosRequestInterceptor";
import { useAxiosResponseInterceptor } from "src/hooks/useAxiosResponseInterceptor";
import axiosInstance from "src/utils/axios";
import { EEndpointType } from "amp";
import useIsMountedRef from "src/hooks/useIsMountedRef";

export enum ELoginStatus {
	InProgress = "inProgress",
	Completed = "completed",
	Canceled = "canceled",
	NotStarted = "notStarted",
}

export enum EAuthError {
	Unauthorized = "UNAUTHORIZED",
}

export enum EAuthPermission {
	Admin = "Admin",
	Csr = "CSR",
	SiteLead = "SiteLead",
	Fleet = "Fleet",
}

export enum ELogInResult {
	Success = "success",
	ConfirmationNeeded = "confirmationNeeded",
	PasswordChangeNeeded = "passwordChangeNeeded",
	InvalidCredentials = "invalidCredentials",
	UserDisabled = "userDisabled",
	UnknownError = "unknownError",
}

export interface IAuthContext {
	initialized: boolean;
	error: EAuthError | undefined;
	loggedIn: boolean;
	userLoggedIn: IAuthUser | undefined;
	userPermission: EAuthPermission | undefined;
	userPermissions: EAuthPermission[] | undefined;
	isAuthorizedAppRoutes: boolean;
	isAuthorizedFleetRoutes: boolean;
	isAuthorizedSiteLeadRoutes: boolean;
	loginStatus: ELoginStatus;

	logIn(
		username: string,
		password: string,
		passwordNew?: string,
	): Promise<ELogInResult>;

	logOut(reason?: EAuthError): Promise<void>;

	getUserInfo(): Promise<IAuthUserInfo>;

	changePassword(passwordCurrent: string, passwordNew: string): Promise<void>;

	signUp(username: string, password: string): Promise<void>;

	confirmUser(username: string, code: string): Promise<boolean>;

	resendConfirmation(username: string): Promise<void>;

	forgotPassword(username: string): Promise<void>;

	confirmPassword(
		username: string,
		passwordCode: string,
		passwordNew: string,
	): Promise<void>;
}

export interface IAuthUser {
	username: string;
	token: string;
}

export interface IAuthUserInfo {
	sub: string;
	email: string;
	// eslint-disable-next-line camelcase
	email_verified: boolean;
}

interface IAuthTenant {
	createUserPool: () => CognitoUserPool;

	createUser: (usernameOrAuthToken: string | IAuthToken) => CognitoUser;
}

interface IAuthToken {
	username: string;
	idToken: string;
	accessToken: string;
	expiresAt: number;
	refreshToken: string;
	permissions: EAuthPermission[] | undefined;
}

const intervalTokenExpirationCheck = 30000;
const bufferTokenExpirationTime = 300000;
const secureStoreKey = "AutowashTokenCognito";
const userPermissionsPriority = [
	EAuthPermission.Admin,
	EAuthPermission.Csr,
	EAuthPermission.SiteLead,
	EAuthPermission.Fleet,
];

const noAuthContextProvider = (): never => {
	throw new Error("No auth context provider could be found.");
};

export const AuthContext = createContext<IAuthContext>({
	initialized: false,
	error: undefined,
	loggedIn: false,
	userLoggedIn: undefined,
	userPermission: undefined,
	userPermissions: undefined,
	isAuthorizedAppRoutes: false,
	isAuthorizedFleetRoutes: false,
	isAuthorizedSiteLeadRoutes: false,
	loginStatus: ELoginStatus.NotStarted,
	logIn: noAuthContextProvider,
	logOut: noAuthContextProvider,
	getUserInfo: noAuthContextProvider,
	changePassword: noAuthContextProvider,
	signUp: noAuthContextProvider,
	confirmUser: noAuthContextProvider,
	resendConfirmation: noAuthContextProvider,
	forgotPassword: noAuthContextProvider,
	confirmPassword: noAuthContextProvider,
});

export const AuthProvider: FC = ({ children }) => {
	// Get tenant
	const tenant = _useAuthTenant();
	// Return auth
	return tenant ? <_AuthProvider {...tenant}>{children}</_AuthProvider> : <SplashScreen />;
};

export const _AuthProvider: FC<IAuthTenant> = ({ children, createUserPool, createUser }) => {
	const [initializing, setInitializing] = useState(false);
	const [initialized, setInitialized] = useState<boolean>(false);
	const [authError, setAuthError] = useState<EAuthError | undefined>(undefined);
	const [authToken, setAuthToken] = useState<IAuthToken | undefined>(undefined);
	const [loginStatus, setLoggingInStatus] = useState<ELoginStatus>(
		ELoginStatus.NotStarted,
	);

	const userLoggedIn = useMemo(
		() => authToken ? { username: authToken.username, token: authToken.accessToken } : undefined,
		[authToken],
	);
	const loggedIn = !!userLoggedIn;
	const userPermissions = authToken?.permissions;
	const userPermission = userPermissionsPriority.find(
		(userPermissionPriority) => !!userPermissions?.includes(userPermissionPriority),
	);
	const isAuthorizedAppRoutes =
		userPermission === EAuthPermission.Admin
		|| userPermission === EAuthPermission.Csr;
	const isAuthorizedFleetRoutes =
		userPermission === EAuthPermission.Admin
		|| userPermission === EAuthPermission.Csr
		|| userPermission === EAuthPermission.Fleet;
	const isAuthorizedSiteLeadRoutes =
		userPermission === EAuthPermission.Admin
		|| userPermission === EAuthPermission.Csr
		|| userPermission === EAuthPermission.SiteLead;
	const authUser = useMemo(
		() => {
			// Check if auth token exists
			if (authToken) {
				// Clear auth error
				setAuthError(undefined);
				// Return auth user
				return createUser(authToken);
			}
			// Return no auth user
			return undefined;
		},
		[createUser, authToken],
	);

	const setAndCacheAuthToken = useCallback(
		(authTokenToSetAndCache: IAuthToken | undefined): void => {
			// Check if auth token exists
			if (authTokenToSetAndCache) {
				// Set cached auth token
				localStorage.setItem(
					secureStoreKey,
					JSON.stringify(authTokenToSetAndCache),
				);
			} else {
				// Remove cached auth token
				localStorage.removeItem(secureStoreKey);
			}
			// Set auth token
			setAuthToken(authTokenToSetAndCache);
		},
		[],
	);

	const refreshAndSetAuthToken = useCallback(
		async (authTokenToRefresh: IAuthToken): Promise<void> => {
			// Create refreshed session
			let sessionRefreshed: CognitoUserSession;
			// Try to refresh token
			try {
				// Create user
				const user = createUser(authTokenToRefresh);
				// Get current session
				const sessionCurrent = await _getUserSession(user);
				// Get refresh token
				const refreshToken = sessionCurrent.getRefreshToken();
				// Refresh token
				await new Promise<void>((resolve, reject) =>
					user.refreshSession(refreshToken, (error, value) =>
						(value ? resolve() : reject(error))));
				// Set refreshed session
				sessionRefreshed = await _getUserSession(user);
			} catch (error) {
				// Log warning
				console.warn("Auth token could not be refreshed.");
				// Set no auth token
				setAndCacheAuthToken(undefined);
				// Throw error
				throw error;
			}
			// Set and cache auth token
			setAndCacheAuthToken(_createAuthToken(sessionRefreshed));
		},
		[createUser, setAndCacheAuthToken],
	);

	useEffect(
		() => {
			(async () => {
				// Check if initializing
				if (initializing) {
					// Already initializing
					return;
				}
				// Set initializing
				setInitializing(true);
				// Try silent auth
				try {
					// Get cached auth token string
					const authTokenCachedString = localStorage.getItem(secureStoreKey);
					// Check if cached auth token string exists
					if (authTokenCachedString) {
						// Get cached auth token
						const authTokenCached = JSON.parse(authTokenCachedString) as IAuthToken;
						// Check if auth token is expired
						if (_isExpiredAuthToken(authTokenCached)) {
							// Refresh auth token
							await refreshAndSetAuthToken(authTokenCached);
						} else {
							// Set and cache auth token
							setAndCacheAuthToken(authTokenCached);
						}
						// Set login status to complete
						setLoggingInStatus(ELoginStatus.Completed);
					} else {
						// Log information
						console.log("Silent auth failed because no cached token was found.");
					}
				} catch {
					// Log warning
					console.warn("Silent auth failed, probably because of an expired token.");
				}
				// Set initialized
				setInitialized(true);
			})();
		},
		[setAndCacheAuthToken, refreshAndSetAuthToken, initialized],
	);

	useEffect(() => {
		// Check if not intialized
		if (!initialized) {
			// Wait for initialization
			return undefined;
		}
		// Create timer
		const timer = setInterval(async () => {
			// Check if auth token needs to be refreshed
			if (authToken && _isExpiredAuthToken(authToken)) {
				// Try to refresh and set auth token
				try {
					// Refresh auth token
					await refreshAndSetAuthToken(authToken);
				} catch {
					// Log warning
					console.warn("Silent token refresh failed.");
				}
			}
		}, intervalTokenExpirationCheck);
		// Clear timer
		return () => clearInterval(timer);
	}, [refreshAndSetAuthToken, initialized, authToken]);

	const logIn = useCallback(
		async (
			username: string,
			password: string,
			passwordNew?: string,
		): Promise<ELogInResult> => {
			// Set logging in to in progress
			setLoggingInStatus(ELoginStatus.InProgress);
			// Create session
			let session: CognitoUserSession;
			// Try to log in
			try {
				// Get session or log in result
				const sessionOrLogInResult = await new Promise<
					CognitoUserSession | ELogInResult
				>((resolve, reject) => {
					// Create authentication details
					const authenticationDetails = new AuthenticationDetails({
						Username: username,
						Password: password,
					});
					// Create user
					const user = createUser(username);
					// Authenticate user
					user.authenticateUser(authenticationDetails, {
						onSuccess: resolve,
						onFailure: reject,
						newPasswordRequired: () => {
							// Check if new password exists
							if (passwordNew) {
								// Change password
								user.completeNewPasswordChallenge(passwordNew, undefined, {
									onSuccess: resolve,
									onFailure: reject,
								});
							} else {
								// Return password change needed
								resolve(ELogInResult.PasswordChangeNeeded);
							}
						},
					});
				});
				// Check if log in result
				if (typeof sessionOrLogInResult === "string") {
					// Return log in result
					return sessionOrLogInResult;
				}
				// Set session
				session = sessionOrLogInResult;
				// Check if session is not valid
				if (!session.isValid()) {
					// Throw error
					throw new Error(
						"Auth token could not be set because the response was not valid.",
					);
				}
			} catch (error) {
				// Get log in result
				const logInResult = _handleError(
					error,
					[
						{
							code: "UserNotConfirmedException",
							action: () => ELogInResult.ConfirmationNeeded,
						},
						{
							code: "NotAuthorizedException",
							message: "User is disabled.",
							action: () => ELogInResult.UserDisabled,
						},
						{
							code: "NotAuthorizedException",
							action: () => ELogInResult.InvalidCredentials,
						},
					],
					() => ELogInResult.UnknownError);
				// Set logging status
				setLoggingInStatus(ELoginStatus.Canceled);
				// Return log in result
				return logInResult;
			}
			// Set auth token
			setAndCacheAuthToken(_createAuthToken(session));
			// Set auth status
			setLoggingInStatus(ELoginStatus.Completed);
			// Return success
			return ELogInResult.Success;
		},
		[createUser, setAndCacheAuthToken],
	);

	const logOut = useCallback(async (reason?: EAuthError): Promise<void> => {
		// Check if reason
		if (reason) {
			// Set auth error
			setAuthError(reason);
		}
		// Check if auth user exists
		if (authUser) {
			// Sign out auth user
			await new Promise<void>((resolve) => authUser.signOut(resolve));
		}
		// Set and cache no auth token
		setAndCacheAuthToken(undefined);
		// Set auth status
		setLoggingInStatus(ELoginStatus.NotStarted);
	}, [setAndCacheAuthToken, authUser]);

	const getUserInfo = useCallback(async (): Promise<IAuthUserInfo> => {
		// Ensure auth user exists
		_ensureUser(authUser, "Get user info");
		// Try to get user info
		try {
			// Return user info
			return await new Promise<IAuthUserInfo>((resolve, reject) =>
				authUser.getUserData((error, userData) => {
					// Check if user data exists
					if (userData) {
						// Return user info
						resolve({
							sub: userData.Username,
							email:
								userData.UserAttributes?.find(({ Name }) => Name === "email")
									?.Value ?? "",
							email_verified:
								userData.UserAttributes?.find(
									({ Name }) => Name === "email_verified",
								)?.Value?.toUpperCase() === "TRUE",
						});
					} else {
						// Return error
						reject(error);
					}
				}));
		} catch (error) {
			// Log error
			// eslint-disable-next-line no-console
			console.error("Saved auth token might not be valid. Resetting it...");
			// Clear auth token
			setAndCacheAuthToken(undefined);
			// Throw error
			throw error;
		}
	}, [setAndCacheAuthToken, authUser]);

	const changePassword = useCallback(
		async (passwordCurrent: string, passwordNew: string): Promise<void> => {
			// Ensure auth user exists
			_ensureUser(authUser, "Change password");
			// Change password
			return new Promise<void>((resolve, reject) =>
				authUser.changePassword(passwordCurrent, passwordNew, (error, value) =>
					(value ? resolve() : reject(error))));
		},
		[authUser],
	);

	const signUp = useCallback(
		async (username: string, password: string): Promise<void> => {
			// Create pool
			const pool = createUserPool();
			// Sign up
			await new Promise<ISignUpResult>((resolve, reject) =>
				pool.signUp(username, password, [], [], (error, value) =>
					(value ? resolve(value) : reject(error))));
		},
		[createUserPool],
	);

	const confirmUser = useCallback(
		async (username: string, code: string): Promise<boolean> => {
			// Create user
			const user = createUser(username);
			// Try to confirm registration
			try {
				// Confirm registration
				await new Promise<void>((resolve, reject) =>
					user.confirmRegistration(code, false, (error, value) =>
						(value ? resolve() : reject(error))));
			} catch (error) {
				// Return not confirmed
				return _handleError(
					error,
					[{ code: "CodeMismatchException", action: () => false }],
					() => false,
				);
			}
			// Return confirmed
			return true;
		},
		[createUser],
	);

	const resendConfirmation = useCallback(
		async (username: string): Promise<void> => {
			// Create user
			const user = createUser(username);
			// Resend confirmation
			return new Promise<void>(
				(resolve, reject) => user.resendConfirmationCode(
					(error, value) => (value ? resolve() : reject(error)),
				),
			);
		},
		[createUser],
	);

	const forgotPassword = useCallback(
		async (username: string): Promise<void> => {
			// Create user
			const user = createUser(username);
			// Forgot password
			return new Promise<void>((resolve, reject) =>
				user.forgotPassword({
					onSuccess: () => resolve(),
					onFailure: reject,
				}));
		},
		[createUser],
	);

	const confirmPassword = useCallback(
		async (
			username: string,
			passwordCode: string,
			passwordNew: string,
		): Promise<void> => {
			// Create user
			const user = createUser(username);
			// Confirm password
			return new Promise<void>((resolve, reject) =>
				user.confirmPassword(passwordCode, passwordNew, {
					onSuccess: () => resolve(),
					onFailure: reject,
				}));
		},
		[createUser],
	);

	useAxiosRequestInterceptor(useCallback(
		async () => userLoggedIn ? Promise.resolve({ Authorization: `Bearer ${authToken?.accessToken}` }) : {},
		[authToken],
	));

	useAxiosResponseInterceptor({
		onFailure: useCallback(async ({ status }: { status?: number }) => {
			// Check if forbidden status
			if (status === 403) {
				// Log out
				await logOut(EAuthError.Unauthorized);
			}
		}, [logOut]),
	});

	if (!initialized) {
		return <SplashScreen />;
	}

	return (
		<AuthContext.Provider
			value={{
				initialized,
				error: authError,
				loggedIn,
				userLoggedIn,
				userPermission,
				userPermissions,
				isAuthorizedAppRoutes,
				isAuthorizedFleetRoutes,
				isAuthorizedSiteLeadRoutes,
				loginStatus,
				logIn,
				logOut,
				getUserInfo,
				changePassword,
				signUp,
				confirmUser,
				resendConfirmation,
				forgotPassword,
				confirmPassword,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function _isExpiredAuthToken({ expiresAt }: IAuthToken): boolean {
	// Return if expired
	return Date.now() + bufferTokenExpirationTime > expiresAt * 1000;
}

function _createAuthToken(session: CognitoUserSession): IAuthToken {
	// Get ID token
	const idToken = session.getIdToken();
	// Get access token
	const accessToken = session.getAccessToken();
	// Get refresh token
	const refreshToken = session.getRefreshToken();
	// Return auth token
	return {
		username: _getUsername(accessToken),
		idToken: idToken.getJwtToken(),
		accessToken: accessToken.getJwtToken(),
		expiresAt: accessToken.getExpiration(),
		refreshToken: refreshToken.getToken(),
		permissions: _getPermissions(accessToken),
	};
}

function _getUsername(accessToken: CognitoAccessToken): string {
	// Return username
	return accessToken.payload.username;
}

function _getPermissions(
	accessToken: CognitoAccessToken,
): EAuthPermission[] | undefined {
	// Get permissions
	const permissions = accessToken.payload["cognito:groups"];
	// Check if permissions does not exist
	if (!permissions) {
		// Return no permissions
		return undefined;
	}
	// Check if not an array of strings
	if (
		!Array.isArray(permissions) ||
		!permissions.every((permission) => typeof permission === "string")
	) {
		// Throw error
		throw new Error("Auth permissions must be an array of strings.");
	}
	// Return permissions
	return permissions as EAuthPermission[];
}

function _ensureUser(
	user: CognitoUser | undefined,
	action: string,
): asserts user {
	// Check if user does not exist
	if (!user) {
		// Throw error
		throw new Error(`Must have a valid user before taking action: ${action}`);
	}
}

async function _getUserSession(user: CognitoUser): Promise<CognitoUserSession> {
	// Return session
	return new Promise((resolve, reject) => {
		// Return session
		return user.getSession((error: Error | null, session: CognitoUserSession | null) => {
			// Check if session exists
			if (session) {
				// Return session
				resolve(session);
			} else {
				// Throw error
				reject(error);
			}
		});
	});
}

function _handleError<TValue>(
	error: unknown,
	handlers: { code: string; message?: string; action: () => TValue }[],
	fallback: (errorUnknown: unknown) => TValue,
): TValue {
	// Get code
	const code = (error as { code: unknown } | undefined)?.code;
	// Get message
	const message = (error as { message: unknown } | undefined)?.message;
	// Get handler
	const handler =
		handlers.find(
			({ code: codeHandled, message: messageHandled }) =>
				code &&
				codeHandled === code &&
				message &&
				messageHandled &&
				messageHandled === message,
		)
		?? handlers.find(
			({ code: codeHandled, message: messageHandled }) =>
				code && codeHandled === code && !messageHandled,
		);
	// Check if handler exists
	if (handler) {
		// Execute action
		return handler.action();
	}
	// Log information
	console.log(`Unknown auth error (${error}) detected.`);
	// Execute fallback
	return fallback(error);
}

function _useAuthTenant(): IAuthTenant | undefined {
	// Get is mounted
	const isMountedRef = useIsMountedRef();
	// Get tenant key
	const { ampTenantKey: tenantKey } = useContext(TenantContext);
	// Create tenant configuration
	const [tenantConfiguration, setTenantConfiguration] = useState<
		{ userPoolId: string; clientId: string } | undefined
	>();
	// Get tenant configuration
	useEffect(
		() => {
			// Check if tenant key does not exist
			if (!tenantKey) {
				// Wait for tenant key
				return;
			}
			// Create set tenant configuration from API
			const setTenantConfigurationFromApi = async () => {
				// Wait for tenant context to settle so that tenant header is properly injected
				await new Promise<void>(resolve => setTimeout(resolve, 0));
				// Get tenant configuration from API
				const { data: tenantConfigurationFromApi } = await axiosInstance.get<
					{ userPoolId: string; clientId: string } | undefined
				>(
					`${EEndpointType.Anonymous}/user/get-auth-configuration`,
				);
				// Check if mounted
				if (isMountedRef.current) {
					// Check if user pool ID or client ID do not exist
					if (
						!tenantConfigurationFromApi
						|| !tenantConfigurationFromApi.userPoolId
						|| !tenantConfigurationFromApi.clientId
					) {
						// Throw error
						throw new Error("User pool ID and client ID must exist.");
					}
					// Set tenant configuration
					setTenantConfiguration({
						userPoolId: tenantConfigurationFromApi.userPoolId,
						clientId: tenantConfigurationFromApi.clientId,
					});
				}
			};
			// Set tenant configuration from API
			setTenantConfigurationFromApi();
		},
		[tenantKey],
	);

	// Create create user pool
	const createUserPool = useCallback(
		(): CognitoUserPool => {
			// Get configuration
			if (!tenantConfiguration) {
				throw new Error(
					"User cannot be created.",
				);
			}
			const { userPoolId, clientId } = tenantConfiguration;
			// Return pool
			return new CognitoUserPool({ UserPoolId: userPoolId, ClientId: clientId });
		},
		[tenantConfiguration],
	);
	// Create create user
	const createUser = useCallback(
		(usernameOrAuthToken: string | IAuthToken): CognitoUser => {
			// Get username
			const username =
				typeof usernameOrAuthToken === "string"
					? usernameOrAuthToken
					: usernameOrAuthToken.username;
			// Create pool
			const pool = createUserPool();
			// Create user
			const user = new CognitoUser({ Pool: pool, Username: username });
			// Check if auth token
			if (typeof usernameOrAuthToken !== "string") {
				// Set session
				user.setSignInUserSession(
					new CognitoUserSession({
						IdToken: new CognitoIdToken({
							IdToken: usernameOrAuthToken.idToken,
						}),
						AccessToken: new CognitoAccessToken({
							AccessToken: usernameOrAuthToken.accessToken,
						}),
						RefreshToken: new CognitoRefreshToken({
							RefreshToken: usernameOrAuthToken.refreshToken,
						}),
					}),
				);
			}
			// Return user
			return user;
		},
		[createUserPool],
	);
	// Return auth tenant
	return tenantConfiguration ? { createUserPool, createUser } : undefined;
}
