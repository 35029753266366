import { EFormType } from "src/enums/form-type.enum";
import { EPageType } from "src/enums/page-type.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.usersAndAccounts}/legacy-members`;

const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;
const EDIT_ROUTE = `${BASE_ROUTE}/${EFormType.edit}/:id`;
const CREATE_ROUTE = `${BASE_ROUTE}/${EFormType.create}`;

export const LegacyMembersRoutes = {
	BASE_ROUTE,
	LIST_ROUTE,
	EDIT_ROUTE,
	CREATE_ROUTE,
};