import { EFormType } from "src/enums/form-type.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { EPageType } from "src/enums/page-type.enum";

export function generateManagmentRoutes(
	category: ENavCategory,
	target: string,
) {
	const BASE_ROUTE = `/${ENavBaseRoute.management}/${category}/${target}`;
	const FORM_ROUTE = `${BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
	const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;
	const CREATE_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
	const EDIT_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;

	return {
		FORM_ROUTE,
		LIST_ROUTE,
		CREATE_ROUTE,
		EDIT_ROUTE,
		BASE_ROUTE,
	};
}
