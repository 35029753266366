import { createContext, useCallback, useContext, useEffect, ReactNode } from "react";
import { useStateWithMounted } from "src/utils/useStateWithMounted";
import { useAxiosRequestInterceptor } from "src/hooks/useAxiosRequestInterceptor";
import axios from "src/utils/axios";
import { EEndpointType, XAmpUserContext } from "amp";
import { AuthContext } from "./AuthContext";

interface IUserAndAccountContextValue {
	setAccountId: (accountId: string | undefined) => void;
	userId: string | undefined;
	accountId: string | undefined;
}

export const UserAndAccountContext = createContext<IUserAndAccountContextValue>({
	setAccountId: () => undefined,
	userId: undefined,
	accountId: undefined,
});

interface IUserAndAccountProviderProps {
	children: ReactNode;
}

export const UserAndAccountProvider = ({ children }: IUserAndAccountProviderProps) => {
	const [accountId, setAccountId] = useStateWithMounted<string | undefined>(undefined);
	const [userId, setUserId] = useStateWithMounted<string | undefined>(undefined);

	const { userLoggedIn } = useContext(AuthContext);
	useAxiosRequestInterceptor(useCallback(() => {
		return {
			"Amp-Account-Id": accountId ?? "USE-DEFAULT-ACCOUNT",
		};
	}, [accountId]));

	const getMe = async () => {
		const user = (await axios.get<XAmpUserContext | undefined>(`${EEndpointType.Public}/user/me`)).data;
		if (user) {
			setUserId(user.mobileUser.external_id__c);
			if (!accountId) {
				setAccountId(user.accountContext.account.external_id__c);
			}
		}
	};

	useEffect(() => {
		if (userLoggedIn) {
			// Give time for auth header to be set
			setTimeout(() => {
				getMe();
			}, 1000);
		} else {
			setUserId(undefined);
			setAccountId(undefined);
		}
	}, [userLoggedIn]);
	return (
		<UserAndAccountContext.Provider
			value={{
				setAccountId,
				userId,
				accountId,
			}}
		>
			{children}
		</UserAndAccountContext.Provider>
	);
};