import { useCallback, useMemo } from "react";
import axios from "src/utils/axios";
import { FetchData, FetchDataConfig, useFetchData } from "./useFetchData";
import { EEndpointType } from "amp";
import { XDynamicSqlData, XDynamicSqlName, IDynamicSqlQuery } from "amp";

const _COUNT = Symbol("Count");

export function useCountDataDynamic<
	TFetchDataDynamicName extends XDynamicSqlName,
>(
	name: TFetchDataDynamicName,
	query?: IDynamicSqlQuery,
	config?: FetchDataConfig,
): FetchData<number> {
	// Get query query with count
	const queryWithCount = { ...query, count: true };
	// Get query JSON
	const queryJson = useMemo(
		() => _getQueryJson(queryWithCount),
		[queryWithCount],
	);
	// Create get data
	const getData = useCallback(
		async () => _fetchDataDynamic(name, queryJson, _COUNT),
		[name, queryJson],
	);
	// Use data
	return useFetchData(getData, config);
}

export function useFetchDataDynamic<
	TFetchDataDynamicName extends XDynamicSqlName,
>(
	name: TFetchDataDynamicName,
	query?: IDynamicSqlQuery,
	config?: FetchDataConfig,
): FetchData<XDynamicSqlData<TFetchDataDynamicName>[]> {
	// Get query JSON
	const queryJson = useMemo(() => _getQueryJson(query), [query]);
	// Create get data
	const getData = useCallback(
		async () => _fetchDataDynamic(name, queryJson),
		[name, queryJson],
	);
	// Use data
	return useFetchData(getData, config);
}

export async function fetchDataDynamic<
	TFetchDataDynamicName extends XDynamicSqlName,
>(
	name: TFetchDataDynamicName,
	query?: IDynamicSqlQuery,
): Promise<XDynamicSqlData<TFetchDataDynamicName>[]> {
	// Get query JSON
	const queryJson = _getQueryJson(query);
	// Return data
	return _fetchDataDynamic(name, queryJson);
}

export async function countDataDynamic<
	TFetchDataDynamicName extends XDynamicSqlName,
>(name: TFetchDataDynamicName, query?: IDynamicSqlQuery): Promise<number> {
	// Get query JSON
	const queryJson = _getQueryJson(query);
	// Return data
	return _fetchDataDynamic(name, queryJson, _COUNT);
}

async function _fetchDataDynamic<TFetchDataDynamicName extends XDynamicSqlName>(
	name: TFetchDataDynamicName,
	queryJson: string | undefined,
): Promise<XDynamicSqlData<TFetchDataDynamicName>[]>;
async function _fetchDataDynamic<TFetchDataDynamicName extends XDynamicSqlName>(
	name: TFetchDataDynamicName,
	queryJson: string | undefined,
	count: typeof _COUNT,
): Promise<number>;
async function _fetchDataDynamic<TFetchDataDynamicName extends XDynamicSqlName>(
	name: TFetchDataDynamicName,
	queryJson: string | undefined,
	count?: typeof _COUNT,
): Promise<XDynamicSqlData<TFetchDataDynamicName>[] | number> {
	// Get query
	const query = queryJson ? JSON.parse(queryJson) : undefined;
	// Get data
	const { data } = await axios.post<
		XDynamicSqlData<TFetchDataDynamicName>[] | number
	>(
		`/${EEndpointType.Anonymous}/dynamic/query/${name}`,
		count === _COUNT ? { ...query, count: true } : query,
	);
	// Return data
	return data;
}

function _getQueryJson(
	query: IDynamicSqlQuery | undefined,
): string | undefined {
	// Return query JSON
	return query ? JSON.stringify(query) : undefined;
}
