import { EFormType } from "src/enums/form-type.enum";
import { EPageType } from "src/enums/page-type.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";

const BRAND_IMAGES_BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.branding}/brand-images`

const BRAND_IMAGES_LIST_ROUTE = `${BRAND_IMAGES_BASE_ROUTE}/${EPageType.list}`;
const BRAND_IMAGES_CREATE_ROUTE = `${BRAND_IMAGES_BASE_ROUTE}/${EFormType.create}`;
const BRAND_IMAGES_EDIT_ROUTE = `${BRAND_IMAGES_BASE_ROUTE}/${EFormType.edit}/:id`;

export const BrandImageRoutes = {
	BRAND_IMAGES_BASE_ROUTE,
	BRAND_IMAGES_LIST_ROUTE,
	BRAND_IMAGES_CREATE_ROUTE,
	BRAND_IMAGES_EDIT_ROUTE,
};