import React, {
	useEffect,
	useRef,
	useState
} from 'react';
import type { FC } from 'react';
import { useSnackbar } from 'notistack';
import {
	Box,
	ButtonBase,
	Hidden,
	Menu,
	MenuItem,
	Typography,
	makeStyles,
	Tooltip,
	SvgIcon,
	IconButton,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { useFetchData } from 'src/hooks/useFetchData';
import { User as UserIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
	avatar: {
		height: 32,
		width: 32,
		marginRight: theme.spacing(1)
	},
	popover: {
		width: 200
	}
}));

const Account: FC = () => {
	const classes = useStyles();
	const ref = useRef<any>(null);
	const { logOut, userLoggedIn, getUserInfo } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const [isOpen, setOpen] = useState<boolean>(false);

	const { data, retry } = useFetchData(getUserInfo, { handleInitialDataLoad: false });
	const username = userLoggedIn?.username;
	useEffect(() => { username && retry() }, [username]);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleLogout = async (): Promise<void> => {
		try {
			handleClose();
			await logOut();
		} catch (err) {
			console.error(err);
			enqueueSnackbar('Unable to logout', {
				variant: 'error'
			});
		}
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				// @ts-ignore
				ref={ref}
			>
				<Hidden smDown>
					<Box
						display="flex"
						alignItems="center"
						component={ButtonBase}
						onClick={handleOpen}
					>
						<Typography
							variant="h6"
							color="inherit"
						>
							{data?.email}
						</Typography>
					</Box>
				</Hidden>
				<Hidden mdUp>
					<Typography
						variant="h6"
						color="inherit"
					>
						<Tooltip title="User">
							<IconButton
								color="inherit"
								onClick={handleOpen}
							>
								<SvgIcon fontSize="small">
									<UserIcon />
								</SvgIcon>
							</IconButton>
						</Tooltip>
					</Typography>
				</Hidden>
			</Box>
			<Menu
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				keepMounted
				PaperProps={{ className: classes.popover }}
				getContentAnchorEl={null}
				anchorEl={ref.current}
				open={isOpen}
			>
				<MenuItem button={false}>
					{data?.email}
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
}

export default Account;
