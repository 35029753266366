import React from "react";
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsSuperAdmin } from 'src/hooks/useIsSuperAdmin';

interface SuperAdminGuardProps {
	redirect?: boolean;
  children?: ReactNode;
}

const SuperAdminGuard: FC<SuperAdminGuardProps> = ({ redirect = true, children }) => {
  const isSuperAdmin = useIsSuperAdmin();

	if (!isSuperAdmin) {
		if (redirect) {
			return <Navigate to="/app" />;
		} else {
			return null;
		}
	}
	return (
		<>
			{children}
		</>
	);
};

export default SuperAdminGuard;
