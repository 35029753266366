import {
	XGeneralMultiTenantWritable,
	XGeneralMultiTenantWritableWithId,
	XCodeBucketsWithLocationCounts,
	ICodeBucket,
	IAutowashLocation,
	EEndpointType,
} from "amp";
import { AxiosResponse } from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { SortOption } from "src/components/Results";
import useConfirmDelete from "src/hooks/useConfirmDelete";
import axios from "src/utils/axios";
 
const PAGE_NAME = "Code Groups";
const PORTAL_URL = "/app/management/code-groups";
const PORTAL_URL_CREATE = PORTAL_URL + "/create";
const PORTAL_URL_EDIT = PORTAL_URL + "/:id/edit";

const API_CODE_BUCKET_URL = `${EEndpointType.Csr}/code-bucket`;
const DELETE_CODE_BUCKET_ENDPOINT = `${API_CODE_BUCKET_URL}/delete`;

const ITEM_NAME_PLURAL = "Code Groups";
const ITEM_NAME = "Code Group";
const sortOptions: SortOption<XCodeBucketsWithLocationCounts>[] = [
{
	key: "name",
	direction: "asc",
	label: "Name (A-Z)",
},
{
	key: "name",
	direction: "desc",
	label: "Name (Z-A)",
}];

const locationTableSortOptions: SortOption<IAutowashLocation>[] = [
	{
		key: "name",
		direction: "asc",
		label: "Name (A-Z)",
	},
	{
		key: "name",
		direction: "desc",
		label: "Name (Z-A)",
	}];
const getPortalUrlEdit = (id?: number | string) => id
	? PORTAL_URL_EDIT.replace(":id", id.toString())
	: PORTAL_URL_EDIT;

const useGoTo = () => {
	const navigate = useNavigate();
	const goToCreate = useCallback(() => navigate(PORTAL_URL_CREATE), []);
	const goToList = useCallback(() => navigate(PORTAL_URL), []);
	const goToEdit = useCallback((id: string) => navigate(`${PORTAL_URL}/${id}/edit`), []);
	const getEditUrl = useCallback((id: string) => `${PORTAL_URL}/${id}/edit`, []);
	return { goToCreate, goToList, goToEdit, getEditUrl };
};

const getCodeBuckets = async (): Promise<XCodeBucketsWithLocationCounts[]> => {
	const result = await axios.get<XCodeBucketsWithLocationCounts[]>(`${API_CODE_BUCKET_URL}/get-code-bucket-with-location-counts`);
	return result?.data;
};

const getCodeBucketById = async (id: number): Promise<ICodeBucket | undefined> => {
	const result = await axios.get<
		ICodeBucket | undefined
	>(`${API_CODE_BUCKET_URL}/get-by-id/${id}`);
	return result?.data;
};

const createCodeBucket = async (codeBucket: XGeneralMultiTenantWritable<ICodeBucket>): Promise<ICodeBucket> => {
	const result = await axios.post<
		XGeneralMultiTenantWritable<ICodeBucket>,
		AxiosResponse<ICodeBucket>
	>(`${API_CODE_BUCKET_URL}/create`, codeBucket);
	return result?.data;
};

const updateCodeBucket = async (codeBucket: Partial<XGeneralMultiTenantWritableWithId<ICodeBucket>>): Promise<ICodeBucket | undefined> => {
	const result = await axios.post<
		Partial<XGeneralMultiTenantWritableWithId<ICodeBucket>>,
		AxiosResponse<ICodeBucket | undefined>
	>(`${API_CODE_BUCKET_URL}/update`, codeBucket);
	return result?.data;
};

const useDelete = (
	{ postDeleteAction }: { postDeleteAction?: () => Promise<unknown> } = {}
) => useConfirmDelete({
	deleteUrl: DELETE_CODE_BUCKET_ENDPOINT,
	itemName: ITEM_NAME,
	itemNamePlural: ITEM_NAME_PLURAL,
	postDeleteAction,
});
export const CodeBucketService = {
	PAGE_NAME,
	PORTAL_URL,
	PORTAL_URL_CREATE,
	PORTAL_URL_EDIT,
	ITEM_NAME,
	ITEM_NAME_PLURAL,
	DELETE_CODE_BUCKET_ENDPOINT,
	sortOptions,
	locationTableSortOptions,
	useGoTo,
	useDelete,
	getCodeBuckets,
	getCodeBucketById,
	createCodeBucket,
	updateCodeBucket,
	getPortalUrlEdit,
};