import { EFormType } from "src/enums/form-type.enum";
import { EPageType } from "src/enums/page-type.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { EKioskTypeId, EKioskTypeLabel } from "amp";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.hardware}/kiosks`;
const KIOSK_GROUPS = "kiosk-groups";
const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;
const FORM_KIOSK_ROUTE = `${BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const CREATE_KIOSK_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
const EDIT_KIOSK_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const COPY_KIOSK_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.copy}/:id`;
const FORM_KIOSK_GROUP_BASE = `${BASE_ROUTE}/${KIOSK_GROUPS}/${EPageType.form}`;
const FORM_KIOSK_GROUP_ROUTE = `${FORM_KIOSK_GROUP_BASE}/:formType/:id?`;
const CREATE_KIOSK_GROUP_ROUTE = `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/:kioskType?`;
const EDIT_KIOSK_GROUP_ROUTE = `${FORM_KIOSK_GROUP_BASE}/${EFormType.edit}/:id`;
const COPY_KIOSK_GROUP_ROUTE = `${FORM_KIOSK_GROUP_BASE}/${EFormType.copy}/:id`;

function getKioskGroupCreateRoute(type: EKioskTypeId) {
	switch (type) {
		case EKioskTypeId.Automatic:
			return `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/${EKioskTypeLabel.Automatic}`;
		case EKioskTypeId.SelfService:
			return `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/${EKioskTypeLabel.SelfService}`;
		case EKioskTypeId.CashMachine:
			return `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/${EKioskTypeLabel.CashMachine}`;
		case EKioskTypeId.Tunnel:
			return `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/${EKioskTypeLabel.Tunnel}`;
		case EKioskTypeId.LoadingStation:
			return `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/${EKioskTypeLabel.LoadingStation}`;
		case EKioskTypeId.Display:
			return `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/${EKioskTypeLabel.Display}`;
		default:
			return `${FORM_KIOSK_GROUP_BASE}/${EFormType.create}/type/${EKioskTypeLabel.Automatic}`;
	}
}

export const KioskRoutes = {
	BASE_ROUTE,
	LIST_ROUTE,
	// Kiosk routes
	FORM_KIOSK_ROUTE,
	CREATE_KIOSK_ROUTE,
	EDIT_KIOSK_ROUTE,
	COPY_KIOSK_ROUTE,
	// Kiosk Group routes
	FORM_KIOSK_GROUP_ROUTE,
	CREATE_KIOSK_GROUP_ROUTE,
	EDIT_KIOSK_GROUP_ROUTE,
	COPY_KIOSK_GROUP_ROUTE,
	getKioskGroupCreateRoute,
};
