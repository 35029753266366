import { XGeneralMultiTenantWritable, XGeneralMultiTenantWritableWithId, ICustomerJourneyMessageTemplate, EEndpointType, ICustomerJourneyStatusCounts } from "amp";

import { AxiosResponse } from "axios";
import { Breadcrumb } from "src/components/Header";
import { EFormType } from "src/enums/form-type.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { ENavCategory } from "src/enums/nav-category.enum";
import { EPageType } from "src/enums/page-type.enum";
import axios from "src/utils/axios";

const CUSTOMER_JOURNEY_NAME = "Customer Journey";
const CUSTOMER_JOURNEY_NAME_PLURAL = "Customer Journeys";
const MESSAGE_TEMPLATE_NAME = "Message Template";
const MESSAGE_TEMPLATE_NAME_PLURAL = "Message Templates";
const USER_JOURNEY_NAME = "User Journey";
const USER_JOURNEY_NAME_PLURAL = "User Journeys";

const BASE_ROUTE = `/${ENavBaseRoute.management}/${ENavCategory.marketing}/customer-journeys`;

const API_ROUTE = "customer-journeys";
const MESSAGE_TEMPLATE_DELETE_ENDPOINT = `${EEndpointType.Csr}/${API_ROUTE}/delete-message-template`;

const LIST_ROUTE = `${BASE_ROUTE}/${EPageType.list}`;

const CUSTOMER_JOURNEY_FORM_ROUTE = `${BASE_ROUTE}/${EPageType.form}/:formType/:id?`;
const CUSTOMER_JOURNEY_CREATE_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.create}`;
const CUSTOMER_JOURNEY_EDIT_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.edit}/:id`;
const CUSTOMER_JOURNEY_COPY_ROUTE = `${BASE_ROUTE}/${EPageType.form}/${EFormType.copy}/:id`;

const MESSAGE_TEMPLATE_FORM_ROUTE = `${BASE_ROUTE}/message-template/${EPageType.form}/:formType/:id?`;
const MESSAGE_TEMPLATE_CREATE_ROUTE = `${BASE_ROUTE}/message-template/${EPageType.form}/${EFormType.create}`;
const MESSAGE_TEMPLATE_EDIT_ROUTE = `${BASE_ROUTE}/message-template/${EPageType.form}/${EFormType.edit}/:id`;
const MESSAGE_TEMPLATE_COPY_ROUTE = `${BASE_ROUTE}/message-template/${EPageType.form}/${EFormType.copy}/:id`;
const BREADCRUMBS: Breadcrumb[] = [{
	label: CUSTOMER_JOURNEY_NAME_PLURAL,
	url: CUSTOMER_JOURNEY_NAME_PLURAL,
}];

const getUserFlowStatusCounts = async (customerJourneyIds: number[]): Promise<ICustomerJourneyStatusCounts[]> => {
	return (await axios.post<number[], AxiosResponse<ICustomerJourneyStatusCounts[]>>(
		`${EEndpointType.Csr}/${API_ROUTE}/get-user-flow-status-counts`, 
		{ customerJourneyIdFilter: customerJourneyIds })
	).data;
};

const createMessageTemplate = async (
	insertRequest: XGeneralMultiTenantWritable<ICustomerJourneyMessageTemplate>
): Promise<ICustomerJourneyMessageTemplate> => {
	return (await axios.post<ICustomerJourneyMessageTemplate>(
		`${EEndpointType.Csr}/${API_ROUTE}/insert-message-template`, 
		{ insertRequest })
	).data;
};

const updateMessageTemplate = async (
	updateRequest: Partial<XGeneralMultiTenantWritableWithId<ICustomerJourneyMessageTemplate>>,
): Promise<ICustomerJourneyMessageTemplate> => {
	return (await axios.post<ICustomerJourneyMessageTemplate>(
		`${EEndpointType.Csr}/${API_ROUTE}/update-message-template`, 
		{ updateRequest })
	).data;
};

export const CustomerJourneyService = {
	CUSTOMER_JOURNEY_NAME,
	CUSTOMER_JOURNEY_NAME_PLURAL,
	MESSAGE_TEMPLATE_NAME,
	MESSAGE_TEMPLATE_NAME_PLURAL,
	USER_JOURNEY_NAME,
	USER_JOURNEY_NAME_PLURAL,
	BREADCRUMBS,

	LIST_ROUTE,
	CUSTOMER_JOURNEY_FORM_ROUTE,
	CUSTOMER_JOURNEY_CREATE_ROUTE,
	CUSTOMER_JOURNEY_EDIT_ROUTE,
	CUSTOMER_JOURNEY_COPY_ROUTE,
	MESSAGE_TEMPLATE_FORM_ROUTE,
	MESSAGE_TEMPLATE_COPY_ROUTE,
	MESSAGE_TEMPLATE_CREATE_ROUTE,
	MESSAGE_TEMPLATE_EDIT_ROUTE,
	MESSAGE_TEMPLATE_DELETE_ENDPOINT,

	getUserFlowStatusCounts,
	createMessageTemplate,
	updateMessageTemplate,
};