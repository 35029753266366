import { EErrorId, ERefundFailReson, IDisplayError, IError, XMaybe, getError } from "amp";

export const handleRefundFailedError = (error: IError<unknown>): XMaybe<IDisplayError> => {
	// Get promo code error
	const refundError = getError(error, EErrorId.RefundFailed);
	if (refundError) {
		const { id, data } = refundError;
		const message = data?.reason ? mapRefundReasonToErrorMessage(data?.reason) : "Unknown error";
		return {
			message,
			id,
			name: "Refund Failed"
		};
	} 
	return undefined;
};

export function mapRefundReasonToErrorMessage(reason: ERefundFailReson) {
	switch (reason) {
		case ERefundFailReson.RefundWindowElapsed:
			return "You do not have permission to refund this transaction. The refund window has elapsed.";
		case ERefundFailReson.ChargeDisputed:
			return "This payment is being disputed by the customer and a standard refund cannot be processed during a dispute. Please access Stripe (payment processor) to handle the dispute and/or refund. Note that permissions to your business's Stripe may be restricted, please reach out to your manager, as needed. https://dashboard.stripe.com/disputes";
		default:
			return "There was an error processing the refund. Please try again or contact support.";
	}
}