import { useSnackbar } from "notistack";
import { ReactNode, useCallback, useState } from "react";
import { confirmationModalService } from "src/services/confirmation-modal.service";

export interface IUseConfirmActionTextConfig {
	header?: string;
	message?: ReactNode;
	confirmText?: string;
	successSnackbarMessage?: string;
	hideButtons?: boolean;
}

interface IUseConfirmAction<T = void> {
	action: (params?: T) => Promise<void> | ((params?: T) => void);
	getTextConfig?: (params?: T) => IUseConfirmActionTextConfig;
}

export function useConfirmAction<T = void>({
	action,
	getTextConfig,
}: IUseConfirmAction<T>): [boolean, (params?: T) => Promise<boolean>] {
	const [loading, setLoading] = useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();

	const executeAction = useCallback(
		async (params?: T): Promise<boolean> => {
			// Get text config
			const textConfig = getTextConfig?.(params);
			// Trigger confirmation modal
			const confirmed = await confirmationModalService.confirmAsPromise({
				message: textConfig?.message,
				header: textConfig?.header,
				confirmText: textConfig?.confirmText,
				hideButtons: textConfig?.hideButtons,
			});
			// Check if confirmed
			if (confirmed) {
				try {
					// Set loading
					setLoading(true);
					// Execute action
					await action(params);
					// Show success message
					if (textConfig?.successSnackbarMessage) {
						enqueueSnackbar(textConfig.successSnackbarMessage, {
							variant: "success",
						});
					}
					// Return success
					return true;
				} catch (err) {
					enqueueSnackbar(err.message ?? "Unknown Error", {
						variant: "error",
					});
					// Log error
					throw Error(err.message ?? "Unknown error");
				} finally {
					// Set not loading
					setLoading(false);
				}
			}
			// Return not success
			return false;
		},
		[action, getTextConfig, enqueueSnackbar],
	);

	// Return loading and action callback
	return [loading, executeAction];
}
