import { createContext, useCallback, useEffect, useMemo, useState, ReactNode } from "react";
import { EEndpointType } from "amp";
import environment from "src/environment";
import { useAxiosRequestInterceptor } from "src/hooks/useAxiosRequestInterceptor";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import axios from "src/utils/axios";
import { IGetPortalTenantRequest, IGetPortalTenantResponse, XTenantKeyAndLogo } from "amp";
import { useNavigate } from "react-router";

export const AUTOWASH_TENANT_KEY = "AUTOWASH";
const _GET_TENANT_KEYS_ENDPOINT = `/${EEndpointType.Csr}/tenant/get-tenant-keys-and-logos`;
const _GET_TENANT_KEY_ENDPOINT = `/${EEndpointType.Csr}/tenant/get-tenant-key-and-logo`;

const _AMP_TENANT_HEADER = "Amp-Tenant";
const _AMP_TENANT_IMPERSONATE_HEADER = "Amp-Tenant-Impersonate";

interface ITenantState {
	ampTenantKey: string | null;
	ampTenantImpersonateKey: string | null;
	ampTenants: XTenantKeyAndLogo[];
}

const initialTenantState: ITenantState = {
	ampTenantKey: null,
	ampTenantImpersonateKey: null,
	ampTenants: [],
};

export interface ITenantContextValue extends ITenantState {
	handleImpersonateTenantKeyChange: (key: string) => void;
	fetchAmpTenants: () => void;
	updateTenant: (tenantKey: string) => Promise<void> | undefined;
	currentTenant: XTenantKeyAndLogo | undefined;
}

export const TenantContext = createContext<ITenantContextValue>({
	ampTenantKey: null,
	ampTenantImpersonateKey: null,
	ampTenants: [],
	currentTenant: undefined,
	handleImpersonateTenantKeyChange: (key: string) => { },
	fetchAmpTenants: () => undefined,
	updateTenant: (tenantKey: string) => undefined,
});

interface ITenantProviderProps {
	children: ReactNode;
}

export const TenantProvider = ({ children }: ITenantProviderProps) => {
	const navigate = useNavigate();
	const [tenantState, setTenantState] = useState(initialTenantState);
	const {
		ampTenantKey,
		ampTenantImpersonateKey,
		ampTenants,
	} = tenantState;

	const isMountedRef = useIsMountedRef();

	useEffect(() => {
		// Get tenant key from URL
		getTenantKeyFromUrl().then((tenantKey) => {
			// Set tenant key
			updateTenantState({ ampTenantKey: tenantKey });
		});
	}, []);

	const handleImpersonateTenantKeyChange = useCallback((key: string) => {
		// Check if amp tenant key is autowash key
		if (ampTenantKey !== AUTOWASH_TENANT_KEY) {
			// Throw error if trying to impersonate and not autowash
			throw new Error("Do not impersonate tenants if not autowash user");
		}
		// Check if impersonate key changed
		if (ampTenantImpersonateKey !== key) {
			// Redirect to dashboard
			navigate("/management/dashboard");
			// Update impersonate key
			updateTenantState({
				ampTenantImpersonateKey: key,
			});
		}
	}, [navigate, ampTenantKey, ampTenantImpersonateKey]);

	const updateTenantState = (update: Partial<ITenantState>): void => {
		setTenantState((prevState) => ({
			...prevState,
			...update,
		}));
	};

	const getTenantKeyFromUrl = async () => {
		// Get host
		const { host } = window.location;
		// Check if running locally
		if (host.startsWith("localhost:")) {
			// Return localhost tenant key
			return environment.localhost.tenantKey;
		}
		// Check if standard multi-tenant portal
		if (host.endsWith(".ampmemberships.com") || host.endsWith(".herokuapp.com")) {
			// Split domain at "."
			const domainSegments = host
				.split(".")
				// Discard the "www" if present
				.filter(segment => !/^(www)$/i.test(segment));
			// Split first segment on "-"
			const firstDomainSegment = domainSegments[0]
				.split("-")[0];
			// Return tenant key
			return firstDomainSegment.toUpperCase();
		}
		// Create tenant key for custom portal request
		const getPortalTenantRequest: IGetPortalTenantRequest = { portalUrl: host };
		// Get tenant key for custom portal
		const getPortalTenantResponse = await axios.post<IGetPortalTenantResponse>(
			`/${EEndpointType.Anonymous}/metadata/get-portal-tenant`,
			getPortalTenantRequest,
			{ headers: { [_AMP_TENANT_HEADER]: AUTOWASH_TENANT_KEY } },
		);
		// Check if tenant key does not exist
		if (!getPortalTenantResponse?.data?.tenantKey) {
			// Throw error
			throw new Error(`Tenant key for portal URL (${host}) could not be resolved.`);
		}
		// Return custom portal tenant key
		return getPortalTenantResponse.data.tenantKey;
	};

	const getTenantHeaders = useCallback((): Record<string, string> => {
		// Return tenant headers
		if (ampTenantKey) {
			return ampTenantImpersonateKey && ampTenantImpersonateKey !== AUTOWASH_TENANT_KEY ? {
				[_AMP_TENANT_HEADER]: ampTenantKey,
				[_AMP_TENANT_IMPERSONATE_HEADER]: ampTenantImpersonateKey
			} : {
				[_AMP_TENANT_HEADER]: ampTenantKey,
			};
		}
		return {};
	}, [ampTenantKey, ampTenantImpersonateKey]);

	useAxiosRequestInterceptor(getTenantHeaders);

	async function fetchTenantKeysAndLogos(): Promise<XTenantKeyAndLogo[]> {
		const response = await axios.get(_GET_TENANT_KEYS_ENDPOINT);

		return response.data;
	}

	const fetchAmpTenants = () => {
		if (ampTenantKey === AUTOWASH_TENANT_KEY) {
			// Set timeout used to give time for auth headers to be attached to request
			setTimeout(async () => {
				const ampTenants = await fetchTenantKeysAndLogos();
				if (isMountedRef.current) {
					updateTenantState({ ampTenants });
				}
			});
		}
  };

  const updateTenant = async (tenantKey: string) => {
	const response = await axios.get<XTenantKeyAndLogo | undefined>(`${_GET_TENANT_KEY_ENDPOINT}/${tenantKey}`);
	const tenant = response.data;
	if (tenant) {
		const newTenants = ampTenants.map((_tenant) => {
			if (_tenant.tenant_key === tenant.tenant_key) {
				return tenant;
			}
			return _tenant;
 		});
		 updateTenantState({ ampTenants: newTenants });
	}
  };

	const currentTenant = useMemo(() => {
		return ampTenants.find((_tenant) => _tenant.tenant_key === ampTenantKey);
	}, [ampTenants, ampTenantKey]);

	return (
		<TenantContext.Provider
			value={{
				ampTenantKey,
				ampTenantImpersonateKey,
				ampTenants,
				currentTenant,
				handleImpersonateTenantKeyChange,
				fetchAmpTenants,
				updateTenant,
			}}
		>
			{children}
		</TenantContext.Provider>
	);
};
